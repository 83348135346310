<template>
  <Page :loading="loading" :error="error">
    <div v-if="mailing && mailingData">
      <Section>
        <RouterLinkAuthWithIcon
          data-testid="link-to-mailings-sms"
          :to="{ name: RouteNamesActivityOverview.MAILINGS_SMS }"
          icon="arrow_left_alt"
          :text="texts.actions.backToOverview"
        ></RouterLinkAuthWithIcon>
        <PageHeading
          :title="mailing.name"
          :badgeLabel="
            texts.enums.mailingStatus[mailing.status as MailingStatus]
          "
          :badgeType="statusBadgeType"
        />
      </Section>
      <div v-if="viewMode === 'statistics'">
        <MailingStatistics :mailingId :data="mailingData" />
      </div>
      <div v-else class="flex flex-col gap-8">
        <SectionHeading
          :title="texts.navigationItems.mailing.overview.title"
          :description="texts.navigationItems.mailing.overview.description"
          :divider="false"
        />
        <MailingDescriptionList :data="mailingData" />
      </div>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { MailingStatus } from "@/enums";

import { useRoute } from "vue-router";
import logger from "@/plugins/logger";
import { RouteNamesActivityOverview } from "@/router/routeNames";
import {
  getMailingStatisticsBasicDataFromMailing as getMailingStatistics,
  MailingStatisticsBasicData,
} from "@/views/mailings/mailing/components/MailingStatistics.types";
import {
  getMailingDescriptionListDataFromMailing as getMailingDescriptionList,
  MailingDescriptionListData,
} from "@/views/mailings/mailing/components/MailingDescriptionList.types";

import Page from "@/components/common/page/Page.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Section from "@/components/common/section/Section.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import MailingStatistics from "@/views/mailings/mailing/components/MailingStatistics.vue";
import MailingDescriptionList from "@/views/mailings/mailing/components/MailingDescriptionList.vue";
import RouterLinkAuthWithIcon from "@/components/router/router-link-auth/RouterLinkAuthWithIcon.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import { convertToBadgeType } from "@/views/mailings/mailing/Overview.types";
import { MailingDTO } from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import texts from "@/utils/texts";

const route = useRoute();
const mailingId = route.params["mailingId"] as string;
if (!mailingId) throw new Error("No id param provided.");

const mailing = ref<MailingDTO>();
const mailingData = ref<
  MailingDescriptionListData | MailingStatisticsBasicData
>();
const statusBadgeType = computed<BadgeType>(() =>
  mailing.value
    ? convertToBadgeType(mailing.value.status as MailingStatus)
    : BadgeType.default,
);

// Fetch mail data
const loading = ref<boolean>(true);
const error = ref<boolean>(false);

const fetchMailing = () => {
  loading.value = true;
  return (
    Promise.all([eduConfigurationServiceClient.getMailingById(mailingId)])
      // TODO: can't we just use the shallowRefs here, instead of the sorta finicky .then(..) glue?
      .then(([mailingFetched]) => {
        mailing.value = mailingFetched;

        if (viewMode.value === "statistics") {
          return getMailingStatistics(mailing.value);
        } else {
          return getMailingDescriptionList(mailing.value);
        }
      })
      .then((data) => {
        mailingData.value = data;
      })
      .catch((e) => {
        error.value = true;
        logger.error(e);
      })
      .finally(() => {
        loading.value = false;
      })
  );
};

fetchMailing();

const viewMode = computed<"statistics" | "description">(() => {
  if (
    mailing.value?.status === MailingStatus.Sent ||
    mailing.value?.status === MailingStatus.InProgress
  )
    return "statistics";
  else return "description";
});
</script>
