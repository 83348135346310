import {
  BrochureConfigurationDto,
  BrochureOverviewDto,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";

export interface BrochureOverviewItem {
  configuration: BrochureConfigurationDto;
  brochure: BrochureOverviewDto;
  digitalProspectCount: number;
  hardcopyProspectCount: number;
  isOrphaned: boolean;
}

export const toBrochureOverview = (
  brochureConfigurations: BrochureConfigurationDto[],
  brochures: BrochureOverviewDto[],
): BrochureOverviewItem[] => {
  const brochureItems = brochureConfigurations
    .flatMap((config) =>
      config.brochureIds.map((id) => {
        return { configurationId: config.id, brochureId: id };
      }),
    )
    .map((cb) => {
      const configuration = brochureConfigurations.find(
        (config) => config.id == cb.configurationId,
      );
      const brochure = brochures.find(
        (brochure) => brochure.id == cb.brochureId,
      );
      const count = brochure?.prospectCounts.find(
        (count) => count.formId == configuration?.formId,
      );
      return {
        configuration: configuration,
        brochure: brochure,
        digitalProspectCount: count?.digitalCount || 0,
        hardcopyProspectCount: count?.hardCopyCount || 0,
        isOrphaned: false,
      } as BrochureOverviewItem;
    })
    .toSorted(
      (a, b) =>
        a.configuration.name.localeCompare(b.configuration.name) ||
        (a.brochure.name ?? "").localeCompare(b.brochure.name ?? ""),
    );

  const orphanedItemsWithConfig = brochures
    .flatMap((brochure) =>
      brochure.prospectCounts.map((count) => {
        return { brochureId: brochure.id, count: count };
      }),
    )
    .filter(
      (brochureCount) =>
        brochureConfigurations.find(
          (config) => config.formId == brochureCount.count.formId,
        ) &&
        !brochureConfigurations.find((config) =>
          config.brochureIds.includes(brochureCount.brochureId),
        ),
    )
    .map((brochureCount) => {
      const configuration = brochureConfigurations.find(
        (config) => config.formId == brochureCount.count.formId,
      );
      const brochure = brochures.find(
        (brochure) => brochure.id == brochureCount.brochureId,
      );

      return {
        configuration: configuration,
        brochure: brochure,
        digitalProspectCount: brochureCount.count.digitalCount,
        hardcopyProspectCount: brochureCount.count.hardCopyCount,
        isOrphaned: true,
      } as BrochureOverviewItem;
    })
    .toSorted(
      (a, b) =>
        a.configuration.name.localeCompare(b.configuration.name) ||
        (a.brochure.name ?? "").localeCompare(b.brochure.name ?? ""),
    );

  const orphanedItemsWithoutConfig = brochures
    .filter(
      (brochure) =>
        !brochureConfigurations.find((config) =>
          config.brochureIds.includes(brochure.id),
        ) &&
        !brochureConfigurations.find(
          (config) =>
            config.formId &&
            brochure.prospectCounts
              .map((count) => count.formId)
              .includes(config.formId),
        ),
    )
    .map((brochure) => {
      const configuration = {
        name: texts.navigationItems.brochures.overview.orphanedItems,
      } as BrochureConfigurationDto;
      return {
        configuration: configuration,
        brochure: brochure,
        digitalProspectCount: brochure.prospectCounts.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.digitalCount,
          0,
        ),
        hardcopyProspectCount: brochure.prospectCounts.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.hardCopyCount,
          0,
        ),
        isOrphaned: true,
      } as BrochureOverviewItem;
    })
    .toSorted(
      (a, b) =>
        a.configuration.name.localeCompare(b.configuration.name) ||
        (a.brochure.name ?? "").localeCompare(b.brochure.name ?? ""),
    );

  return [
    ...brochureItems,
    ...orphanedItemsWithConfig,
    ...orphanedItemsWithoutConfig,
  ];
};
