import { IMergeContent, RowLayoutType } from "@beefree.io/sdk/dist/types/bee";
import dictionary from "@/dictionary.ts";
import { Culture } from "@/enums.ts";

export function replaceMergeContentValuesWithHandleBars(
  mergeContents: IMergeContent[],
): IMergeContent[] {
  return mergeContents.map((mergeContent) => {
    const constValue = BEE_CONFIG_MERGE_CONTENTS.find(
      (c) => c.searchValue === mergeContent.value,
    );

    if (constValue) {
      return {
        ...mergeContent,
        value: constValue.value,
      };
    }

    return mergeContent;
  });
}

/*
 * LET OP
 * Mandrill gebruikt een custom variant/implementatie van handlebars
 * die heeft een aantal eigenaardigheden:
 *  - namen van properties binnen een object worden automatisch door mandrill ge-camelcased
 *    dus een property die aan mandrill wordt doorgegeven als SomeObject.SomeProperty moet in
 *    het template als SomeObject.someProperty gerefereerd worden.
 *  - Niet alle handlebars standard features werken
 *    Ga er alleen van uit dat iets werkt als het in de mandrill handlebars documentatie genoemd wordt
 *    https://mailchimp.com/developer/transactional/docs/templates-dynamic-content/#handlebars
 */
const BEE_CONFIG_MERGE_CONTENTS = [
  {
    searchValue: "{{Registration_TicketCode}}",
    value:
      '<img alt="qrcode" src="https://qr.summit.nl/qr/?data={{Registration_TicketCode}}" />',
  },
  {
    searchValue: "{{Registration_SessionsNL}}",
    value: sessionContent("Registration_SessionsNL"),
  },
  {
    searchValue: "{{Registration_SessionsEN}}",
    value: sessionContent("Registration_SessionsEN"),
  },
  {
    searchValue: "{{Registration_SessionsFR}}",
    value: sessionContent("Registration_SessionsFR"),
  },
  {
    searchValue: "{{Registration_SessionsDE}}",
    value: sessionContent("Registration_SessionsDE"),
  },
  {
    searchValue: "{{Brochure_Request}}",
    value:
      '<ul><!-- {{#each Brochure_Request}} --><li><a href="{{this.url}}" title="{{this.url}}" target="_blank"><span>{{name}}</span></a></li><!-- {{/each}} --></ul>',
  },
  {
    searchValue: "{{StudyProgramInterestsNL}}",
    value: studyProgramContent("StudyProgramInterestsNL"),
  },
  {
    searchValue: "{{StudyProgramInterestsEN}}",
    value: studyProgramContent("StudyProgramInterestsEN"),
  },
  {
    searchValue: "{{StudyProgramInterestsFR}}",
    value: studyProgramContent("StudyProgramInterestsFR"),
  },
  {
    searchValue: "{{StudyProgramInterestsDE}}",
    value: studyProgramContent("StudyProgramInterestsDE"),
  },
  {
    searchValue: "{{StudyProgramApplication}}",
    value: studyProgramApplicationContent(),
  },
  {
    searchValue: "{{SurveyAnswers}}",
    value: surveyAnswersContent(),
  },
];

type registrationSessionsKey =
  | "Registration_SessionsNL"
  | "Registration_SessionsEN"
  | "Registration_SessionsFR"
  | "Registration_SessionsDE";

function onlineSessionLinkLocalizedText(
  registrationSessionsKey: registrationSessionsKey,
) {
  const cultureLookup = {
    Registration_SessionsNL: Culture.NL,
    Registration_SessionsEN: Culture.EN,
    Registration_SessionsFR: Culture.FR,
    Registration_SessionsDE: Culture.DE,
  };
  const culture: Culture = cultureLookup[registrationSessionsKey];
  const texts = dictionary[culture];
  return texts.bee.mergeContents.registrationSessions.onlineSessionLinkText;
}

function sessionContent(registrationSessionsKey: registrationSessionsKey) {
  return `
{{#each ${registrationSessionsKey}}}
<table class="text_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
    style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
    <tr>
        <td class="pad" style="padding-bottom:5px;padding-left:10px;padding-right:10px;padding-top:5px">
            <div style="font-family:sans-serif">
                <div class
                    style="font-size:14px;mso-line-height-alt:21px;color:#555;line-height:1.5;font-family:Arial,Helvetica Neue,Helvetica,sans-serif">
                    <p style="margin:0;font-size:14px;mso-line-height-alt:21px">
                        <span style="font-size:14px;"><strong>{{name}}</strong></span>
                    </p>
                    <p style="margin:0;font-size:14px;mso-line-height-alt:21px">
                    <span style="font-size:14px;">{{#each studyPrograms}}{{name}}{{#if mode}} ({{mode}}){{/if}}{{#unless @last}}, {{/unless}}{{/each}}</span>
                    </p>
                    <p style="margin:0;font-size:14px;mso-line-height-alt:21px">
                        <span style="font-size:14px;">
                          {{#if location}}{{#if locationUrl}}<a href="{{locationUrl}}" title="{{location}}" target="_blank"><span>{{location}}</span></a>{{else}}{{location}}{{/if}}{{#if room}} ({{room}}){{/if}}, {{/if}}{{formattedDateTimePeriod}}
                        </span>
                    </p>
                    <p style="margin:0;font-size:14px;mso-line-height-alt:21px">
                        <span style="font-size:14px;">{{{descriptionForConfirmation}}}</span>
                    </p>
                    {{#if onlineSessionUrl}}
                    <a href="{{onlineSessionUrl}}">${onlineSessionLinkLocalizedText(
                      registrationSessionsKey,
                    )}</a>
                    {{/if}}
                </div>
            </div>
        </td>
    </tr>
</table>
<table class="divider_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation"
    style="mso-table-lspace:0;mso-table-rspace:0">
    <tr>
        <td class="pad">
            <div class="alignment" align="center">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%"
                    style="mso-table-lspace:0;mso-table-rspace:0">
                    <tr>
                        <td class="divider_inner" style="font-size:1px;line-height:1px;border-top:1px dotted #bbb">
                            <span>&#8202;</span>
                        </td>
                    </tr>
                </table>
            </div>
        </td>
    </tr>
</table>
{{/each}}`;
}

function studyProgramContent(
  interestInStudyProgramsKey:
    | "StudyProgramInterestsNL"
    | "StudyProgramInterestsEN"
    | "StudyProgramInterestsFR"
    | "StudyProgramInterestsDE",
) {
  return `
{{#if ${interestInStudyProgramsKey}.0}}
<table class="list_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation"
    style="mso-table-lspace:0;mso-table-rspace:0">
    <tr>
        <td class="pad">
            <div class="levelOne" style="margin-left:0">
                <ul class="leftList" start="1" style="margin-top:0;margin-bottom:0;padding:0;padding-left:20px;font-weight:400;text-align:left;color:#555;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:0;line-height:120%;mso-line-height-alt:16.8px;list-style-type:circle">
                    {{#each ${interestInStudyProgramsKey}}}
                    <li style="margin-bottom:8px;text-align:left">{{name}}{{#if mode}} ({{mode}}){{/if}}</li>
                    {{/each}}
                </ul>
            </div>
        </td>
    </tr>
</table>
{{/if}}`;
}

function studyProgramApplicationContent() {
  return `
<table class="list_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation"
    style="mso-table-lspace:0;mso-table-rspace:0">
    <tr>
        <td class="pad">
            <div class="levelOne" style="margin-left:0">
                <ul class="leftList" start="1" style="margin-top:0;margin-bottom:0;padding:0;padding-left:20px;font-weight:400;text-align:left;color:#555;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:0;line-height:120%;mso-line-height-alt:16.8px;list-style-type:circle">
                  <li style="margin-bottom:8px;text-align:left">{{StudyProgramApplication.name}}{{#if StudyProgramApplication.mode}} ({{StudyProgramApplication.mode}}){{/if}}</li>
                </ul>
            </div>
        </td>
    </tr>
</table>
`;
}

function surveyAnswersContent() {
  return `
    {{#each SurveyAnswers}}
      <div style="margin-bottom: 1em;">
        <div style="font-weight: bold;">
            {{questionLabel}}
        </div>
        <ul>
            {{#each answers}}
              <li>{{this}}</li>
            {{/each}}
        </ul>
      </div>
    {{/each}}
  `;
}

export const BEE_DEFAULT_TEMPLATE = {
  page: {
    body: {
      container: { style: { "background-color": "#FFFFFF" } },
      content: {
        computedStyle: {
          align: "",
          linkColor: "#0068A5",
          messageBackgroundColor: "transparent",
          messageWidth: "500px",
        },
        style: {
          color: "#000000",
          "font-family": "Arial, Helvetica Neue, Helvetica, sans-serif",
        },
      },
      type: "mailup-bee-page-proprerties",
      webFonts: [],
    },

    description: "Default BEE template",
    rows: [
      {
        columns: [
          {
            "grid-columns": 12,
            modules: [],
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "5px",
            },
            uuid: "d477065c-0b46-4bb5-9514-d81f88f184d5",
          },
        ],
        container: {
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
          },
        },
        content: {
          computedStyle: {
            rowColStackOnMobile: true,
            rowReverseColStackOnMobile: false,
          },
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-position": "top left",
            "background-repeat": "no-repeat",
            color: "#000000",
            width: "500px",
          },
        },
        type: RowLayoutType.ONE_COLUMNS_EMPTY,
        uuid: "33230147-a599-40f2-957d-0e769372bb9d",
      },
    ],
    template: { name: "template-base", type: "basic", version: "0.0.1" },
    title: "Template Base",
  },
  comments: {},
};

export const BEE_INVALID_TEMPLATE_STATUSCODE = 1100;
