<template>
  <Card
    class="flex flex-col gap-4"
    :data-testid="testIds.contact.journey.title"
  >
    <div class="flex w-full items-center justify-between">
      <div class="flex gap-2">
        <Icon icon="show_chart"></Icon>

        <CardHeader
          :text="texts.models.contactMoment.studentJourney"
        ></CardHeader>
      </div>
      <ButtonAdd
        :color="Color.Emerald"
        :size="ButtonSize.xs"
        @click="emits('add:contactMoment')"
      ></ButtonAdd>
    </div>
    <Divider />
    <div class="text-sm">
      <ul role="list" class="">
        <li
          v-for="(contactMomentItem, contactItemIdx) in contactMoments"
          :key="contactMomentItem.id"
          :data-testid="testIds.contact.journey.item"
        >
          <div class="relative">
            <span
              v-if="
                contactMoments && contactItemIdx !== contactMoments.length - 1
              "
              class="absolute left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
            <div class="relative flex items-start gap-3">
              <div
                class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100"
              >
                <Icon
                  :icon="contactMomentTypeIcon(contactMomentItem.type)"
                  :color="contactMomentTypeColor(contactMomentItem.type)"
                />
              </div>
              <div class="flex w-full flex-col gap-2 pb-6">
                <div>
                  <div class="flex w-full items-start justify-between">
                    <div>
                      <span class="font-semibold">{{
                        texts.enums.contactMomentType[
                          contactMomentItem.type as ContactMomentType
                        ]
                      }}</span>
                      <span v-if="contactMomentItem.activityName">{{
                        (contactMomentItem.type === ContactMomentType.Visit
                          ? " "
                          : texts.models.contactMoment.forActivity) +
                        contactMomentItem.activityName
                      }}</span>
                    </div>
                    <TextButton
                      :data-testid="testIds.action.view"
                      :color="Color.Emerald"
                      :label="texts.actions.viewDetails"
                      @click="emits('view:details', contactMomentItem)"
                    ></TextButton>
                  </div>
                  <span class="text-gray-400">{{
                    contactMomentItem.dateTime.toLocaleString(
                      DateTime.DATETIME_MED,
                    )
                  }}</span>
                </div>
                <div
                  v-if="
                    contactMomentItem.brochureNames ||
                    contactMomentItem.locationName ||
                    contactMomentItem.studyProgramName ||
                    contactMomentItem.sessionName
                  "
                  class="flex flex-col gap-1 text-sm font-medium"
                >
                  <span>{{
                    contactMomentTypeString(contactMomentItem.type)
                  }}</span>
                  <div class="flex w-full flex-wrap items-center gap-1">
                    <template v-if="!contactMomentItem.sessionName">
                      <Tag
                        v-for="name in contactMomentItem.studyProgramNames"
                        :key="name"
                        :color="contactMomentTypeColor(contactMomentItem.type)"
                        :size="contactMomentTagSize"
                        >{{ name }}</Tag
                      >
                    </template>
                    <Tag
                      v-for="name in contactMomentItem.brochureNames"
                      :key="name"
                      :color="contactMomentTypeColor(contactMomentItem.type)"
                      :size="contactMomentTagSize"
                      >{{ name }}</Tag
                    >
                    <Tag
                      v-if="contactMomentItem.locationName"
                      :label="contactMomentItem.locationName"
                      :color="contactMomentTypeColor(contactMomentItem.type)"
                      :size="contactMomentTagSize"
                      >{{ contactMomentItem.locationName }}
                    </Tag>
                    <Tag
                      v-if="contactMomentItem.studyProgramName"
                      :color="contactMomentTypeColor(contactMomentItem.type)"
                      :size="contactMomentTagSize"
                    >
                      {{ contactMomentItem.studyProgramName }}
                    </Tag>
                    <Tag
                      v-if="contactMomentItem.sessionName"
                      :color="contactMomentTypeColor(contactMomentItem.type)"
                      :size="contactMomentTagSize"
                    >
                      {{ contactMomentItem.sessionName }}
                    </Tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </Card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { DateTime } from "luxon";
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import { Color } from "@/enums";
import { ContactMoment } from "@/models/contact-moment";
import Icon from "@/components/common/icon/Icon.vue";
import { ContactMomentType } from "@/lib/eduConfigurationServiceClient";
import TextButton from "@/components/common/button/TextButton.vue";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import Tag from "@/components/common/tag/Tag.vue";
import Divider from "@/components/common/divider/Divider.vue";
import { TagSize } from "@/components/common/tag/Tag.types";
import { testIds } from "@/utils/testing";
import { IconNames } from "@/components/common/icon/Icon.types";
import texts from "@/utils/texts";

defineProps<{
  contactMoments: ContactMoment[];
}>();

const emits = defineEmits(["view:details", "add:contactMoment"]);

const contactMomentTagSize = ref(TagSize.xs);

const contactMomentTypeIcon = (value: ContactMomentType): IconNames => {
  switch (value) {
    case ContactMomentType.Registration:
      return "fact_check";
    case ContactMomentType.Visit:
      return "where_to_vote";
    case ContactMomentType.GeneralMail:
      return "mail";
    case ContactMomentType.ActivityMail:
      return "mail";
    case ContactMomentType.ActivityReminderMail:
      return "mail";
    case ContactMomentType.ActivityInviteMail:
      return "mail";
    case ContactMomentType.BrochureRequest:
      return "menu_book";
    case ContactMomentType.KeepMeInformed:
      return "info";
    case ContactMomentType.Enrollment:
      return "school";
    case ContactMomentType.SessionVisit:
      return "wifi";
    case ContactMomentType.ActivityVisitedSurveyMail:
      return "mail";
    case ContactMomentType.ProspectImport:
      return "publish";
    case ContactMomentType.ShortMessage:
      return "sms";
    case ContactMomentType.ActivityVisit:
      return "where_to_vote";
    case ContactMomentType.Application:
      return "hotel_class";
    case ContactMomentType.TrialDayRegistration:
      return "fact_check";
    case ContactMomentType.ThirdPartyProspectImport:
      return "alt_route";
    default:
      return "help";
  }
};

const contactMomentTypeColor = (
  value: ContactMomentType,
): Color | undefined => {
  switch (value) {
    case ContactMomentType.Enrollment:
      return Color.Emerald;
    case ContactMomentType.Application:
      return Color.Lavender;
    default:
      return undefined;
  }
};

const contactMomentTypeString = (value: ContactMomentType): string => {
  switch (value) {
    case ContactMomentType.Enrollment:
      return texts.models.contactMoment.studyProgramNames;
    case ContactMomentType.Application:
      return texts.models.contactMoment.studyProgramNames;
    case ContactMomentType.SessionVisit:
      return texts.models.contactMoment.sessionName;
    case ContactMomentType.Registration:
      return texts.models.contactMoment.studyProgramInterests;
    case ContactMomentType.BrochureRequest:
      return texts.models.contactMoment.brochureNames;
    case ContactMomentType.Visit:
      return texts.models.contactMoment.location;
    default:
      return "";
  }
};
</script>
