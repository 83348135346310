<template>
  <div class="flex flex-col gap-8">
    <SectionHeading
      :title="texts.navigationItems.mailing.steps.overview.title"
      :description="texts.navigationItems.mailing.steps.overview.description"
      :divider="false"
    />
    <MailingDescriptionList :data="mailingDescriptionListData" />
  </div>
</template>

<script setup lang="ts">
import { watch, computed } from "vue";

import {
  ConceptMailingData,
  MailingContextData,
} from "@/views/mailings/mailing/MailingWizard.types";

import SectionHeading from "@/components/common/section/SectionHeading.vue";
import MailingDescriptionList from "@/views/mailings/mailing/components/MailingDescriptionList.vue";
import { MailingDescriptionListData } from "../components/MailingDescriptionList.types";
import { getMailingDescriptionListDataFromConcept } from "../components/MailingDescriptionList.types";
import texts from "@/utils/texts";

const emit = defineEmits([
  "forwardNavigationValidated",
  "backNavigationValidated",
  "saveValidated",
]);

const props = defineProps<{
  isNavigatingBack: boolean;
  isNavigatingForward: boolean;
  isSaving: boolean;
  data: ConceptMailingData;
  contextData: MailingContextData;
}>();

const mailingDescriptionListData = computed<MailingDescriptionListData>(() =>
  getMailingDescriptionListDataFromConcept(props.data),
);

// Navigation
watch(
  () => props.isNavigatingForward,
  (value) => {
    if (value) {
      emit("forwardNavigationValidated", true);
    }
  },
);

watch(
  () => props.isNavigatingBack,
  (value) => {
    if (value) {
      emit("backNavigationValidated", true);
    }
  },
);

watch(
  () => props.isSaving,
  async (value) => {
    if (value) {
      emit("saveValidated", true);
    }
  },
);
</script>
