<template>
  <FormLayout @submit.prevent="submitForm">
    <FormFieldArrayHelper
      v-slot="{ field, key }"
      :fieldId="fieldId"
      :fields="localizations"
    >
      <FormFieldTextInput
        :id="`${key}.name`"
        :label="`${texts.models.activityLocation.name} (${
          texts.enums.culture[field.locale]
        })`"
        :data-testid="`name_${field.locale}`"
      />
    </FormFieldArrayHelper>
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import * as yup from "yup";
import { Culture } from "@/enums";
import { ActivityTypeFormValues } from "./ActivityTypeForm.types";
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { useLocalizations } from "@/models/helpers/localizable";
import FormLayout from "@/components/common/form/FormLayout.vue";
import texts from "@/utils/texts";

const props = defineProps<{
  formValues?: ActivityTypeFormValues;
}>();

const emit = defineEmits<{
  (e: "submit", values: ActivityTypeFormValues): void;
}>();

const form = useForm<ActivityTypeFormValues>({
  validationSchema: yup.object({
    localizations: yup.array().of(
      yup.object({
        locale: yup.mixed<Culture>().required(),
        name: yup.string().requiredWhenMainLocale(),
      }),
    ),
  }),
});

const { fieldId, localizations } = useLocalizations(
  props.formValues?.localizations,
);

const submitForm = form.handleSubmit((values) => emit("submit", values));
if (props.formValues) form.setValues(props.formValues);
</script>
