import { MailingType as MailingTypeEnum } from "@/enums";
import {
  ISelectionDefinitionAttributesDTO,
  SelectionCriterionDtoType,
  SelectionDefinitionAttributesDTO,
  SelectionDefinitionDtoResponseType,
} from "@/lib/eduConfigurationServiceClient";
import {
  IMergeContent,
  IMergeTag,
  ISpecialLink,
} from "@beefree.io/sdk/dist/types/bee";

export interface MergeTagDTO {
  name: string;
  value: string;
  previewValue?: string;
}

export class MergeTag implements IMergeTag {
  name: string;
  value: string;
  previewValue?: string;

  constructor(dto: MergeTagDTO) {
    this.name = dto.name;
    this.value = dto.value;
    this.previewValue = dto.previewValue;
  }
}

export interface MergeContentDTO {
  name: string;
  value: string;
}

export class MergeContent implements IMergeContent {
  name: string;
  value: string;

  constructor(dto: MergeContentDTO) {
    this.name = dto.name;
    this.value = dto.value;
  }
}

export interface SpecialLinkDTO {
  type: string;
  label: string;
  link: string;
}

export class SpecialLink implements ISpecialLink {
  type: string;
  label: string;
  link: string;

  constructor(dto: SpecialLinkDTO) {
    this.type = dto.type;
    this.label = dto.label;
    this.link = dto.link;
  }
}

export interface MailingTypeDTO {
  mailingType: string;
  mergeTags: MergeTagDTO[];
  mergeContents: MergeContentDTO[];
  specialLinks: SpecialLinkDTO[];
  selectionDefinitionAttributes: ISelectionDefinitionAttributesDTO;
}

interface ISelectionDefinitionAttributesDTOPatched {
  responseType: SelectionDefinitionDtoResponseType;
  requiredCriteria: SelectionCriterionDtoType[];
  nonRequiredCriteria: SelectionCriterionDtoType[];
}

export class MailingType {
  mailingType: MailingTypeEnum;
  mergeTags: MergeTag[];
  mergeContents: MergeContent[];
  specialLinks: SpecialLink[];
  selectionDefinitionAttributes: ISelectionDefinitionAttributesDTOPatched;

  constructor(dto: MailingTypeDTO) {
    if (!Object.values<string>(MailingTypeEnum).includes(dto.mailingType)) {
      throw new Error(`Unknown mailing type ${dto.mailingType}`);
    }
    this.mailingType = dto.mailingType as MailingTypeEnum;
    this.mergeTags = dto.mergeTags.map((dto) => new MergeTag(dto));
    this.mergeContents = dto.mergeContents.map((dto) => new MergeContent(dto));
    this.specialLinks = dto.specialLinks.map((dto) => new SpecialLink(dto));
    this.selectionDefinitionAttributes = new SelectionDefinitionAttributesDTO(
      dto.selectionDefinitionAttributes,
    ) as unknown as ISelectionDefinitionAttributesDTOPatched;
  }
}

export const getMailingTypeColor = (type: MailingTypeEnum) => {
  if (type === MailingTypeEnum.General) {
    return "bg-emerald";
  } else if (type === MailingTypeEnum.Activity) {
    return "bg-steel";
  } else if (type === MailingTypeEnum.ActivityReminder) {
    return "bg-lavender";
  } else if (type === MailingTypeEnum.ActivityInvite) {
    return "bg-sunflower";
  } else if (type === MailingTypeEnum.ActivityVisitedSurvey) {
    return "bg-coral";
  }
};

export function isActivityMailingType(type: MailingTypeEnum) {
  return (
    type === MailingTypeEnum.Activity ||
    type === MailingTypeEnum.ActivityReminder ||
    type === MailingTypeEnum.ActivityInvite ||
    type === MailingTypeEnum.ActivityVisitedSurvey
  );
}
