<template>
  <div v-if="question.id" class="group relative flex items-center">
    <ListItem
      v-if="selected"
      class="w-full flex-1 border"
      :title="selected.label"
      clickable
      @click="slideOverSettingsOpen = true"
      ><div class="flex w-full items-center justify-between p-2">
        <span class="text-sm">{{ selected.label }}</span>
        <DropdownWrapper :origin="DropdownOrigin.TopRight" @click.stop>
          <template v-slot:button>
            <MenuButton
              class="flex items-center"
              :data-testid="testIds.actions"
            >
              <Icon icon="more_vert"></Icon>
            </MenuButton>
          </template>
          <template v-slot:items>
            <DropdownItem
              v-if="!isFirstQuestion"
              @click="emit('move:question:up', question)"
            >
              <Icon icon="arrow_upward" :color="Color.DeepTeal"></Icon>
              <span class="text-deepteal-500">{{ texts.actions.moveUp }}</span>
            </DropdownItem>
            <DropdownItem
              v-if="!isLastQuestion"
              @click="emit('move:question:down', question)"
            >
              <Icon icon="arrow_downward" :color="Color.DeepTeal"></Icon>
              <span class="text-deepteal-500">{{
                texts.actions.moveDown
              }}</span>
            </DropdownItem>
            <Divider class="mx-2 my-1" />
            <DropdownItem @click="slideOverSettingsOpen = true">
              <Icon icon="settings" :color="Color.DeepTeal"></Icon>
              <span class="text-deepteal-500">{{
                texts.actions.viewSettings
              }}</span>
            </DropdownItem>
            <Divider class="mx-2 my-1" />
            <DropdownItem @click="emit('delete:question', question)">
              <Icon icon="delete" :color="Color.Alert"></Icon>
              <span class="text-alert-500">{{ texts.actions.delete }}</span>
            </DropdownItem>
          </template>
        </DropdownWrapper>
      </div>
      <Badge
        v-if="conditionalQuestions.length > 0"
        :label="conditionalQuestions.length.toString()"
        :type="BadgeType.info"
        class="absolute right-0 top-0 mr-20 mt-5 flex -translate-y-1/2 translate-x-1/2 transform items-center"
      />
    </ListItem>
  </div>
  <SlideOverFormTemplateQuestionSettings
    v-model:visible="slideOverSettingsOpen"
    :currentLocale="currentLocale"
    :question="question"
    :conditionalQuestions="conditionalQuestions"
    :questionOptions="questionOptions"
    @update:question:settings="emit('update:question:settings', $event)"
  />
</template>

<script setup lang="ts">
import Badge from "@/components/common/badge/Badge.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import Divider from "@/components/common/divider/Divider.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import { Color, Culture, FormType } from "@/enums";
import { FormQuestionDTO } from "@/lib/formsServiceClient";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import {
  FormTemplateEditContext,
  FormTemplateEditContextKey,
} from "@/views/settings/forms/FormExtensions";
import { MenuButton } from "@headlessui/vue";
import { computed, inject, ref, watch } from "vue";
import "../../FormExtensions";
import { QuestionSettingsFormValues } from "./FormTemplateQuestionSettingsForm.types";
import SlideOverFormTemplateQuestionSettings from "./SlideOverFormTemplateQuestionSettings.vue";
import ListItem from "@/components/common/list/ListItem.vue";

const props = defineProps<{
  currentLocale: Culture;
  question: FormQuestionDTO;
  isFirstQuestion?: boolean;
  isLastQuestion?: boolean;
  conditionalQuestions: FormQuestionDTO[];
}>();

const emit = defineEmits<{
  "update:question:settings": [question: QuestionSettingsFormValues];
  "delete:question": [question: FormQuestionDTO];
  "move:question:up": [question: FormQuestionDTO];
  "move:question:down": [question: FormQuestionDTO];
}>();

const editContext = inject(
  FormTemplateEditContextKey,
) as FormTemplateEditContext;
if (!editContext) {
  throw new Error("FormEditorContext not found");
}

const displayQuestion = ref(
  editContext?.questions.find((q) => q.id === props.question.id),
);

const questionOptions = computed<SelectOption[]>(() => {
  return (
    editContext?.questions
      .filter((q) =>
        editContext.formType === FormType.ActivityVisitedSurvey
          ? q.isSurveyQuestion
          : !q.isSurveyQuestion,
      )
      .map((q) => {
        return {
          value: q.id,
          label:
            q.localizations.find((l) => l.locale.value === props.currentLocale)
              ?.label ?? "",
        } satisfies SelectOption;
      })
      .toSorted((a, b) => a.label.localeCompare(b.label)) ?? []
  );
});
const selected = ref<SelectOption | undefined>(
  questionOptions.value.find((o) => o.value === props.question.id),
);

watch(
  () => editContext?.questions,
  () => {
    displayQuestion.value = editContext?.questions.find(
      (q) => q.id === props.question.id,
    );

    selected.value = questionOptions.value.find(
      (o) => o.value === props.question.id,
    );
  },
);

const slideOverSettingsOpen = ref<boolean>(props.question.id ? false : true);
</script>
