import { StudyProgramDepartment } from "@/models/study-program-department";
import { reactive } from "vue";
import { FilterProps } from "../common/filter/FilterItem.types";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";

export interface FilterPropsWithChildren extends FilterProps {
  studyPrograms: FilterProps[];
}

export function createNoDepartmentStudyProgramFilterItems(
  studyPrograms: LocalizedStudyProgramDTO[],
  checkedIds: string[] = [],
): FilterProps[] {
  return studyPrograms
    .filter((studyProgram) => !studyProgram.studyProgramDepartment)
    .map((studyProgram) =>
      createStudyProgramFilterItem(
        studyProgram,
        checkedIds.includes(studyProgram.id),
      ),
    )
    .toSorted(sortOnName);
}

export function createStudyProgramFilterItem(
  studyProgram: LocalizedStudyProgramDTO,
  modelValue = false,
): FilterProps {
  return reactive({
    id: studyProgram.id,
    name: studyProgram.displayName,
    modelValue,
  });
}

export function createDepartmentFilterItems(
  studyPrograms: LocalizedStudyProgramDTO[],
  checkedIds: string[] = [],
): FilterPropsWithChildren[] {
  return studyPrograms
    .reduce<FilterPropsWithChildren[]>((previous, studyProgram) => {
      if (studyProgram.studyProgramDepartment) {
        const department = studyProgram.studyProgramDepartment;
        let filterItem = previous.find(
          (filterItem) => department.id === filterItem.id,
        );

        if (!filterItem) {
          filterItem = createDepartmentFilterItem(department);
          previous.push(filterItem);
        }

        filterItem.studyPrograms.push(
          createStudyProgramFilterItem(
            studyProgram,
            checkedIds.includes(studyProgram.id),
          ),
        );

        filterItem.studyPrograms.toSorted(sortOnName);
      }
      return previous;
    }, [])
    .toSorted(sortOnName);
}

export function createDepartmentFilterItem(
  department: StudyProgramDepartment,
): FilterPropsWithChildren {
  return {
    id: department.id,
    name: department.name,
    modelValue: false,
    studyPrograms: [],
  };
}

export function getCheckedIds(filterProps: FilterProps[]) {
  return filterProps
    .filter((studyProgram) => !!studyProgram.modelValue)
    .map((studyProgram) => studyProgram.id);
}

export function sortOnName(a: FilterProps, b: FilterProps): number {
  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
}
