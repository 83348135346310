<template>
  <Async :state="state">
    <template v-slot:failure>
      <ErrorComponent />
    </template>
    <template v-slot:loading>
      <Loader />
    </template>
    <template v-slot>
      <form class="flex flex-col gap-8">
        <div class="flex flex-col gap-4">
          <h2 class="text-lg font-semibold text-deepteal-700">
            {{ languageTexts.title }}
          </h2>
          <span class="text-sm font-light text-deepteal-700">
            {{ languageTexts.description }}
          </span>
        </div>
        <div class="flex-flex-col gap-4">
          <FormFieldSelect
            id="mainLanguageId"
            :label="languageTexts.defaultLabel"
            :items="languageItems"
          />
          <span class="mb-2 mt-4 block text-sm font-semibold text-deepteal-800"
            >{{ languageTexts.extraLabel }}
          </span>
          <MultiCheckboxField
            formFieldId="otherLanguageIds"
            :items="checkboxItems"
          />
        </div>
      </form>
    </template>
  </Async>
</template>

<script setup lang="ts">
import { useAsyncState } from "@/components/common/async/Async.types";
import Async from "@/components/common/async/Async.vue";
import { MultiCheckboxFieldItem } from "@/components/common/checkbox/MultiCheckboxField.types";
import MultiCheckboxField from "@/components/common/checkbox/MultiCheckboxField.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import Loader from "@/components/common/loader/Loader.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import { Input } from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import settings, {
  loadAvailableLanguages,
  loadLanguages,
} from "@/store/context/settings.context";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";

const languageTexts = texts.navigationItems.manage.settings.general.languages;

interface LanguageSettingsForm {
  mainLanguageId: string;
  otherLanguageIds: string[];
}

const actionLoading = ref(false);
const form = useForm<LanguageSettingsForm>({});

console.log(settings.availableLanguages);

console.log(settings.mainLanguage);

const sortedLanguages = computed(() =>
  settings.languages.toSorted((a) =>
    a.id === settings.mainLanguage.id ? -1 : 1,
  ),
);

const languageItems = computed<Array<SelectOption>>(() =>
  sortedLanguages.value.map((l) => ({
    label: l.name,
    value: l.id,
  })),
);

const checkboxItems = computed<Array<MultiCheckboxFieldItem>>(() =>
  sortedLanguages.value.map((l) => ({
    id: l.id,
    value: l.id,
    label: l.name,
    disabled: l.id === form.values.mainLanguageId,
  })),
);

// This is a workaround to prevent the watch from triggering when the otherLanguageIds is updated with the mainLanguageId
const bypassWatch = ref(false);
const { state, handler } = useAsyncState(async () => {
  await loadLanguages();
  await loadAvailableLanguages();

  form.setValues({
    mainLanguageId: settings.mainLanguage.id,
    otherLanguageIds: settings.availableLanguages.map((l) => l.id),
  });
});

handler().then(() => {
  watch(
    [() => form.values.mainLanguageId, () => form.values.otherLanguageIds],
    async ([newMainLanguageId, newOtherLanguageIds], [oldMainLanguageId]) => {
      if (bypassWatch.value) {
        bypassWatch.value = false;
        return;
      }

      if (
        newMainLanguageId === undefined ||
        newOtherLanguageIds === undefined
      ) {
        return;
      }

      const validation = await form.validate();

      if (validation.valid) {
        actionLoading.value = true;
        try {
          const body = new Input({
            mainLanguageId: newMainLanguageId,
            otherLanguageIds: newOtherLanguageIds.filter(
              (l) => l !== form.values.mainLanguageId,
            ),
          });

          await eduConfigurationServiceClient.updateAvailableLanguages(body);
          loadAvailableLanguages();

          Notify.success(texts.notifications.edit.success);

          if (newMainLanguageId !== oldMainLanguageId) {
            bypassWatch.value = true;
            form.setFieldValue(
              "otherLanguageIds",
              newOtherLanguageIds.concat(newMainLanguageId),
            );
          }
        } catch (e) {
          logger.error(e);
          Notify.failure(texts.notifications.edit.failure);
        } finally {
          actionLoading.value = false;
        }
      }
    },
    {},
  );
});
</script>
