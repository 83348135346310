import { Culture, MailingStatus, MailingType } from "@/enums";
import { PlanningMode } from "@/lib/eduConfigurationServiceClient";
import { DateTime } from "luxon";

export interface MailingDTO {
  id: string;
  name: string;
  type: MailingType;
  status: MailingStatus;
  locale: Culture;
  activityName?: string;
  registrationLinkName?: string;
  formName?: string;
  planningMode?: PlanningMode;
  relativePlanningDayOffset?: number;
  relativePlanningTimeOfDay?: string;
  plannedDateTime?: string;
  modifiedDateTime: string;
  sentDateTime?: string;
}

export interface MailingContentExtendedDTO extends MailingContentDTO {
  id: string;
}

export interface MailingExtendedDTO extends MailingDTO {
  activityId?: string;
  registrationLinkId?: string;
  surveyLinkId?: string;
  subject?: string;
  fromName?: string;
  fromEmailAddress?: string;
  replyToEmailAddress?: string;
  mailingContent?: MailingContentExtendedDTO;
  selectionDefinitionId?: string;
}

export interface MailingContentDTO {
  configuration: string;
  content: string;
}

export interface ConceptMailingCreateDTO {
  type: MailingType;
  locale: Culture;
  name: string;
  activityId?: string;
  registrationLinkId?: string;
  surveyLinkId?: string;
  planningMode?: PlanningMode;
  relativePlanningDayOffset?: number;
  relativePlanningTimeOfDay?: string;
  plannedDateTime?: DateTime;
  fromEmailAddress?: string;
  replyToEmailAddress?: string;
  fromName?: string;
  mailingContent?: MailingContentDTO;
  subject?: string;
  selectionDefinitionId?: string;
}

export interface ConceptMailingCreateResponseDTO {
  id: string;
}

export interface ConceptMailingUpdateDTO extends ConceptMailingCreateDTO {
  id: string;
}

export interface PlannedMailingCreateDTO {
  type: MailingType;
  locale: Culture;
  name: string;
  activityId?: string;
  registrationLinkId?: string;
  surveyLinkId?: string;
  planningMode: PlanningMode;
  relativePlanningDayOffset?: number;
  relativePlanningTimeOfDay?: string;
  plannedDateTime?: DateTime;
  fromEmailAddress: string;
  replyToEmailAddress: string;
  fromName: string;
  mailingContent: MailingContentDTO;
  subject: string;
  selectionDefinitionId: string;
}

export interface PlannedMailingCreateResponseDTO {
  id: string;
}

export interface PlannedMailingUpdateDTO extends PlannedMailingCreateDTO {
  id: string;
}

export interface TestMailDTO {
  recipient: string;
  fromEmailAddress: string;
  replyToEmailAddress: string;
  fromName: string;
  subject: string;
  content: string;
}

export class Mailing {
  id: string;
  name: string;
  type: MailingType;
  status: MailingStatus;
  locale: Culture;
  activityName?: string;
  registrationLinkName?: string;
  formName?: string;
  planningMode?: PlanningMode;
  relativePlanningDayOffset?: number;
  relativePlanningTimeOfDay?: DateTime;
  plannedDateTime?: DateTime;
  sentDateTime?: DateTime;
  savedDateTime: DateTime;

  constructor(dto: MailingDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.activityName = dto.activityName;
    this.registrationLinkName = dto.registrationLinkName;
    this.formName = dto.formName;
    this.type = dto.type;
    this.status = dto.status;
    this.locale = dto.locale;
    this.planningMode = dto.planningMode;
    this.relativePlanningDayOffset = dto.relativePlanningDayOffset;
    this.relativePlanningTimeOfDay = dto.relativePlanningTimeOfDay
      ? DateTime.fromISO(dto.relativePlanningTimeOfDay)
      : undefined;
    this.plannedDateTime = dto.plannedDateTime
      ? DateTime.fromISO(dto.plannedDateTime)
      : undefined;
    this.sentDateTime = dto.sentDateTime
      ? DateTime.fromISO(dto.sentDateTime)
      : undefined;
    this.savedDateTime = DateTime.fromISO(dto.modifiedDateTime);
  }

  matchesFilter(search: string): boolean {
    return (
      search === "" ||
      this.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
    );
  }
}

export class MailingExtended extends Mailing {
  activityId?: string;
  registrationLinkId?: string;
  surveyLinkId?: string;
  subject?: string;
  fromName?: string;
  fromEmailAddress?: string;
  replyToEmailAddress?: string;
  mailingContent?: MailingContentExtendedDTO;
  selectionDefinitionId?: string;

  constructor(dto: MailingExtendedDTO) {
    super(dto);

    this.activityId = dto.activityId;
    this.registrationLinkId = dto.registrationLinkId;
    this.surveyLinkId = dto.surveyLinkId;
    this.subject = dto.subject;
    this.fromName = dto.fromName;
    this.fromEmailAddress = dto.fromEmailAddress;
    this.replyToEmailAddress = dto.replyToEmailAddress;
    this.mailingContent = dto.mailingContent;
    this.selectionDefinitionId = dto.selectionDefinitionId;
  }
}

export interface MailingStatisticsDTO {
  total: number;
  sent: number;
  hardBounces: number;
  softBounces: number;
  rejects: number;
  opens: number;
  clicks: number;
  unknown: number;
  registrations: number;
}

export class MailingStatistics {
  total: number;
  sent: number;
  hardBounces: number;
  softBounces: number;
  rejects: number;
  opens: number;
  clicks: number;
  unknown: number;
  registrations: number;

  constructor(dto: MailingStatisticsDTO) {
    this.total = dto.total;
    this.sent = dto.sent;
    this.hardBounces = dto.hardBounces;
    this.softBounces = dto.softBounces;
    this.rejects = dto.rejects;
    this.opens = dto.opens;
    this.clicks = dto.clicks;
    this.unknown = dto.unknown;
    this.registrations = dto.registrations;
  }
}
