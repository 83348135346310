<template>
  <div :key="form.values.answers?.length">
    <template v-for="(answer, answerIndex) in answers" :key="`${answerIndex}`">
      <template
        v-for="(localization, culture) in answer.localizations"
        :key="`${localization.language}-${answerIndex}-${culture}`"
      >
        <div
          v-show="
            culture === activeCulture && isQuestionWithAnswers(form.values.type)
          "
          :class="[answer.disabled ? 'opacity-50' : '', 'my-4']"
        >
          <div class="flex w-full justify-between align-middle">
            <div class="flex items-center gap-4">
              <Icon
                v-if="leadingIcon"
                :icon="leadingIcon"
                class="text-gray-300"
                :size="IconSize.sm"
              >
              </Icon>
              <FormFieldTextInputInline
                :id="`answers[${answerIndex}].localizations.${culture}.label`"
                :type="TextInputType.TEXT"
                css="w-full font-medium text-sm leading-6 text-gray-900"
                required
              />
            </div>

            <div class="row flex gap-2">
              <Icon
                v-if="
                  findNextEnabledAnswer(
                    'up',
                    answers.find((a) => a.identifier == answer.identifier)!,
                  ) &&
                  !answer.disabled &&
                  !answer.isOtherOption
                "
                icon="arrow_upward"
                :color="Color.Gray"
                class="opacity-50"
                :size="IconSize.sm"
                @click="
                  moveAnswerUp(
                    answers.find((a) => a.identifier == answer.identifier)!,
                  )
                "
              ></Icon>
              <Icon
                v-if="
                  findNextEnabledAnswer(
                    'down',
                    answers.find((a) => a.identifier == answer.identifier)!,
                  ) &&
                  !answer.disabled &&
                  !answer.isOtherOption
                "
                icon="arrow_downward"
                :color="Color.Gray"
                :size="IconSize.sm"
                class="opacity-50"
                @click="
                  moveAnswerDown(
                    answers.find((a) => a.identifier == answer.identifier)!,
                  )
                "
              ></Icon
              ><span class="cursor-default text-sm text-gray-200">{{
                "|"
              }}</span>
              <Icon
                v-if="isNewAnswer(answer)"
                icon="delete"
                :color="Color.Alert"
                :size="IconSize.sm"
                @click="deleteAnswer(answerIndex)"
              ></Icon>
              <Icon
                v-if="!answer.disabled && !newQuestion && !isNewAnswer(answer)"
                icon="visibility"
                :color="Color.Gray"
                :size="IconSize.sm"
                class="opacity-50"
                @click="disableAnswer(answer)"
              ></Icon>
              <Icon
                v-if="answer.disabled && !newQuestion"
                icon="visibility_off"
                :color="Color.Gray"
                :size="IconSize.sm"
                class="opacity-50"
                @click="enableAnswer(answer)"
              ></Icon>
            </div>
          </div>
        </div>
      </template>
    </template>
    <div
      v-if="isQuestionWithAnswers(form.values.type)"
      class="flex gap-4 text-sm"
    >
      <Icon
        v-if="leadingIcon"
        :icon="leadingIcon"
        class="text-gray-300"
        :size="IconSize.sm"
      ></Icon>
      <div class="cursor-pointer text-gray-500" @click="addAnswer(undefined)">
        {{ texts.models.answer.addAnswer }}
      </div>
      <div v-if="!otherAnswer" class="text-gray-500">
        {{ texts.models.answer.or }}
      </div>
      <div
        v-if="!otherAnswer"
        class="cursor-pointer text-royal-300"
        @click="addOtherAnswer"
      >
        {{ texts.models.answer.addOtherOption }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from "@/components/common/icon/Icon.vue";
import { Ref, computed, ref, watch } from "vue";
import { Color, Culture } from "@/enums";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import FormFieldTextInputInline from "@/components/common/text-input/FormFieldTextInputInline.vue";
import {
  AnswerFormValues,
  QuestionEditorFormValues,
} from "@/views/settings/questions/components/question-settings/QuestionSettings.types";
import { FormContext } from "vee-validate";
import {
  localizationArrayToObject,
  isQuestionWithAnswers,
} from "./QuestionSettings.types";
import {
  QuestionEditorQuestionDTO,
  QuestionType,
} from "@/lib/formsServiceClient";
import texts from "@/utils/texts";
import { IconSize } from "@/components/common/icon/Icon.types";

const props = defineProps<{
  originalQuestion: QuestionEditorQuestionDTO;
  activeCulture: Culture;
  form: FormContext<QuestionEditorFormValues, QuestionEditorFormValues>;
  newQuestion?: boolean;
  definedLocalizations: Culture[];
}>();

const answers = computed({
  get: () => {
    return props.form.values.answers ?? [];
  },
  set: (value) => {
    props.form.setValues({
      ...props.form.values,
      answers: value,
    });
  },
});

const leadingIcon = computed(() => {
  switch (props.form.values.type) {
    case QuestionType.RadioButton:
      return "radio_button_unchecked";
    case QuestionType.CheckBox:
      return "check_box_outline_blank";
    case QuestionType.DropDown:
      return "check_circle";
    default:
      return undefined;
  }
});

const emit = defineEmits(["disable:answers"]);

const amountOfNewAnswers = ref(0);
const otherAnswer: Ref<AnswerFormValues | undefined> = ref(undefined);

const addAnswer = (newAnswerLabel?: string) => {
  amountOfNewAnswers.value--;
  const newLocalizations: { language: string; label?: string }[] =
    props.definedLocalizations.map((culture) => ({
      language: culture,
      label: newAnswerLabel ?? texts.models.answer.newAnswer,
    }));

  const newAnswer: AnswerFormValues = {
    identifier: amountOfNewAnswers.value.toString(),
    localizations: localizationArrayToObject(newLocalizations),
    disabled: false,
  };
  if (props.form.values.answers === undefined) {
    props.form.setFieldValue("answers", [newAnswer]);
  } else if (otherAnswer.value) {
    props.form.setFieldValue("answers", [
      ...props.form.values.answers.filter((a) => !a.isOtherOption),
      newAnswer,
      otherAnswer.value,
    ]);
  } else {
    props.form.setFieldValue("answers", [
      ...props.form.values.answers,
      newAnswer,
    ]);
  }
  props.form.validate();
};

const addOtherAnswer = () => {
  amountOfNewAnswers.value--;
  const newLocalizations: { language: string; label?: string }[] =
    props.definedLocalizations.map((culture) => ({
      language: culture,
      label: texts.models.answer.otherOption,
    }));

  const newAnswer: AnswerFormValues = {
    identifier: amountOfNewAnswers.value.toString(),
    localizations: localizationArrayToObject(newLocalizations),
    disabled: false,
    isOtherOption: true,
  };
  otherAnswer.value = newAnswer;
  if (props.form.values.answers === undefined) {
    props.form.setFieldValue("answers", [newAnswer]);
  } else {
    props.form.setFieldValue("answers", [
      ...props.form.values.answers,
      newAnswer,
    ]);
  }
};

const deleteAnswer = (i: number) => {
  amountOfNewAnswers.value++;
  const newAnswers = [...(props.form.values.answers ?? [])].filter(
    (_, index) => index !== i,
  );
  if (answers.value[i].isOtherOption) {
    otherAnswer.value = undefined;
  }

  props.form.setFieldValue("answers", newAnswers);
};

const isNewAnswer = (answer: AnswerFormValues) => {
  return props.originalQuestion.answers?.find(
    (a) => a.identifier === answer.identifier,
  )
    ? false
    : true;
};

const disableAnswer = (answer: AnswerFormValues) => {
  props.form.setValues({
    ...props.form.values,
    answers: answers.value.map((a) =>
      a.identifier === answer.identifier ? { ...a, disabled: true } : a,
    ),
  });
  emit("disable:answers");
};

const enableAnswer = (answer: AnswerFormValues) => {
  props.form.setValues({
    ...props.form.values,
    answers: answers.value.map((a) =>
      a.identifier === answer.identifier ? { ...a, disabled: false } : a,
    ),
  });
};

const moveAnswerUp = (answer: AnswerFormValues) => {
  const answerIndex = answers.value.findIndex(
    (a) => a.identifier === answer.identifier,
  );

  const otherAnswer = findNextEnabledAnswer("up", answer);
  const otherAnswerIndex = answers.value.findIndex(
    (a) => a.identifier === otherAnswer?.identifier,
  );

  const answerCopy = answers.value.slice(answerIndex, answerIndex + 1);
  const newArray = [...answers.value];
  newArray[answerIndex] = newArray[otherAnswerIndex];
  newArray[otherAnswerIndex] = answerCopy[0];
  props.form.setFieldValue("answers", newArray);
};

const moveAnswerDown = (answer: AnswerFormValues) => {
  const answerIndex = answers.value.findIndex(
    (a) => a.identifier === answer.identifier,
  );

  const otherAnswer = findNextEnabledAnswer("down", answer);
  const otherAnswerIndex = answers.value.findIndex(
    (a) => a.identifier === otherAnswer?.identifier,
  );

  const answerCopy = answers.value.slice(answerIndex, answerIndex + 1);
  const newArray = [...answers.value];
  newArray[answerIndex] = newArray[otherAnswerIndex];
  newArray[otherAnswerIndex] = answerCopy[0];
  props.form.setFieldValue("answers", newArray);
};

const findNextEnabledAnswer = (
  direction: "up" | "down",
  answer: AnswerFormValues,
) => {
  const answerIndex = answers.value.findIndex(
    (a) => a.identifier === answer.identifier,
  );
  if (direction === "up") {
    return answers.value
      .filter((a, i) => i < answerIndex)
      .toSorted((a, b) => answers.value.indexOf(b) - answers.value.indexOf(a))
      .find((a) => !a.disabled && !a.isOtherOption);
  } else {
    return answers.value
      .filter((a, i) => i > answerIndex)
      .toSorted((a, b) => answers.value.indexOf(a) - answers.value.indexOf(b))
      .find((a) => !a.disabled && !a.isOtherOption);
  }
};

watch(
  () => props.form.values.type,
  (newType, prevType) => {
    if (!props.newQuestion) {
      return;
    }
    if (isQuestionWithAnswers(prevType) && !isQuestionWithAnswers(newType)) {
      props.form.setFieldValue("answers", []);
    } else if (
      !isQuestionWithAnswers(prevType) &&
      isQuestionWithAnswers(newType)
    ) {
      addAnswer(texts.models.answer.answer1);
      addAnswer(texts.models.answer.answer2);
    }
  },
);
</script>
