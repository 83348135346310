<template>
  <DropdownWrapper :origin="DropdownOrigin.TopRight">
    <template v-slot:button>
      <ButtonGroupMenuButton
        :color="Color.Gray"
        :size="ButtonSize.sm"
        flat
        round
        >{{ label }}</ButtonGroupMenuButton
      >
    </template>
    <template v-slot:items>
      <DropdownItem
        v-for="item in items"
        :key="item.value"
        :icon="item.icon"
        @click="modelValueInternal = item.value"
        >{{ item.label }}</DropdownItem
      >
    </template>
  </DropdownWrapper>
</template>

<script setup lang="ts">
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { Color } from "@/enums";
import texts from "@/utils/texts";
import { ButtonSize } from "@/components/common/button/Button.types";
import { computed } from "vue";
import { QuestionType } from "@/lib/formsServiceClient";
import { leadingIconForQuestionType } from "@/views/settings/questions/components/question-settings/QuestionSettings.types";
import { IconNames } from "@/components/common/icon/Icon.types";

const props = defineProps<{
  modelValue: QuestionType | undefined;
}>();

const modelValueInternal = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});

const emit = defineEmits<{
  "update:modelValue": [value: QuestionType | undefined];
}>();

const label = computed(
  () => items.find((item) => item.value === modelValueInternal.value)?.label,
);

const items: {
  label: string;
  value?: QuestionType;
  icon: IconNames | undefined;
}[] = [
  {
    label: texts.navigationItems.manage.settings.templates.typeFilterAll,
    value: undefined,
    icon: undefined,
  },
  ...Object.values(QuestionType)
    .map((type) => ({
      label: texts.enums.questionType[type],
      value: type,
      icon: leadingIconForQuestionType(type),
    }))
    .toSorted((a, b) => a.label.localeCompare(b.label)),
];
</script>
