<template>
  <Button
    v-bind="$attrs"
    :label="label"
    :color="Color.White"
    :leadingIcon="'visibility'"
    @click="showMailingContent"
  ></Button>

  <Modal v-model:visible="mailingContentModalVisible" :size="ModalSize.Large">
    <template v-slot:content>
      <div v-if="!mailingContent" data-testid="no-emailcontent">
        {{ texts.navigationItems.mailing.overview.noTemplate }}
      </div>
      <iframe
        v-if="mailingContent"
        :srcdoc="mailingContent"
        class="h-[50vh] w-full"
      ></iframe>
    </template>
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          data-testid="hide-emailcontent"
          :label="texts.actions.close"
          @click="hideMailingContent"
        ></Button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Color } from "@/enums";

// Components
import Modal from "@/components/common/modal/Modal.vue";
import Button from "@/components/common/button/Button.vue";
import { ModalSize } from "@/components/common/modal/Modal.types";
import texts from "@/utils/texts";

defineProps<{ label: string; mailingContent?: string }>();

const mailingContentModalVisible = ref(false);
const showMailingContent = () => {
  mailingContentModalVisible.value = true;
};

const hideMailingContent = () => {
  mailingContentModalVisible.value = false;
};
</script>
