import { Culture } from "@/enums";
import { useFieldArray } from "vee-validate";
import settings from "@/store/context/settings.context";

export type Localization<L> = LocalizationLocale & L;

interface LocalizationLocale {
  locale: Culture;
}
export type QueryableLocalizations<L> = {
  [K in Culture]?: L;
};

export function getLocalizationsQueryable<L>(
  localizations: Localization<L>[],
): QueryableLocalizations<L> {
  const result = {} as QueryableLocalizations<L>;

  localizations.forEach((localization) => {
    const locale = localization.locale;

    if (!result[locale]) {
      result[locale] = {} as L;
    }

    const keys = Object.keys(localization).filter(
      (k) => k !== "locale",
    ) as (keyof L)[];

    keys.forEach((k) => {
      if (!result[locale]) {
        throw new Error("Locale should be initialized.");
      }
      // Typescript does not understand that result[locale] is non-null in this case.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[locale][k] = localization[k];
    });
  });

  return result;
}

export function getLocalizationDTO<L>(
  queryable: QueryableLocalizations<L>,
): Localization<L>[] {
  const result = [] as Localization<L>[];

  let key: Culture;
  for (key in queryable) {
    const properties = queryable[key];
    if (properties) {
      result.push({
        locale: key,
        ...properties,
      });
    }
  }

  return result;
}

/**
 * @deprecated Please use `useLocalizationFields` and set initial values using form.setValues
 * @param initial
 */
export function useLocalizations<T>(initial?: Localization<T>[]) {
  const fieldId = "localizations";
  const { fields, push } = useFieldArray<Localization<T>>(fieldId);

  settings.availableLanguages.forEach((language) => {
    const localization = initial?.find(
      (localization) => localization.locale === language.locale.value,
    );
    push({
      locale: language.locale.value as Culture,
      ...localization,
    } as Localization<T>);
  });

  return {
    fieldId: fieldId,
    localizations: fields,
  };
}

/**
 * Returns a FieldArray that can be used with the FormFieldArrayHelper to render.
 * Makes sure all available languages are added. Values are not initialized.
 */
export function useLocalizationFields<T extends { locale: Culture }>(
  fieldId = "localizations",
) {
  const { fields } = useFieldArray<T>(fieldId);

  return { fieldId, fields };
}

export function normalizeLocalizations<T extends { locale: Culture }>(
  localizationDtos?: T[],
) {
  return settings.availableLanguages
    .toSorted((a) => (a.id === settings.mainLanguage.id ? -1 : 1))
    .map((language) => language.locale.value as Culture)
    .map(
      (locale) =>
        ({
          locale: locale,
          ...localizationDtos?.find((loc) => loc.locale === locale),
        }) as T,
    );
}
