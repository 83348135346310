import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/style.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "./yup-extensions";

import logger, { InsightsLoggerPlugin } from "@/plugins/logger";
import { InsightsTrackerPlugin } from "@/plugins/tracker";
import notifications from "@kyvg/vue3-notification";
import { createAuth0 } from "@auth0/auth0-vue";
import env from "@/env";

const loggerPlugin = new InsightsLoggerPlugin(router);
const trackerPlugin = new InsightsTrackerPlugin(router, logger);

async function init() {
  createApp(App)
    .use(router)
    .use(
      createAuth0({
        domain: env.VITE_AUTH0_DOMAIN,
        clientId: env.VITE_AUTH0_CLIENT_KEY,

        authorizationParams: {
          audience: env.VITE_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin,
        },
      }),
    )
    .use(loggerPlugin)
    .use(trackerPlugin)
    .use(notifications)
    .mount("#app");
}

init();
