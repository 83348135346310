import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import settings from "@/store/context/settings.context";
import { DateTime } from "luxon";
import * as yup from "yup";

declare module "yup" {
  interface StringSchema {
    requiredWhenMainLocale(): yup.StringSchema;
    isDomainName(message: string): yup.StringSchema;
  }

  interface MixedSchema {
    isInFuture(message: string): yup.MixedSchema;
    isLaterThan(key: string | DateTime, message: string): yup.MixedSchema;
    isEarlierThan(key: string | DateTime, message: string): yup.MixedSchema;
  }
}

function requiredWhenMainLocale(this: yup.StringSchema) {
  return this.when("locale", {
    is: (locale: Culture) => locale === settings.mainLanguage.locale.value,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  });
}
yup.addMethod<yup.StringSchema>(
  yup.string,
  "requiredWhenMainLocale",
  requiredWhenMainLocale,
);

function isInFuture(this: yup.Schema<DateTime>, message: string) {
  return this.test("date-time-is-in-future", message, (value) => {
    if (!value) return true;

    return value.diff(DateTime.now()).milliseconds > 0;
  });
}

yup.addMethod(yup.mixed, "isInFuture", isInFuture);

function isLaterThan(
  this: yup.Schema<DateTime>,
  key: string | DateTime,
  message: string,
) {
  return this.test("is-later-than-date-time", message, (value, { parent }) => {
    if (!value) return true;

    // The key can reference a field (string) or a static DateTime object
    const comparisonValue =
      typeof key === "string" ? parent[key] : (key as DateTime);
    if (!comparisonValue) return true;

    return value.diff(comparisonValue).milliseconds >= 0;
  });
}

yup.addMethod(yup.mixed, "isLaterThan", isLaterThan);

function isEarlierThan(
  this: yup.Schema<DateTime>,
  key: string | DateTime,
  message: string,
) {
  return this.test(
    "is-earlier-than-date-time",
    message,
    (value, { parent }) => {
      if (!value) return true;

      const compare = typeof key === "string" ? parent[key] : (key as DateTime);
      return compare && value.diff(compare).milliseconds <= 0;
    },
  );
}

yup.addMethod(yup.mixed, "isEarlierThan", isEarlierThan);

function isDomainName(this: yup.StringSchema, message: string) {
  return this.test("is-domain-name", message, (value) => {
    if (!value) return true; // Skip validation if the value is empty or undefined

    const maxTotalLength = 253;
    const maxLabelLength = 63;
    const labelRegex = /^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;
    const tldRegex = /^[a-zA-Z]{2,63}$/;

    if (value.length > maxTotalLength) {
      return false; // The domain cannot exceed maxTotalLength characters
    }

    const labels = value.split(".");
    if (labels.length < 2) {
      return false; // Domain must have at least one period
    }

    const tld = labels.pop();
    if (!tld || !tldRegex.test(tld)) {
      return false; // Top-level domain must be valid
    }

    for (const label of labels) {
      if (label.length > maxLabelLength) {
        return false; // Each label in the domain name cannot exceed maxLabelLength characters
      }

      if (!labelRegex.test(label)) {
        return false; // Each label must consist of alphanumeric characters and hyphens, but cannot start or end with a hyphen
      }
    }

    return true;
  });
}

yup.addMethod(yup.string, "isDomainName", isDomainName);

export function alterYupLocalizations(culture: Culture) {
  const texts = dictionary[culture];
  // https://github.com/jquense/yup#api
  yup.setLocale({
    mixed: {
      required: texts.validation.required,
    },
    string: {
      max: texts.validation.maxLength,
      email: texts.validation.email,
      url: texts.validation.url,
    },
  });
}
