<template>
  <Section>
    <PageHeading :title="texts.navigationItems.mailings.title" />
  </Section>
  <MailingTypePicker
    v-model:visible="mailingTypePickerVisible"
    :title="texts.navigationItems.mailings.mailingTypePicker.title"
    :description="texts.navigationItems.mailings.mailingTypePicker.description"
    :mailingTypeGroups="mailingTypeGroups"
  ></MailingTypePicker>
  <Page :loading="loading" :error="error" class="flex flex-row gap-4">
    <div class="grid flex-grow grid-cols-12 gap-12">
      <div class="col-span-3 flex flex-col gap-4">
        <MailingFilter
          :mailings="mailings"
          :mailingTypes="settings.mailingTypes.map((mt) => mt.mailingType)"
          :mailingStates="statuses"
          @filter="
            (value) => {
              filteredMailings = value;
            }
          "
          @selectedTypes="
            (value) => {
              selectedMailingStates = value;
            }
          "
        ></MailingFilter>
      </div>
      <div class="col-span-9">
        <div class="mb-10">
          <div class="mb-auto mt-auto inline text-xl font-semibold">
            {{ mailingCount }} {{ texts.generic.results }}
          </div>
          <ButtonAdd
            v-show="canWrite"
            data-testid="new-mailing-button"
            class="float-right inline"
            :onClick="
              () => (mailingTypePickerVisible = !mailingTypePickerVisible)
            "
          />
        </div>
        <div class="flex flex-col gap-12">
          <template v-for="(group, key) in groupedMailings" :key="key">
            <List
              v-if="!selectedMailingStates || group.length > 0"
              :title="texts.enums.mailingStatus[key]"
            >
              <RouterLinkAuth
                v-for="mailing in group"
                :key="mailing.id"
                v-slot="{ navigate }"
                :to="{
                  name: RouteNamesMailing.OVERVIEW,
                  params: { id: mailing.id },
                }"
                custom
              >
                <MailingItem :mailing="mailing" @click="navigate" />
              </RouterLinkAuth>
            </List>
          </template>
        </div>
      </div>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { MailingStatus } from "@/enums";
import { computed, ref } from "vue";

// Components
import Page from "@/components/common/page/Page.vue";
import logger from "@/plugins/logger";
import { useRoute } from "vue-router";
import List from "@/components/common/list/List.vue";
import MailingItem from "@/views/mailings/components/MailingItem.vue";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import MailingTypePicker from "@/components/mailing-type-picker/MailingTypePicker.vue";
import { MailingTypeGroup } from "@/components/mailing-type-picker/MailingTypePicker.types";
import {
  conceptSortFn,
  plannedSortFn,
  sentSortFn,
  toMailingTypeGroups,
} from "./MainPage.types";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Section from "@/components/common/section/Section.vue";
import MailingFilter from "./components/MailingFilter.vue";
import { hasPermissions } from "@/router/helpers/userCanGoTo";
import settings from "@/store/context/settings.context";
import {
  ActivityRelatedMailingOverviewDTO,
  Permission,
} from "@/lib/eduConfigurationServiceClient";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { RouteNamesMailing } from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import texts from "@/utils/texts";

const route = useRoute();
// Store setup
const canWrite = hasPermissions([Permission.MailingsWrite]);

const defaultStatuses = [
  MailingStatus.Planned,
  MailingStatus.Sent,
  MailingStatus.Failed,
  MailingStatus.Concept,
];

const mailings = ref<ActivityRelatedMailingOverviewDTO[]>([]);
const filteredMailings = ref<ActivityRelatedMailingOverviewDTO[]>([]);
const mailingCount = computed(() => {
  return (
    (plannedMailings.value?.length || 0) +
    (sentMailings.value?.length || 0) +
    (failedMailings.value?.length || 0) +
    (conceptMailings?.value?.length || 0)
  );
});

const determineLandingStatuses = (
  mailingStatus?: MailingStatus,
): MailingStatus[] => {
  if (!mailingStatus) {
    return defaultStatuses;
  } else if (defaultStatuses.includes(mailingStatus)) {
    return defaultStatuses;
  }
  return [mailingStatus];
};

const mailingTypePickerVisible = ref(false);

const mailingTypeGroups: MailingTypeGroup[] = toMailingTypeGroups(
  settings.mailingTypes,
  settings.culture,
  false,
);
// Page filters
const statuses = ref<MailingStatus[]>(
  determineLandingStatuses(route.params["statusFilter"] as MailingStatus),
);

const selectedMailingStates = ref<MailingStatus[] | undefined>();

// Loading & Error
const loading = ref<boolean>(true);
const error = ref<boolean>(false);

const groupedMailings = computed<
  Record<
    "Sent" | "Planned" | "Concept" | "Failed",
    ActivityRelatedMailingOverviewDTO[]
  >
>(() => ({
  [MailingStatus.Planned]: plannedMailings.value,
  [MailingStatus.Concept]: conceptMailings.value,
  [MailingStatus.Sent]: sentMailings.value,
  [MailingStatus.Failed]: failedMailings.value,
}));

const plannedMailings = computed(() =>
  filteredMailings.value
    .filter(
      (mailing: ActivityRelatedMailingOverviewDTO) =>
        mailing.status === MailingStatus.Planned ||
        mailing.status === MailingStatus.InProgress,
    )
    .toSorted(plannedSortFn),
);
const sentMailings = computed(() =>
  filteredMailings.value
    .filter(
      (mailing: ActivityRelatedMailingOverviewDTO) =>
        mailing.status === MailingStatus.Sent,
    )
    .toSorted(sentSortFn),
);

const failedMailings = computed(() =>
  filteredMailings.value
    .filter(
      (mailing: ActivityRelatedMailingOverviewDTO) =>
        mailing.status === MailingStatus.Failed,
    )
    .toSorted(sentSortFn),
);

const conceptMailings = computed(() =>
  filteredMailings.value
    .filter(
      (mailing: ActivityRelatedMailingOverviewDTO) =>
        mailing.status === MailingStatus.Concept,
    )
    .toSorted(conceptSortFn),
);

eduConfigurationServiceClient
  .getMailings(undefined, false)
  .then((res) => {
    mailings.value = res;
  })
  .catch((e) => {
    error.value = true;
    logger.log(e);
  })
  .finally(() => {
    loading.value = false;
  });
</script>
