<template>
  <Page
    v-if="surveys"
    :loading="loading"
    :error="error"
    class="flex flex-col gap-8"
  >
    <div class="flex justify-between gap-2">
      <PageHeading>
        <span class="print:hidden">{{
          textsReports.visitedSurveys.cards.submitted.title
        }}</span>
        <span class="hidden print:inline">{{ activeSurvey?.name }}</span>
      </PageHeading>
      <div class="flex gap-4">
        <Button
          v-if="canViewPersonalData()"
          :label="textsReports.visitedSurveys.cards.submitted.exportBtn"
          :loading="downloadingVisitedSurveysExcel"
          data-testid="downloadVisitedSurveysExcel"
          :leadingIcon="'download'"
          :color="pageColor"
          @click="downloadExcel"
        ></Button>
        <Button
          :label="textsReports.visitedSurveys.cards.submitted.printBtn"
          :leadingIcon="'pie_chart'"
          :color="pageColor"
          @click="printResults"
        >
        </Button>
      </div>
    </div>
    <RouterNavigationTabs :tabs="tabs" />
    <RefreshableRouterView />
  </Page>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { ActivityExtended } from "@/models/activity";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Page from "@/components/common/page/Page.vue";
import { IActivitySurveyLinkDto } from "@/lib/eduConfigurationServiceClient";
import { RouteNamesActivityReports } from "@/router/routeNames";
import RouterNavigationTabs from "@/components/router/router-navigation-tabs/RouterNavigationTabs.vue";
import { IconNames } from "@/components/common/icon/Icon.types";
import RefreshableRouterView from "@/components/router/RefreshableRouterView.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import texts from "@/utils/texts";
import Button from "@/components/common/button/Button.vue";
import { Color } from "@/enums";
import { downloadVisitedSurveysExcel } from "@/services/report.service";
import Notify from "@/utils/notify";
import logger from "@/plugins/logger";
import { canViewPersonalData } from "@/router/helpers/userCanGoTo";

const props = defineProps<{
  activity: ActivityExtended;
}>();

const textsReports = texts.navigationItems.organize.activity.reports;

const pageColor: Color = Color.Fuchsia;

const loading = ref<boolean>(false);
const error = ref<boolean>(false);

const surveys = ref<IActivitySurveyLinkDto[]>();

const activeSurveyLinkId = computed(
  () => router.currentRoute.value.params.surveyLinkId as string,
);
const activeSurvey = computed(() =>
  surveys.value?.find(
    (survey) => survey.id === router.currentRoute.value.params.surveyLinkId,
  ),
);

const router = useRouter();
const initialization = async () => {
  try {
    loading.value = true;
    surveys.value = await eduConfigurationServiceClient.getSurveysByActivityId(
      props.activity.id,
    );
    router.replace(tabs.value[0].to);
  } catch (err) {
    error.value = true;
    throw err;
  } finally {
    loading.value = false;
  }
};
initialization();

const tabs = computed(
  () =>
    surveys.value?.map((survey) => ({
      name: survey.name,
      to: {
        name: RouteNamesActivityReports.VISITED_SURVEYS_SURVEY,
        params: { surveyLinkId: survey.id },
      },
      icon: "quiz" as IconNames,
    })) ?? [],
);

// Fetching Excel
const downloadingVisitedSurveysExcel = ref<boolean>(false);

const downloadExcel = () => {
  downloadingVisitedSurveysExcel.value = true;
  downloadVisitedSurveysExcel(props.activity.id, activeSurveyLinkId.value)
    .then(() => {
      Notify.success(
        textsReports.visitedSurveys.cards.submitted.export.success,
      );
    })
    .catch((error) => {
      Notify.failure(
        textsReports.visitedSurveys.cards.submitted.export.failure,
      );
      logger.error(error);
    })
    .finally(() => {
      downloadingVisitedSurveysExcel.value = false;
    });
};

const printResults = () => {
  setTimeout(() => {
    print();
  }, 1000);
};
</script>
