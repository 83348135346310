<template>
  <TransitionFade>
    <div
      v-if="isAuthenticated"
      class="my-auto flex h-full animate-pulse flex-col items-center justify-center gap-4"
    >
      <HippoLogo class="-mt-2 max-h-32" />
    </div>
  </TransitionFade>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import { watchEffect } from "vue";
import { useRouter } from "vue-router";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import logger from "@/plugins/logger";
import { RouteNames } from "@/router/routeNames";
import { setAuthContext } from "@/store/context/auth.context";
import HippoLogo from "@/components/logo/HippoLogo.vue";

const {
  isAuthenticated,
  isLoading,
  loginWithRedirect,
  getAccessTokenSilently,
  user,
} = useAuth0();

const router = useRouter();

watchEffect(async () => {
  try {
    // Wait for Auth0 to finish loading, for if we check 'isAuthenticated' to soon,
    // 'isAuthenticated' will still be false and we will get in a login-loop
    if (isLoading.value) return;
    if (!isAuthenticated.value) {
      await loginWithRedirect();
      return;
    }

    // If the user is not loaded, we haven't got all information
    // to properly set the auth-context
    if (!user.value) {
      return;
    }

    const token = await getAccessTokenSilently();
    setAuthContext(token, user.value);

    setTimeout(() => router.replace({ name: RouteNames.DASHBOARD }), 1000);
  } catch (e) {
    logger.error(e);
    router.replace({ name: RouteNames.UNAVAILABLE });
  }
});
</script>
