import activityContext, {
  clearActivityContext,
  loadActivityContext,
} from "@/store/context/activity.context";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { RouteNames } from "../routeNames";
import {
  ActivityDTO,
  AffiliateLinkOverviewItemDto,
  RegistrationLinkWithRegistrationCountDTO,
  ActivityVisitedSurveyLinkOverviewDTO,
} from "@/lib/eduConfigurationServiceClient";

export async function beforeEnterActivity(
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const id = to.params.id as string | undefined;
  clearActivityContext();

  if (id) await loadActivityContext(id);

  next();
}

export interface ActivityRouteProps {
  id: string;
  activity: ActivityDTO;
  registrationLinks: RegistrationLinkWithRegistrationCountDTO[];
  affiliateLinks: AffiliateLinkOverviewItemDto[];
  surveyLinks: ActivityVisitedSurveyLinkOverviewDTO[];
}

export interface ActivityRoutePropsCreate {
  id: undefined;
  activity: undefined;
  registrationLinks: RegistrationLinkWithRegistrationCountDTO[];
}

export function activityRouteProps(route: RouteLocationNormalized) {
  return {
    id: route.params.id,
    activity: activityContext.activity,
    registrationLinks: activityContext.registrationLinks,
    affiliateLinks: activityContext.affiliateLinks,
    surveyLinks: activityContext.surveyLinks,
  };
}

export function beforeRouteCheckIfId(
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (!to.params.id) next({ name: RouteNames.ACTIVITY_EDIT });
  else next();
}
