<template>
  <Page :error="error">
    <Section>
      <div class="flex">
        <div class="flex-1">
          <Dropdown
            :label="texts.navigationItems.mailing.steps.template.selectTemplate"
            :loading="beeTemplateLoading || bee.state.loading || loading"
            :disabled="beeTemplateLoading || bee.state.loading || loading"
            ><DropdownItem
              v-for="(item, i) in templates"
              :key="i"
              :label="item.name"
              @click="selectTemplate(item)"
            ></DropdownItem
          ></Dropdown>
        </div>
        <Toggle
          v-model="bee.state.preview"
          data-testid="toggle-preview"
          class="ml-3"
          :label="texts.actions.showPreview"
        />
        <Button
          class="ml-8"
          data-testid="open-testmail-modal"
          :label="texts.navigationItems.mailing.steps.template.testMail.button"
          :leadingIcon="'send'"
          :color="Color.White"
          @click="openTestMailModal"
        />
      </div>
    </Section>
    <div :id="containerId" class="mb-1 h-beeEditor shadow-sm"></div>

    <Modal
      v-model:visible="testMailModalVisible"
      :title="texts.navigationItems.mailing.steps.template.testMail.modal.title"
      :description="
        texts.navigationItems.mailing.steps.template.testMail.modal.description
      "
      :centered="true"
    >
      <template v-slot:content>
        <TestMailForm
          :loading="isSendingTestMail"
          :canSendTestMail="canSendTestMail"
          :testMailingSources="testMailingSources"
          @submit="submitForm"
          @update:visible="testMailModalVisible = $event"
        />
      </template>
    </Modal>
  </Page>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { Color } from "@/enums";
import {
  ConceptMailingData,
  MailingContextData,
} from "@/views/mailings/mailing/MailingWizard.types";

import Dropdown from "@/components/common/dropdown/Dropdown.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Section from "@/components/common/section/Section.vue";
import Button from "@/components/common/button/Button.vue";
import Toggle from "@/components/common/toggle/Toggle.vue";
import { SaveResponse, useBee } from "@/utils/bee/bee-instance";
import MailingTemplate from "@/models/mailing-templates";
import {
  getMailingTemplate,
  getMailingTemplatesByType,
} from "@/services/mailing-templates.service";
import logger from "@/plugins/logger";
import Page from "@/components/common/page/Page.vue";
import Notify from "@/utils/notify";
import TestMailForm from "@/views/mailings/mailing/steps/components/TestMailForm.vue";
import Modal from "@/components/common/modal/Modal.vue";
import {
  TestMailingDto,
  TestMailingSource,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import texts from "@/utils/texts";

const emit = defineEmits([
  "forwardNavigationValidated",
  "backNavigationValidated",
  "saveValidated",
]);

const props = defineProps<{
  isNavigatingBack: boolean;
  isNavigatingForward: boolean;
  isSaving: boolean;
  data: ConceptMailingData;
  contextData: MailingContextData;
}>();

// Loading & Error
const loading = ref<boolean>(true);
const error = ref<boolean>(false);

// Navigation
watch(
  () => props.isNavigatingForward,
  (value) => {
    if (!value) return;
    beeSave();
  },
);

watch(
  () => props.isNavigatingBack,
  (value) => {
    if (!value) return;
    emit("backNavigationValidated", true);
  },
);

watch(
  () => props.isSaving,
  (value) => {
    if (!value) return;
    beeSave();
  },
);

// Email-templates
if (!props.data.type)
  throw "Cannot get email templates by type, because type has not yet been set";

const templates = ref<MailingTemplate[]>([]);
getMailingTemplatesByType(props.data.type)
  .then((res) => (templates.value = res))
  .catch((e) => {
    error.value = true;
    logger.error(e);
  })
  .finally(() => {
    loading.value = false;
  });

const selectTemplate = (template: MailingTemplate) => {
  beeTemplateLoading.value = true;

  return getMailingTemplate(template.id)
    .then((res) => bee.load(res.configuration))
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      beeTemplateLoading.value = false;
    });
};

// Bee
const internalData = computed(() => props.data);

const containerId = "bee-container";
const bee = useBee(containerId);
bee.init({
  template: internalData.value.mailingContent?.configuration,
  mergeTags: props.contextData.mailingType.mergeTags,
  mergeContents: props.contextData.mailingType.mergeContents,
  specialLinks: props.contextData.mailingType.specialLinks,
});
const beeTemplateLoading = ref(false);

const beeSave = async () => {
  const saveResponse = await bee.save();
  handleSaveResponse(saveResponse);
};
const isSendingTestMail = ref(false);

const handleSaveResponse = (response: SaveResponse) => {
  internalData.value.mailingContent = {
    configuration: JSON.parse(response[0]),
    content: response[1],
  };

  if (props.isSaving) {
    emit("saveValidated", true);
  }

  if (props.isNavigatingForward) {
    emit("forwardNavigationValidated", true);
  }

  if (isSendingTestMail.value) {
    sendTestMail(testMailSourceId.value, testMailRecipient.value);
  }
};

// Test mail
const canSendTestMail = computed(() => (props.contextData.id ? true : false));
const openTestMailModal = async () => {
  if (props.contextData.id) {
    await fetchTestMailingSources(props.contextData.id);
  }
  testMailModalVisible.value = true;
};

const testMailModalVisible = ref(false);
const testMailSourceId = ref<string>();
const testMailRecipient = ref<string>();

const submitForm = async (values: { sourceId: string; email: string }) => {
  isSendingTestMail.value = true;
  testMailSourceId.value = values.sourceId;
  testMailRecipient.value = values.email;
  beeSave();
};

const sendTestMail = async (sourceId?: string, recipient?: string) => {
  if (!props.contextData.id) {
    throw new Error("Cannot send testmail without a mailing id");
  }

  if (!sourceId) {
    throw new Error(
      "Cannot send testmail without a selected prospect/registration",
    );
  }

  if (!recipient) {
    throw new Error("Cannot send testmail without a recipient");
  }

  const dto = TestMailingDto.fromJS({
    sourceId: sourceId,
    toEmailAddress: recipient,
  });

  try {
    await eduConfigurationServiceClient.sendTestMailing(
      props.contextData.id,
      dto,
    );
    Notify.success(
      texts.navigationItems.mailing.steps.template.testMail.post.success,
    );
  } catch (e) {
    Notify.failure(
      texts.navigationItems.mailing.steps.template.testMail.post.failure,
    );
    logger.error(e);
  } finally {
    isSendingTestMail.value = false;
    testMailRecipient.value = undefined;
    testMailModalVisible.value = false;
  }
};

const testMailingSources = ref<TestMailingSource[]>([]);
const fetchTestMailingSources = async (mailingId: string) => {
  try {
    const response =
      await eduConfigurationServiceClient.getTestMailingSources(mailingId);
    testMailingSources.value = response.sources;
  } catch (error) {
    logger.error(error);
  }
};
</script>
