import { Culture } from "@/enums";
import { useLocalStorage } from "@vueuse/core";

export const AVAILABLE_CULTURES = [
  Culture.NL,
  Culture.EN,
  Culture.FR,
  Culture.DE,
] as const;

export const DEFAULT_CULTURE = Culture.EN;
const CULTURE_STORAGE_KEY = "summit-edu-culture";

const browserCulture = AVAILABLE_CULTURES.filter(
  (culture) => culture.substring(0, 2) === navigator.language.substring(0, 2),
)[0];

const storedCulture = useLocalStorage<Culture>(
  CULTURE_STORAGE_KEY,
  browserCulture ?? DEFAULT_CULTURE,
);
export default storedCulture;
