import { Coordinates } from "@/lib/eduConfigurationServiceClient";

export function calculateCentroid(coordinates: Coordinates[]): Coordinates {
  const toRadians = (degree: number) => degree * (Math.PI / 180);
  const toDegrees = (radian: number) => radian * (180 / Math.PI);

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  coordinates.forEach((coord) => {
    const latitude = toRadians(coord.latitude);
    const longitude = toRadians(coord.longitude);

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  });

  const total = coordinates.length;

  x = x / total;
  y = y / total;
  z = z / total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return new Coordinates({
    latitude: toDegrees(centralLatitude),
    longitude: toDegrees(centralLongitude),
  });
}

export function removeOutlierCoordinates(
  center: Coordinates,
  coordinates: Coordinates[],
) {
  const distanceTuples = createCoordinateDistanceTuple(center, coordinates);

  const distances = distanceTuples.map((tuple) => tuple[1]);
  const stdDev = standardDeviation(distances);
  const mean = distances.reduce((a, b) => a + b) / distances.length;

  return distanceTuples
    .filter((tuple) => tuple[1] < mean + stdDev)
    .map((tuple) => tuple[0]);
}

function createCoordinateDistanceTuple(
  centerCoordinate: Coordinates,
  coordinates: Coordinates[],
): [coordinate: Coordinates, distance: number][] {
  return coordinates.map((coordinate) => {
    return [coordinate, distanceToCenter(centerCoordinate, coordinate)];
  });
}

function distanceToCenter(
  centerCoordinate: Coordinates,
  coordinate: Coordinates,
) {
  const latitude = centerCoordinate.latitude - coordinate.latitude;
  const longitude = centerCoordinate.longitude - coordinate.longitude;
  const distance = Math.sqrt(latitude * latitude + longitude * longitude);
  return distance;
}

function standardDeviation(numbers: number[]) {
  const mean = average(numbers);
  const intermediate = numbers.map((x) => Math.pow(x - mean, 2));
  return Math.sqrt(average(intermediate));
}

function average(numbers: number[]) {
  return numbers.reduce((a, b) => a + b) / numbers.length;
}
