<template>
  <div class="flex w-full items-center">
    <span class="mr-4 flex-1 text-sm">
      {{ texts.actions.confirmDelete.text }}
    </span>
    <Button
      data-testid="cancel-delete"
      :color="Color.White"
      :label="texts.actions.cancel"
      class="mr-4"
      :buttonElementType="ButtonElementType.button"
      @click="cancel"
    ></Button>
    <Button
      :data-testid="testIds.action.confirm"
      :loading="loading"
      :color="Color.Alert"
      :label="texts.actions.confirmDelete.action"
      :buttonElementType="ButtonElementType.button"
      @click="confirmDelete"
    ></Button>
  </div>
</template>

<script setup lang="ts">
import Button from "@/components/common/button/Button.vue";
import { Color } from "@/enums";
import { ButtonElementType } from "../button/Button.types";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";

// evading wrongly inferred types in unit-tests by explicitely typing 'emits'
const emit = defineEmits(["cancel", "confirm"]);

defineProps({
  loading: {
    type: Boolean,
    required: false,
  },
});

const cancel = () => {
  emit("cancel");
};

const confirmDelete = () => {
  emit("confirm");
};
</script>
