import { ITenantAnonymizationPeriodDTO } from "@/lib/eduConfigurationServiceClient";
import * as yup from "yup";

export interface AnonymizationPeriodSettingsFormValues {
  anonymizationPeriodInMonths?: number;
}

export const convertToDTO = (
  formValues: AnonymizationPeriodSettingsFormValues,
): ITenantAnonymizationPeriodDTO => {
  return {
    anonymizationPeriodInMonths: formValues.anonymizationPeriodInMonths,
  };
};

export const validationSchema = yup.object({
  anonymizationPeriodInMonths: yup.number().optional().min(12),
});
