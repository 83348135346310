<template>
  <div v-if="success" class="rounded-md bg-info-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <Icon
          icon="info"
          :size="IconSize.sm"
          filled
          class="text-info-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-info-800">
          {{ interpolate(componentTexts.success, importCount.toString()) }}
        </h3>
        <div class="mt-2 text-sm text-info-700">
          <ul
            v-if="messages && messages.length > 0"
            role="list"
            class="list-disc space-y-1 pl-5"
          >
            <li v-for="(message, index) in messages" :key="index">
              {{ message }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div v-if="errors && errors.length > 0" class="rounded-md bg-alert-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <Icon
          icon="error"
          :size="IconSize.sm"
          filled
          class="text-alert-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-alert-800">
          <template v-if="partialSuccess">
            {{
              interpolate(componentTexts.partialSuccess, importCount.toString())
            }}
          </template>
          <template v-else>
            {{ componentTexts.submitErrorList }}
          </template>
        </h3>
        <div class="mt-2 text-sm text-alert-700">
          <ul role="list" class="list-disc space-y-1 pl-5">
            <li v-for="(error, index) in errors" :key="index">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { computed } from "vue";
import { interpolate } from "@/dictionary";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";

const componentTexts = texts.commonComponents.import.results;

const success = computed(
  () =>
    props.importCount > 0 &&
    (props.errors === undefined || props.errors.length < 1),
);

const partialSuccess = computed(
  () => props.importCount > 0 && props.errors && props.errors.length > 0,
);

const props = defineProps<{
  importCount: number;
  errors?: string[];
  messages?: string[];
}>();
</script>
