import { Culture, EmailTemplateType } from "@/enums";
import {
  EmailTemplateCreateOrUpdateDTO,
  EmailTemplateExtended,
  EmailTemplateLocalizableProperties,
} from "@/models/emailTemplates";
import { Localization } from "@/models/helpers/localizable";
import settings from "@/store/context/settings.context";

export interface EmailTemplateFormValuesLocale {
  locale: Culture;
  subject: string;
  fromName: string;
  contentBeeConfiguration: string;
  contentHtml: string;
}
export interface EmailTemplateFormValues {
  name: string;
  fromEmailAddress: string;
  replyToEmailAddress: string;
  localizations?: Localization<Partial<EmailTemplateLocalizableProperties>>[];
}

export function toCreateDto(
  formValues: EmailTemplateFormValues,
  type: EmailTemplateType,
) {
  const createDto: EmailTemplateCreateOrUpdateDTO = {
    type,
    name: formValues.name,
    fromEmailAddress: formValues.fromEmailAddress,
    replyToEmailAddress: formValues.replyToEmailAddress,
    localizations:
      formValues.localizations as Localization<EmailTemplateLocalizableProperties>[],
  };
  return createDto;
}

export function toUpdateDto(
  formValues: EmailTemplateFormValues,
  type: EmailTemplateType,
) {
  const updateDto: EmailTemplateCreateOrUpdateDTO = {
    type,
    name: formValues.name,
    fromEmailAddress: formValues.fromEmailAddress,
    replyToEmailAddress: formValues.replyToEmailAddress,
    localizations:
      formValues.localizations as Localization<EmailTemplateLocalizableProperties>[],
  };
  return updateDto;
}

export function toFormValues(emailTemplate: EmailTemplateExtended) {
  const formValues: EmailTemplateFormValues = {
    name: emailTemplate.name,
    fromEmailAddress: emailTemplate.fromEmailAddress,
    replyToEmailAddress: emailTemplate.replyToEmailAddress,
    localizations: emailTemplate.localizations.toSorted((a) =>
      a.locale === settings.mainLanguage.locale.value ? -1 : 1,
    ),
  };
  return formValues;
}

export function createNewLocale(
  culture: Culture,
  beeConfig: string | undefined,
) {
  return {
    locale: culture,
    subject: undefined,
    fromName: undefined,
    contentBeeConfiguration: beeConfig,
    contentHtml: undefined,
  } as Localization<Partial<EmailTemplateLocalizableProperties>>;
}
