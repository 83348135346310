<template>
  <StudyProgramForm :formValues="formValues" @submit="onSubmit">
    <ButtonSubmit
      data-testid="submitForm"
      :loading="actionLoading"
    ></ButtonSubmit>
    <ButtonCancel @click="onCancel"></ButtonCancel>
  </StudyProgramForm>
</template>

<script setup lang="ts">
import { ref } from "vue";
import {} from "@/router/routeNames";
import logger from "@/plugins/logger";

import StudyProgramForm from "./StudyProgramForm.vue";
import {
  StudyProgramFormValues,
  convertToFormValues,
  convertToUpdateDTO,
} from "./StudyProgramForm.types";

import Notify from "@/utils/notify";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { UpdateStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import { loadStudyPrograms } from "@/store/context/settings.context";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import texts from "@/utils/texts";

const isArchived = ref<boolean>(true);

const loading = ref<boolean>(true);
const error = ref<boolean>(false);
const actionLoading = ref<boolean>(false);

const studyProgramName = ref<string>();
const formValues = ref<StudyProgramFormValues>();

const props = defineProps<{
  id: string;
}>();

const emit = defineEmits(["cancel", "submit"]);

eduConfigurationServiceClient
  .getStudyProgram(props.id)
  .then((response) => {
    studyProgramName.value = response.name;
    isArchived.value = response.isArchived;
    formValues.value = convertToFormValues(response);
  })
  .catch((e) => {
    error.value = true;
    logger.error(e);
  })
  .finally(() => {
    loading.value = false;
  });

const onSubmit = (values: StudyProgramFormValues) => {
  actionLoading.value = true;

  return eduConfigurationServiceClient
    .editStudyProgram(
      props.id,
      new UpdateStudyProgramDTO(
        convertToUpdateDTO(props.id, values, isArchived.value),
      ),
    )
    .then(() => loadStudyPrograms())
    .then(() => {
      Notify.success(texts.notifications.edit.successCached, [
        texts.models.studyProgram.title,
      ]);
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.studyProgram.edit.failure);
      logger.error(e);
    })
    .finally(() => {
      emit("submit");
      actionLoading.value = false;
    });
};

const onCancel = () => {
  emit("cancel");
};
</script>
