<template>
  <ErrorComponent v-if="error" />
  <Loader v-else-if="loading" />
  <div v-else class="flex flex-col gap-8">
    <div class="flex flex-col gap-8">
      <div class="flex flex-row gap-8">
        <StatsCard
          :color="pageColor"
          :value="stats?.inviteCount"
          class="basis-1/3"
          :header="textsReports.visitedSurveys.cards.invited.title"
        ></StatsCard>
        <StatsCard
          :color="pageColor"
          :value="stats?.responseCount"
          class="basis-1/3"
          :header="textsReports.visitedSurveys.cards.submitted.title"
        ></StatsCard>
        <StatsCard
          :color="pageColor"
          :value="
            (stats?.responsePercentage ?? -1) >= 0
              ? stats?.responsePercentage
              : undefined
          "
          class="basis-1/3"
          type="percentage"
          :header="textsReports.visitedSurveys.cards.submittedPercentage.title"
        ></StatsCard>
      </div>

      <QuestionAnswersCard
        v-for="(
          questionAnswerDistribution, key
        ) in stats?.questionAnswerDistributions"
        :key="key"
        :stats="questionAnswerDistribution"
      />
      <div class="flex flex-row items-center">
        <Button
          :label="
            displayOpenAnswers
              ? textsReports.visitedSurveys.cards.openAnswers.collapse
              : textsReports.visitedSurveys.cards.openAnswers.expand
          "
          :leadingIcon="
            displayOpenAnswers
              ? 'keyboard_double_arrow_down'
              : 'keyboard_double_arrow_right'
          "
          :loading="loadingOpenAnswers"
          :color="Color.Gray"
          :size="ButtonSize.sm"
          round
          flat
          @click="
            () => {
              loadOpenAnswers();
              toggleOpenAnswers();
            }
          "
        >
        </Button>
      </div>
      <template v-if="displayOpenAnswers && !loadingOpenAnswers">
        <h2 class="text-lg text-deepteal-700">
          {{ textsReports.visitedSurveys.cards.openAnswers.title }}
        </h2>
        <QuestionOpenAnswersCard
          v-for="(question, key) in openAnswers.questions"
          :key="key"
          :question="question"
        >
        </QuestionOpenAnswersCard>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Color } from "@/enums";
import Button from "@/components/common/button/Button.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import StatsCard from "@/components/common/stats-card/StatsCard.vue";
import texts from "@/utils/texts";
import ErrorComponent from "@/components/common/error/Error.vue";
import Loader from "@/components/common/loader/Loader.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import QuestionAnswersCard from "@/views/organize/activities/activity/hub/components/QuestionAnswersCard.vue";
import QuestionOpenAnswersCard from "@/views/organize/activities/activity/hub/components/QuestionOpenAnswersCard.vue";
import {
  ISurveyStatsDto,
  ISurveyOpenAnswersDto,
} from "@/lib/eduConfigurationServiceClient";

const props = defineProps<{
  id: string;
  surveyLinkId: string;
}>();

const textsReports = texts.navigationItems.organize.activity.reports;

const loading = ref<boolean>(false);
const error = ref<boolean>(false);

const pageColor: Color = Color.Fuchsia;

const stats = ref<ISurveyStatsDto>();

const initialization = async () => {
  try {
    loading.value = true;
    stats.value =
      await eduConfigurationServiceClient.getSurveyStatsBySurveyLinkId(
        props.id,
        props.surveyLinkId,
      );
  } catch (err) {
    error.value = true;
    throw err;
  } finally {
    loading.value = false;
  }
};
initialization();

// fetch open answers
const displayOpenAnswers = ref<boolean>(false);
const toggleOpenAnswers = () => {
  displayOpenAnswers.value = !displayOpenAnswers.value;
};

const openAnswers = ref<ISurveyOpenAnswersDto>({ questions: [] });
const loadingOpenAnswers = ref<boolean>(false);
let openAnswersHaveBeenLoaded = false;
const loadOpenAnswers = async () => {
  if (openAnswersHaveBeenLoaded) return;
  loadingOpenAnswers.value = true;

  openAnswers.value =
    await eduConfigurationServiceClient.getSurveyOpenAnswersByActivityId(
      props.id,
      props.surveyLinkId,
    );

  loadingOpenAnswers.value = false;
  openAnswersHaveBeenLoaded = true;
};
</script>
