<template>
  <ErrorComponent v-if="error"></ErrorComponent>
  <div v-else class="flex h-full w-full flex-col gap-8">
    <div class="flex w-full items-start justify-between">
      <div class="flex flex-col gap-2">
        <PageHeading>{{ textsSurveyLink.title }}</PageHeading>
        <PageSubTitle>{{ textsSurveyLink.description }}</PageSubTitle>
      </div>
      <Button
        v-if="surveyLinks.length > 0"
        :leadingIcon="'add'"
        :iconColor="Color.White"
        :data-testid="testIds.action.create"
        @click="createSlideOver = true"
        >{{ textsSurveyLink.btnAdd }}
      </Button>
    </div>
    <List v-if="surveyLinks.length > 0" compact>
      <SurveyListItem
        v-for="surveyLink in surveyLinks"
        :key="surveyLink.id"
        :surveyLink="surveyLink"
      >
        <Divider />
        <DropdownItem @click="openDeleteModal(surveyLink.id)">
          <Icon icon="delete" :color="Color.Alert"></Icon>
          <span class="text-alert-500">{{ texts.actions.delete }}</span>
        </DropdownItem>
      </SurveyListItem>
    </List>

    <div v-else class="w-full max-w-2xl">
      <SurveyLinkCreate
        :activity="activity"
        :redirectToHub="true"
        @create:success="onCreateSuccess"
        @create:cancel="createSlideOver = false"
      />
    </div>

    <SlideOver
      v-model:visible="createSlideOver"
      :title="textsSurveyLink.create.slideOver.title"
      :subTitle="textsSurveyLink.create.slideOver.description"
    >
      <div class="px-5">
        <SurveyLinkCreate
          :activity="activity"
          :redirectToHub="false"
          @create:success="onCreateSuccess"
          @create:cancel="createSlideOver = false"
        />
      </div>
    </SlideOver>

    <DeleteSurveyLinkModal
      v-if="surveyLinkIdToDelete"
      :deletingId="surveyLinkIdToDelete"
      :activityId="activity.id"
      @update:deletingId="deleteSurveyLink"
    >
    </DeleteSurveyLinkModal>
  </div>
</template>

<script setup lang="ts">
import Button from "@/components/common/button/Button.vue";
import Divider from "@/components/common/divider/Divider.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import Icon from "@/components/common/icon/Icon.vue";
import List from "@/components/common/list/List.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { Color } from "@/enums";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import router from "@/router/index";
import { RouteNamesActivityOverview } from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import activityContext from "@/store/context/activity.context";
import { loadForms } from "@/store/context/settings.context";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import DeleteSurveyLinkModal from "@/views/organize/activities/activity/hub/survey-links/components/DeleteSurveyLinkModal.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import SurveyLinkCreate from "./components/SurveyLinkCreate.vue";
import SurveyListItem from "./components/SurveyListItem.vue";

const route = useRoute();
const props = defineProps<ActivityRouteProps>();

const textsSurveyLink = texts.navigationItems.organize.activity.surveyLinks;

// Fetch survey links
const error = ref(false);
const fetchSurveyLinks = async () => {
  try {
    activityContext.surveyLinks =
      await eduConfigurationServiceClient.getSurveyLinkOverview(props.id);
  } catch (e) {
    error.value = true;
    throw e;
  }
};

// Create
const createSlideOver = ref(false);
const onCreateSuccess = async () => {
  await fetchSurveyLinks();
  await loadForms();
  createSlideOver.value = false;
  if (props.surveyLinks.length === 1) {
    router.push({
      name: RouteNamesActivityOverview.SURVEY_LINKS,
      params: { id: props.id },
    });
  }
};

const surveyLinkIdToDelete = ref<string | undefined>(undefined);
// Delete survey link
const openDeleteModal = (surveyLinkId: string) => {
  surveyLinkIdToDelete.value = surveyLinkId;
};

const deleteSurveyLink = () => {
  surveyLinkIdToDelete.value = undefined;
  fetchSurveyLinks();
};

onMounted(() => {
  if (route.query.reload === "true") {
    fetchSurveyLinks();
    router.replace({ query: { ...route.query, reload: undefined } });
  }
});
</script>
