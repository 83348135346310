<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-4 py-8">
    <div class="flex flex-col justify-start gap-4 lg:flex-row lg:items-center">
      <Search v-model="search" />
      <SwitchToggle
        v-model="hideVisitedSurveyForms"
        class="min-w-48"
        :labels="['Registratie', 'Enquête']"
        :icons="['subject', 'apps']"
        :size="ButtonSize.sm"
      ></SwitchToggle>
      <div class="flex flex-row items-center justify-between gap-4">
        <FormTemplateTypeFilter
          v-if="hideVisitedSurveyForms"
          v-model="filterType"
        />
        <span class="text-wrap font-semibold text-deepteal-700"
          >{{ filteredTemplates.length }}
          {{ texts.models.formTemplate.plural }}</span
        >
      </div>
      <div
        class="flex flex-1 items-center justify-between gap-4 lg:justify-end"
      >
        <ButtonSettings @click="slideOverCreateOpen = true">
          {{ texts.models.formStylingSettings.settingsButton }}
        </ButtonSettings>
        <CreateFormTemplateButton />
      </div>
    </div>
    <List>
      <template v-slot:header>
        <ListItemColumn :flex="0"><div class="h-6 w-6"></div></ListItemColumn>
        <ListItemColumn :flex="2">{{ textsTemplate.name }}</ListItemColumn>
        <ListItemColumn hide>{{ textsTemplate.type }}</ListItemColumn>
        <ListItemColumn :flex="0"><div class="h-6 w-6"></div></ListItemColumn>
      </template>
      <FormTemplateItem
        v-for="template in filteredTemplates"
        :key="template.id"
        :template="template"
        @delete:succeeded="initialize"
      />
    </List>
    <SlideOverFormTemplateSettings
      v-model:visible="slideOverCreateOpen"
      :title="texts.models.formStylingSettings.generalSettings"
      :description="texts.models.formStylingSettings.generalDescription"
      :formUri="undefined"
    />
  </Page>
</template>

<script setup lang="ts">
import Page from "@/components/common/page/Page.vue";
import Search from "@/components/common/search/Search.vue";
import { FormType } from "@/enums";
import { FormTemplate } from "@/models/template";
import logger from "@/plugins/logger";
import { formsServiceClient } from "@/services/formsService.client.service";
import texts from "@/utils/texts";
import FormTemplateItem from "@/views/settings/forms/components/FormTemplateItem.vue";
import FormTemplateTypeFilter from "@/views/settings/forms/components/FormTemplateTypeFilter.vue";
import { computed, ref } from "vue";
import CreateFormTemplateButton from "@/views/settings/forms/CreateFormTemplateButton.vue";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import SlideOverFormTemplateSettings from "./SlideOverFormStylingSettings.vue";
import SwitchToggle from "@/components/common/toggle/SwitchToggle.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import { watch } from "vue";
const textsTemplate = texts.models.formTemplate;

const loading = ref(false);
const error = ref(false);

// Get forms
const formTemplates = ref<FormTemplate[]>([]);

const slideOverCreateOpen = ref<boolean>(false);

const templates = computed<FormTemplate[]>(() => {
  return formTemplates.value
    .slice(0) // .slice makes a copy of the array to avoid mutating the original
    .toSorted(
      (a, b) =>
        texts.enums.formType[a.type].localeCompare(
          texts.enums.formType[b.type],
        ) || a.name.localeCompare(b.name),
    );
});

const search = ref<string>();
const filterType = ref<FormType>();

const hideVisitedSurveyForms = ref<boolean>(true);

const filteredTemplates = computed<FormTemplate[]>(() =>
  templates.value
    // Filter on search
    .filter(
      (template) =>
        !search.value ||
        template.name?.toLowerCase().includes(search.value.toLowerCase()),
    )
    //Filter by form type
    .filter((template) => {
      //Filter on form type
      if (filterType.value) {
        return template.type === filterType.value;
      }
      //If no filter selected show all registration forms
      return template.type !== FormType.ActivityVisitedSurvey;
    }),
);

const initialize = () => {
  loading.value = true;
  formsServiceClient
    .getForms(undefined)
    .then((response) => {
      formTemplates.value = response
        .filter((r) => r.isTemplate)
        .map((r) => FormTemplate.fromDto(r));
    })
    .catch((e) => {
      error.value = true;
      logger.log(e);
    })
    .finally(() => {
      loading.value = false;
    });
};

initialize();

watch(hideVisitedSurveyForms, () => {
  filterType.value = hideVisitedSurveyForms.value
    ? undefined
    : FormType.ActivityVisitedSurvey;
});
</script>
