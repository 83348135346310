<template>
  <SectionFlex>
    <template v-slot:header>
      <Section>
        <PageHeading>
          {{ texts.navigationItems.manage.settings.title }}
        </PageHeading>
      </Section>
      <RouterNavigationTabs :tabs="tabs" />
    </template>
    <template v-slot>
      <RouterView />
    </template>
  </SectionFlex>
</template>

<script setup lang="ts">
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import RouterNavigationTabs from "@/components/router/router-navigation-tabs/RouterNavigationTabs.vue";
import { RouteNamesSettings } from "@/router/routeNames";
import SectionFlex from "@/components/common/section/SectionFlex.vue";
import Section from "@/components/common/section/Section.vue";
import { IconNames } from "@/components/common/icon/Icon.types";
import { RouterView } from "vue-router";
import texts from "@/utils/texts";

const tabs = [
  {
    name: texts.navigationItems.manage.settings.navigationTabs.general,
    to: { name: RouteNamesSettings.GENERAL },
    icon: "settings",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.organization,
    to: { name: RouteNamesSettings.ORGANIZATION },
    icon: "business_center",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs
      .studyProgramVariables,
    to: { name: RouteNamesSettings.STUDY_PROGRAM_VARIABLES },
    icon: "school",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.studyPrograms,
    to: { name: RouteNamesSettings.STUDY_PROGRAMS },
    icon: "school",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.accounts,
    to: { name: RouteNamesSettings.ACCOUNTS },
    icon: "group",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.emailTemplates,
    to: { name: RouteNamesSettings.EMAIL_OVERVIEW },
    icon: "mail",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.formTemplates,
    to: { name: RouteNamesSettings.FORMS },
    icon: "assignment",
  },
  {
    name: texts.navigationItems.manage.settings.navigationTabs.questions,
    to: { name: RouteNamesSettings.QUESTIONS },
    icon: "help",
  },
] satisfies { name: string; to: { name: string }; icon: IconNames }[];
</script>
