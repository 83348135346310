<template>
  <Async :state="state">
    <template v-slot:failure>
      <ErrorComponent />
    </template>
    <template v-slot:loading>
      <Loader />
    </template>
    <template v-slot>
      <form class="flex flex-col gap-4" @submit="onSubmit">
        <div class="flex flex-col gap-4">
          <h2 class="text-lg font-semibold text-deepteal-700">
            {{ anonymizationTexts.title }}
          </h2>
          <span class="text-sm font-light text-deepteal-700">
            {{ anonymizationTexts.description }}
          </span>
        </div>
        <div class="flex-flex-col gap-4">
          <FormFieldTextInput
            :id="fieldIds.anonymizationPeriodInMonths"
            :label="anonymizationTexts.periodLabel"
            :type="TextInputType.NUMBER"
            :displayMode="FormFieldDisplayMode.Column"
            min="12"
          />
        </div>

        <Alert v-if="anonymizableProspectCount != undefined">
          <span>{{
            interpolate(
              anonymizationTexts.numberOfAnonymizableProspects,
              anonymizableProspectCount.toString(),
            )
          }}</span>
        </Alert>

        <div class="flex">
          <ButtonSubmit :loading="loading" class="flex-grow">{{
            anonymizationTexts.saveButton
          }}</ButtonSubmit>
        </div>
      </form>
    </template>
  </Async>
</template>

<script setup lang="ts">
import Loader from "@/components/common/loader/Loader.vue";
import Async from "@/components/common/async/Async.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import { useAsyncState } from "@/components/common/async/Async.types";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { ref, watch } from "vue";
import { useForm } from "vee-validate";
import {
  AnonymizationPeriodSettingsFormValues,
  convertToDTO,
  validationSchema,
} from "@/views/settings/general/AnonymizationPeriodSettings.types";
import { TenantAnonymizationPeriodDTO } from "@/lib/eduConfigurationServiceClient";
import { interpolate } from "@/dictionary";
import logger from "@/plugins/logger";
import Alert from "@/components/common/alert/Alert.vue";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { fields } from "@/utils/miscellaneous";

const fieldIds = fields<AnonymizationPeriodSettingsFormValues>();
const form = useForm<AnonymizationPeriodSettingsFormValues>({
  validationSchema,
});

const anonymizationTexts =
  texts.navigationItems.manage.settings.general.anonymization;
const loading = ref(false);
const anonymizableProspectCount = ref<number | undefined>(undefined);
let intitialPeriod: number | undefined = undefined;

const { state, handler } = useAsyncState(async () => {
  const dto = await eduConfigurationServiceClient.getAnonymizationPeriod();
  intitialPeriod = dto.anonymizationPeriodInMonths;

  form.setValues({
    anonymizationPeriodInMonths: dto.anonymizationPeriodInMonths,
  });
});

watch([() => form.values.anonymizationPeriodInMonths], async ([newPeriod]) => {
  if (newPeriod === undefined || newPeriod == intitialPeriod) {
    anonymizableProspectCount.value = undefined;
    return;
  }

  eduConfigurationServiceClient
    .countAnonymizableProspects(newPeriod)
    .then((response) => {
      anonymizableProspectCount.value = response;
    })
    .catch(() => {
      anonymizableProspectCount.value = undefined;
    });
});

const onSubmit = form.handleSubmit((values) => {
  loading.value = true;

  const dto = convertToDTO(values as AnonymizationPeriodSettingsFormValues);

  eduConfigurationServiceClient
    .updateAnonymizationPeriod(new TenantAnonymizationPeriodDTO(dto))
    .then(() => {
      intitialPeriod = dto.anonymizationPeriodInMonths;
      anonymizableProspectCount.value = undefined;

      Notify.success(anonymizationTexts.notifications.success);
    })
    .catch((e) => {
      Notify.failure(anonymizationTexts.notifications.failure);
      logger.error(e);
    });
  loading.value = false;
});

handler();
</script>
