// region: Load View Components
import OrganizeMainPage from "@/views/organize/MainPage.vue";
import Activity from "@/views/organize/activities/activity/Activity.vue";
import ActivityTopbar from "@/views/organize/activities/activity/components/ActivityTopbar.vue";
import ActivitySidebar from "@/views/organize/activities/activity/components/ActivitySidebar.vue";
import ActivitySettings from "@/views/organize/activities/activity/settings/ActivitySettings.vue";
import RegistrationLinks from "@/views/organize/activities/activity/registration-links/RegistrationLinks.vue";
import RegistrationLinkEdit from "@/views/organize/activities/activity/registration-links/components/RegistrationLinkEdit.vue";
import Sessions from "@/views/organize/activities/activity/sessions/Sessions.vue";
import ActivityMailingsSMS from "@/views/organize/activities/activity/mailings-sms/MailingsSMS.vue";
import ActivityMailingOverview from "@/views/organize/activities/activity/mailings-sms/mailings/MailingOverview.vue";

import InformationRequestsMainPage from "@/views/information-requests/MainPage.vue";
import InformationRequestsBrochures from "@/views/information-requests/brochures/Overview.vue";
import InformationRequestsKeepMeInformed from "@/views/information-requests/keep-me-informed/Overview.vue";
import InformationRequestsThirdPartyProspectSources from "@/views/information-requests/third-party-prospect-sources/Overview.vue";

import ApplicationsEnrollmentsMainPage from "@/views/applications-enrollments/MainPage.vue";
import Applications from "@/views/applications-enrollments/applications/Overview.vue";
import Enrollments from "@/views/applications-enrollments/enrollments/Overview.vue";

import EmailOverview from "@/views/settings/emails/Overview.vue";
import EmailMailingTemplateCreate from "@/views/settings/emails/MailingTemplateCreate.vue";
import EmailMailingTemplateEdit from "@/views/settings/emails/MailingTemplateEdit.vue";
import EmailEmailTemplateCreate from "@/views/settings/emails/EmailTemplateCreate.vue";
import EmailEmailTemplateEdit from "@/views/settings/emails/EmailTemplateEdit.vue";
import FormsOverview from "@/views/settings/forms/Overview.vue";
import FormsTemplateEdit from "@/views/settings/forms/FormTemplateEdit.vue";

import MailingOverview from "@/views/mailings/Overview.vue";
import MailingWizard from "@/views/mailings/mailing/MailingWizard.vue";
import MailingsMailingOverview from "@/views/mailings/mailing/Overview.vue";

import AccountsOverview from "@/views/settings/users/Overview.vue";
import AccountCreate from "@/views/settings/users/AccountCreate.vue";

import Activities from "@/views/organize/activities/Activities.vue";
import ActivityStudyPrograms from "@/views/organize/activities/activity/study-programs/StudyPrograms.vue";
import ActivityLocations from "@/views/organize/activities/activity/locations/Locations.vue";
import ReportsOverview from "@/views/organize/activities/activity/hub/Overview.vue";
import ReportsVisitedSurveys from "@/views/organize/activities/activity/hub/visited-surveys/VisitedSurveys.vue";
import Settings from "@/views/settings/Settings.vue";
import General from "@/views/settings/General.vue";
import StudyPrograms from "@/views/settings/StudyPrograms.vue";
import Organization from "@/views/settings/Organization.vue";
import Contacts from "@/views/contacts/Contacts.vue";
import Contact from "@/views/contacts/Contact.vue";
import ContactMoments from "@/views/contacts/ContactMoments.vue";
import ContactEdit from "@/views/contacts/ContactEdit.vue";
import StudyProgramVariables from "@/views/settings/StudyProgramVariables.vue";
import FormTemplateCreate from "@/views/settings/forms/FormTemplateCreate.vue";
import QuestionsOverview from "@/views/settings/questions/Overview.vue";
// endregion
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterView,
} from "vue-router";

import RefreshableRouterView from "@/components/router/RefreshableRouterView.vue";
import Dashboard from "@/views/Dashboard.vue";
import ApplicationTopbar from "@/components/application-shell/ApplicationTopbar.vue";
import ApplicationSidebar from "@/components/application-shell/ApplicationSidebar.vue";
import NotFound from "@/views/NotFound.vue";

import {
  RouteMeta,
  RouteNames,
  RouteNamesActivityOverview,
  RouteNamesActivityReports,
  RouteNamesApplicationsEnrollments,
  RouteNamesContacts,
  RouteNamesInformationRequests,
  RouteNamesMailing,
  RouteNamesMailings,
  RouteNamesOrganize,
  RouteNamesSettings,
} from "./routeNames";
import {
  activityRouteProps,
  beforeEnterActivity,
  beforeRouteCheckIfId,
} from "@/router/guards/activityContextGuard";
import authenticationGuard from "@/router/guards/authenticationGuard";
import authorizationGuard from "@/router/guards/authorizationGuard";
import tenantGuard from "@/router/guards/tenantGuard";

import Unauthorized from "@/views/Unauthorized.vue";
import Unsubscribed from "@/views/Unsubscribed.vue";
import Tenant from "@/views/Tenant.vue";
import { EmailTemplateType, MailingType } from "@/enums";

import env from "@/env";
import texts from "@/utils/texts";
import NotificationSettings from "@/views/organize/activities/activity/notification-settings/NotificationSettings.vue";
import TrialDays from "@/views/organize/trial-days/Overview.vue";
import AffiliateLinks from "@/views/organize/activities/activity/affiliate-links/AffiliateLinks.vue";
import SurveyLinks from "@/views/organize/activities/activity/hub/survey-links/SurveyLinks.vue";
import SurveyLinkEdit from "@/views/organize/activities/activity/hub/survey-links/components/SurveyLinkEdit.vue";
import Authentication from "@/views/Authentication.vue";
import Unavailable from "@/views/Unavailable.vue";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import Logout from "@/views/Logout.vue";
import { beforeEnterDashboard } from "@/router/guards/selectionContextGuard";
import VisitedSurvey from "@/views/organize/activities/activity/hub/visited-surveys/components/VisitedSurvey.vue";

const routeTitles = texts.routes;

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: RouteNames.MAIN,
    components: {
      default: RouterView,
      topbar: ApplicationTopbar,
      sidebar: ApplicationSidebar,
    },
    redirect: { name: RouteNames.DASHBOARD },
    children: [
      {
        path: "dashboard",
        name: RouteNames.DASHBOARD,
        component: Dashboard,
        beforeEnter: beforeEnterDashboard,
        meta: {
          title: routeTitles.dashboard,
        },
      },
      {
        path: "organize",
        name: RouteNames.ORGANIZE,
        component: RouterView,
        redirect: { name: RouteNamesOrganize.MAINPAGE },
        meta: {
          permissions: [Permission.ActivitiesRead],
        },
        children: [
          {
            path: "",
            name: RouteNamesOrganize.MAINPAGE,
            component: OrganizeMainPage,
            redirect: { name: RouteNamesOrganize.ACTIVITIES },
            meta: {
              permissions: [Permission.ActivitiesRead],
            },
            children: [
              {
                path: "activities",
                name: RouteNamesOrganize.ACTIVITIES,
                component: Activities,
                meta: {
                  title: routeTitles.organize.activities,
                  permissions: [Permission.ActivitiesRead],
                },
              },
              {
                path: "trial-days",
                name: RouteNamesOrganize.TRIAL_DAYS,
                component: TrialDays,
                meta: {
                  title: routeTitles.organize.activities,
                  permissions: [Permission.ActivitiesRead],
                },
              },
            ],
          },
        ],
      },
      {
        path: "information-requests",
        name: RouteNames.INFORMATIONREQUESTS,
        component: RouterView,
        redirect: { name: RouteNamesInformationRequests.MAINPAGE },
        meta: {
          permissions: [
            Permission.BrochuresRead,
            Permission.KeepmeinformedRead,
            Permission.ThirdpartyprospectsourcesRead,
          ],
        },
        children: [
          {
            path: "",
            name: RouteNamesInformationRequests.MAINPAGE,
            component: InformationRequestsMainPage,
            redirect: { name: RouteNamesInformationRequests.BROCHURES },
            meta: {
              permissions: [
                Permission.BrochuresRead,
                Permission.KeepmeinformedRead,
                Permission.ThirdpartyprospectsourcesRead,
              ],
            },
            children: [
              {
                path: "brochures",
                name: RouteNamesInformationRequests.BROCHURES,
                component: InformationRequestsBrochures,
                meta: {
                  title: routeTitles["information-requests"].brochures,
                  permissions: [Permission.BrochuresRead],
                },
              },
              {
                path: "keep-me-informed",
                name: RouteNamesInformationRequests.KEEPMEINFORMED,
                component: InformationRequestsKeepMeInformed,
                meta: {
                  title: routeTitles["information-requests"].keepMeInformed,
                  permissions: [Permission.KeepmeinformedRead],
                },
              },
              {
                path: "third-party-prospect-sources",
                name: RouteNamesInformationRequests.THIRDPARTYPROSPECTSOURCES,
                component: InformationRequestsThirdPartyProspectSources,
                meta: {
                  title:
                    routeTitles["information-requests"]
                      .thirdPartyProspectSources,
                  permissions: [Permission.ThirdpartyprospectsourcesRead],
                },
              },
            ],
          },
        ],
      },
      {
        path: "applications-enrollments",
        name: RouteNames.APPLICATIONSENROLLMENTS,
        component: RouterView,
        redirect: { name: RouteNamesApplicationsEnrollments.MAINPAGE },
        meta: {
          permissions: [
            Permission.ApplicationsRead,
            Permission.EnrollmentsRead,
          ],
        },
        children: [
          {
            path: "",
            name: RouteNamesApplicationsEnrollments.MAINPAGE,
            component: ApplicationsEnrollmentsMainPage,
            redirect: { name: RouteNamesApplicationsEnrollments.APPLICATIONS },
            meta: {
              permissions: [
                Permission.ApplicationsRead,
                Permission.EnrollmentsRead,
              ],
            },
            children: [
              {
                path: "applications",
                name: RouteNamesApplicationsEnrollments.APPLICATIONS,
                component: Applications,
                meta: {
                  title: routeTitles["applications-enrollments"].applications,
                  permissions: [Permission.ApplicationsRead],
                },
              },
              {
                path: "enrollments",
                name: RouteNamesApplicationsEnrollments.ENROLLMENTS,
                component: Enrollments,
                meta: {
                  title: routeTitles["applications-enrollments"].enrollments,
                  permissions: [Permission.EnrollmentsRead],
                },
              },
            ],
          },
        ],
      },
      {
        path: "mailings",
        name: RouteNames.MAILINGS,
        component: RouterView,
        redirect: { name: RouteNamesMailings.OVERVIEW },
        meta: {
          permissions: [Permission.MailingsRead],
        },
        children: [
          {
            path: "overview",
            name: RouteNamesMailings.OVERVIEW,
            component: MailingOverview,
            meta: {
              title: routeTitles.mailings.overview,
              permissions: [Permission.MailingsRead],
            },
          },
          {
            path: "create",
            component: RouterView,
            redirect: RouteNames.NOT_FOUND,
            meta: {
              permissions: [Permission.MailingsWrite],
            },
            children: [
              {
                path: "general",
                name: RouteNamesMailings.CREATE_GENERAL,
                component: MailingWizard,
                props: { mailingType: MailingType.General },
                meta: {
                  title: routeTitles.mailings.create.general,
                },
              },
              {
                path: "information",
                name: RouteNamesMailings.CREATE_INFORMATION,
                component: MailingWizard,
                props: { mailingType: MailingType.Activity },
                meta: {
                  title: routeTitles.mailings.create.information,
                },
              },
              {
                path: "activityreminder",
                name: RouteNamesMailings.CREATE_ACTIVITY_REMINDER,
                component: MailingWizard,
                props: { mailingType: MailingType.ActivityReminder },
                meta: {
                  title: routeTitles.mailings.create.activityreminder,
                },
              },
              {
                path: "activityinvite",
                name: RouteNamesMailings.CREATE_ACTIVITY_INVITE,
                component: MailingWizard,
                props: { mailingType: MailingType.ActivityInvite },
                meta: {
                  title: routeTitles.mailings.create.activityinvite,
                },
              },
              {
                path: "activityvisitedsurvey",
                name: RouteNamesMailings.CREATE_ACTIVITY_VISITED_SURVEY,
                component: MailingWizard,
                props: { mailingType: MailingType.ActivityVisitedSurvey },
                meta: {
                  title: routeTitles.mailings.create.activityvisitedsurvey,
                },
              },
            ],
          },
          {
            path: ":id",
            name: RouteNamesMailings.MAILING,
            component: RouterView,
            meta: {
              title: routeTitles.mailings.id,
              permissions: [Permission.MailingsRead],
            },
            children: [
              {
                path: "overview",
                name: RouteNamesMailing.OVERVIEW,
                component: MailingsMailingOverview,
                meta: {
                  title: routeTitles.mailings.id.overview,
                  permissions: [Permission.MailingsRead],
                },
              },
              {
                path: "edit",
                name: RouteNamesMailing.EDIT,
                component: MailingWizard,
                meta: {
                  title: routeTitles.mailings.id.edit,
                  permissions: [
                    Permission.MailingsRead,
                    Permission.MailingsWrite,
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        path: "contacts",
        name: RouteNames.CONTACTS,
        component: RouterView,
        meta: {
          permissions: [
            Permission.ContactsRead,
            Permission.ContactsWrite,
            Permission.PersonaldataRead,
          ],
        },
        redirect: { name: RouteNamesContacts.OVERVIEW },
        children: [
          {
            path: "overview",
            name: RouteNamesContacts.OVERVIEW,
            component: Contacts,
            meta: {
              title: routeTitles.contacts.overview,
              permissions: [
                Permission.ContactsRead,
                Permission.ContactsWrite,
                Permission.PersonaldataRead,
              ],
            },
          },
          {
            path: ":id",
            name: RouteNamesContacts.MAINPAGE,
            redirect: { name: RouteNamesContacts.EDIT },
            component: Contact,
            children: [
              {
                path: "edit",
                name: RouteNamesContacts.EDIT,
                component: ContactEdit,
                meta: {
                  title: routeTitles.contacts.id.edit,
                  permissions: [
                    Permission.ContactsRead,
                    Permission.ContactsWrite,
                    Permission.PersonaldataRead,
                  ],
                },
              },
              {
                path: "moments",
                name: RouteNamesContacts.MOMENTS,
                component: ContactMoments,
                meta: {
                  title: routeTitles.contacts.id.moments,
                  permissions: [
                    Permission.ContactsRead,
                    Permission.ContactsWrite,
                    Permission.PersonaldataRead,
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        component: RefreshableRouterView,
        children: [
          {
            path: "",
            name: RouteNames.SETTINGS,
            component: Settings,
            redirect: { name: RouteNamesSettings.GENERAL },
            meta: {
              permissions: [
                Permission.UsermanagementRead,
                Permission.UsermanagementWrite,
              ],
            },
            children: [
              {
                path: "general",
                name: RouteNamesSettings.GENERAL,
                component: General,
                meta: {
                  title: routeTitles.settings.general,
                  permissions: [
                    Permission.UsermanagementRead,
                    Permission.UsermanagementWrite,
                  ],
                },
              },
              {
                path: "organization",
                name: RouteNamesSettings.ORGANIZATION,
                component: Organization,
                meta: {
                  title: routeTitles.settings.organization,
                  permissions: [
                    Permission.UsermanagementRead,
                    Permission.UsermanagementWrite,
                  ],
                },
              },
              {
                path: "study-programs",
                name: RouteNamesSettings.STUDY_PROGRAMS,
                component: StudyPrograms,
                meta: {
                  permissions: [
                    Permission.StudyprogramsRead,
                    Permission.StudyprogramsWrite,
                  ],
                },
              },
              {
                path: "study-program-variables",
                name: RouteNamesSettings.STUDY_PROGRAM_VARIABLES,
                component: StudyProgramVariables,
                meta: {
                  permissions: [
                    Permission.StudyprogramsRead,
                    Permission.StudyprogramsWrite,
                  ],
                },
              },
              {
                path: "accounts",
                name: RouteNamesSettings.ACCOUNTS,
                component: RouterView,
                meta: {
                  permissions: [
                    Permission.UsermanagementRead,
                    Permission.UsermanagementWrite,
                  ],
                },
                redirect: { name: RouteNamesSettings.ACCOUNTS_OVERVIEW },
                children: [
                  {
                    path: "overview",
                    name: RouteNamesSettings.ACCOUNTS_OVERVIEW,
                    component: AccountsOverview,
                    meta: {
                      title: routeTitles.settings.accounts.overview,
                      permissions: [
                        Permission.UsermanagementRead,
                        Permission.UsermanagementWrite,
                      ],
                    },
                  },
                  {
                    path: "create",
                    name: RouteNamesSettings.ACCOUNT_CREATE,
                    component: AccountCreate,
                    meta: {
                      title: routeTitles.settings.accounts.create,
                      permissions: [
                        Permission.UsermanagementRead,
                        Permission.UsermanagementWrite,
                      ],
                    },
                  },
                ],
              },
              {
                path: "e-mails",
                name: RouteNamesSettings.EMAIL,
                component: RouterView,
                redirect: RouteNamesSettings.EMAIL_OVERVIEW,
                children: [
                  {
                    path: "overview",
                    name: RouteNamesSettings.EMAIL_OVERVIEW,
                    component: EmailOverview,
                    meta: {
                      title: routeTitles.settings["e-mail"].overview,
                      permissions: [
                        Permission.UsermanagementRead,
                        Permission.UsermanagementWrite,
                      ],
                    },
                  },
                  {
                    path: "mailing-template",
                    component: RefreshableRouterView,
                    props: true,
                    children: [
                      {
                        path: "create",
                        component: RouterView,
                        children: [
                          {
                            path: "general",
                            name: RouteNamesSettings.EMAIL_CREATE_MAILING_TEMPLATE_GENERAL,
                            component: EmailMailingTemplateCreate,
                            props: { mailingType: MailingType.General },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"][
                                  "mailing-template"
                                ].create.general,
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "information",
                            name: RouteNamesSettings.EMAIL_CREATE_MAILING_TEMPLATE_INFORMATION,
                            component: EmailMailingTemplateCreate,
                            props: { mailingType: MailingType.Activity },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"][
                                  "mailing-template"
                                ].create.information,
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "invite",
                            name: RouteNamesSettings.EMAIL_CREATE_MAILING_TEMPLATE_ACTIVITY_INVITE,
                            component: EmailMailingTemplateCreate,
                            props: { mailingType: MailingType.ActivityInvite },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"][
                                  "mailing-template"
                                ].create.invite,
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "reminder",
                            name: RouteNamesSettings.EMAIL_CREATE_MAILING_TEMPLATE_ACTIVITY_REMINDER,
                            component: EmailMailingTemplateCreate,
                            props: {
                              mailingType: MailingType.ActivityReminder,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"][
                                  "mailing-template"
                                ].create.reminder,
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "visited-survey",
                            name: RouteNamesSettings.EMAIL_CREATE_MAILING_TEMPLATE_ACTIVITY_VISITED_SURVEY,
                            component: EmailMailingTemplateCreate,
                            props: {
                              mailingType: MailingType.ActivityVisitedSurvey,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"][
                                  "mailing-template"
                                ].create["visited-survey"],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                        ],
                      },
                      {
                        path: ":id",
                        name: RouteNamesSettings.EMAIL_EDIT_MAILING_TEMPLATE,
                        component: EmailMailingTemplateEdit,
                        props: true,
                        meta: {
                          title:
                            routeTitles.settings["e-mail"]["mailing-template"]
                              .edit,
                          permissions: [
                            Permission.UsermanagementRead,
                            Permission.UsermanagementWrite,
                          ],
                        },
                      },
                    ],
                  },
                  {
                    path: "e-mail-template",
                    component: RefreshableRouterView,
                    props: true,
                    children: [
                      {
                        path: "create",
                        component: RouterView,
                        children: [
                          {
                            path: "confirmation",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_CONFIRMATION,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.RegistrationConfirmation,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create.confirmation,
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "brochure",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_BROCHURE,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.BrochureRequest,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create.brochure,
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "brochure-hardcopy",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_BROCHURE_HARDCOPY,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.BrochureHardCopyRequest,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create["brochure-hardcopy"],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "keep-me-informed",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_KEEPMEINFORMED,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.KeepMeInformedRequest,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create.keepMeInformed,
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "brochure-mailroom",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_BROCHURE_MAILROOM,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.BrochureMailRoom,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create["brochure-mailroom"],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "registration-notification",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_REGISTRATION_NOTIFICATION,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.RegistrationNotification,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create["registration-notification"],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "registration-canceled-notification",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_REGISTRATION_CANCELED_NOTIFICATION,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.RegistrationCanceledNotification,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create["registration-canceled-notification"],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "application-confirmation",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_APPLICATION_CONFIRMATION,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.ApplicationConfirmation,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create["application-confirmation"],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "activity-capacity-notification",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_ACTIVITY_CAPACITY_NOTIFICATION,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.ActivityCapacityFullNotification,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create["activity-capacity-notification"],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "trial-day-confirmation",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_TRIAL_DAY_CONFIRMATION,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.TrialDayConfirmation,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create["trial-day-confirmation"],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "trial-day-notification",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_TRIAL_DAY_NOTIFICATION,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.TrialDayNotification,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create["trial-day-notification"],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "affiliate-registrations-report-link",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_AFFILIATE_REGISTRATIONS_REPORT_LINK,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.AffiliateRegistrationsReportLink,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create[
                                  "affiliate-registrations-report-link"
                                ],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                          {
                            path: "affiliate-registrations-report",
                            name: RouteNamesSettings.EMAIL_CREATE_EMAIL_AFFILIATE_REGISTRATIONS_REPORT,
                            component: EmailEmailTemplateCreate,
                            props: {
                              emailTemplateType:
                                EmailTemplateType.AffiliateRegistrationsReport,
                            },
                            meta: {
                              title:
                                routeTitles.settings["e-mail"]["email-template"]
                                  .create["affiliate-registrations-report"],
                              permissions: [
                                Permission.UsermanagementRead,
                                Permission.UsermanagementWrite,
                              ],
                            },
                          },
                        ],
                      },
                      {
                        path: ":id",
                        name: RouteNamesSettings.EMAIL_EDIT_EMAIL_TEMPLATE,
                        component: EmailEmailTemplateEdit,
                        props: true,
                        meta: {
                          title:
                            routeTitles.settings["e-mail"]["email-template"]
                              .edit,
                          permissions: [
                            Permission.UsermanagementRead,
                            Permission.UsermanagementWrite,
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "forms",
                name: RouteNamesSettings.FORMS,
                component: FormsOverview,
                meta: {
                  title: routeTitles.settings.forms.overview,
                  permissions: [
                    Permission.UsermanagementRead,
                    Permission.UsermanagementWrite,
                  ],
                },
              },
              {
                path: "questions",
                name: RouteNamesSettings.QUESTIONS,
                component: QuestionsOverview,
                meta: {
                  title: routeTitles.settings.questions.overview,
                  permissions: [
                    Permission.UsermanagementRead,
                    Permission.UsermanagementWrite,
                  ],
                },
              },
            ],
          },
          {
            path: "form",
            component: RouterView,
            children: [
              {
                path: ":id",
                name: RouteNamesSettings.FORMS_EDIT_TEMPLATE,
                component: FormsTemplateEdit,
                props: true,
                meta: {
                  title: routeTitles.settings.forms.edit,
                  permissions: [
                    Permission.UsermanagementRead,
                    Permission.UsermanagementWrite,
                  ],
                },
              },
              {
                path: "create/:type",
                name: RouteNamesSettings.FORMS_CREATE_TEMPLATE,
                component: FormTemplateCreate,
                props: true,
                meta: {
                  title: routeTitles.settings.forms.create,
                  permissions: [
                    Permission.UsermanagementRead,
                    Permission.UsermanagementWrite,
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/activity/:id?",
    name: RouteNames.ACTIVITY_EDIT,
    components: {
      topbar: ActivityTopbar,
      sidebar: ActivitySidebar,
      default: Activity,
    },
    beforeEnter: beforeEnterActivity,
    redirect(to) {
      return !to.params.id
        ? { name: RouteNamesActivityOverview.SETTINGS }
        : {
            name: RouteNamesActivityReports.OVERVIEW,
            params: { id: to.params.id },
          };
    },
    meta: {
      permissions: [Permission.ActivitiesRead],
    },
    children: [
      {
        path: "setup",
        name: RouteNames.ACTIVITY_OVERVIEW,
        meta: {
          permissions: [Permission.ActivitiesWrite],
        },
        children: [
          {
            path: "settings",
            name: RouteNamesActivityOverview.SETTINGS,
            component: ActivitySettings,
            meta: {
              title: routeTitles.activity.id.settings,
              permissions: [Permission.ActivitiesWrite],
            },
            props: activityRouteProps,
          },
          {
            path: "study-programs",
            name: RouteNamesActivityOverview.STUDYPROGRAMS,
            component: ActivityStudyPrograms,
            meta: {
              title: routeTitles.activity.id["study-programs"],
              permissions: [
                Permission.ActivitiesWrite,
                Permission.StudyprogramsRead,
              ],
            },
            props: activityRouteProps,
            beforeEnter: beforeRouteCheckIfId,
          },
          {
            path: "locations",
            name: RouteNamesActivityOverview.LOCATIONS,
            component: ActivityLocations,
            meta: {
              title: routeTitles.activity.id["locations"],
              permissions: [
                Permission.ActivitiesWrite,
                Permission.LocationsRead,
              ],
            },
            props: activityRouteProps,
            beforeEnter: beforeRouteCheckIfId,
          },
          {
            path: "registration-links",
            name: RouteNamesActivityOverview.REGISTRATIONLINKS,
            component: RegistrationLinks,
            meta: {
              title: routeTitles.activity.id["registration-links"].overview,
              permissions: [Permission.ActivitiesWrite],
            },
            props: activityRouteProps,
            beforeEnter: beforeRouteCheckIfId,
          },
          {
            path: "registration-links/:linkId",
            name: RouteNamesActivityOverview.REGISTRATIONLINKS_EDIT,
            component: RegistrationLinkEdit,
            props: true,
            meta: {
              title: routeTitles.activity.id["registration-links"].edit,
              permissions: [Permission.ActivitiesWrite],
            },
            beforeEnter: beforeRouteCheckIfId,
          },
        ],
      },
      {
        path: "sessions",
        name: RouteNamesActivityOverview.SESSIONS,
        component: Sessions,
        meta: {
          title: routeTitles.activity.id.sessions,
          permissions: [Permission.SessionsRead],
        },
        props: activityRouteProps,
        beforeEnter: beforeRouteCheckIfId,
      },
      {
        path: "hub",
        name: RouteNamesActivityOverview.REPORTS,
        redirect: { name: RouteNamesActivityReports.OVERVIEW },
        meta: {
          permissions: [Permission.ActivitiesRead],
        },
        children: [
          {
            path: "",
            name: RouteNamesActivityReports.OVERVIEW,
            component: ReportsOverview,
            meta: {
              title: routeTitles.activity.id.reports.overview,
              permissions: [Permission.ActivitiesRead],
            },
            props: activityRouteProps,
            beforeEnter: beforeRouteCheckIfId,
          },
          {
            path: "visited-surveys",
            name: RouteNamesActivityReports.VISITED_SURVEYS,
            component: ReportsVisitedSurveys,
            meta: {
              title: routeTitles.activity.id.reports["visited-surveys"],
              permissions: [Permission.ActivitiesRead],
            },
            props: activityRouteProps,
            beforeEnter: beforeRouteCheckIfId,
            children: [
              {
                path: ":surveyLinkId",
                name: RouteNamesActivityReports.VISITED_SURVEYS_SURVEY,
                component: VisitedSurvey,
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "mailings-sms",
        component: RefreshableRouterView,
        meta: {
          title: routeTitles.activity.id["mailings-sms"],
          permissions: [
            Permission.ActivitiesRead,
            Permission.ActivitiesWrite,
            Permission.MailingsRead,
            Permission.MailingsWrite,
          ],
        },
        props: activityRouteProps,
        beforeEnter: beforeRouteCheckIfId,
        children: [
          {
            path: "",
            name: RouteNamesActivityOverview.MAILINGS_SMS,
            component: ActivityMailingsSMS,
            meta: {
              permissions: [
                Permission.ActivitiesRead,
                Permission.ActivitiesWrite,
                Permission.MailingsRead,
                Permission.MailingsWrite,
              ],
            },
          },
          {
            path: ":mailingId",
            name: RouteNamesActivityOverview.MAILING_OVERVIEW,
            component: ActivityMailingOverview,
            meta: {
              permissions: [
                Permission.ActivitiesRead,
                Permission.ActivitiesWrite,
                Permission.MailingsRead,
                Permission.MailingsWrite,
              ],
            },
          },
        ],
      },
      {
        path: "notification-settings",
        name: RouteNamesActivityOverview.NOTIFICATION_SETTINGS,
        component: NotificationSettings,
        meta: {
          title: routeTitles.activity.id["notification-settings"],
          permissions: [Permission.ActivitiesRead, Permission.ActivitiesWrite],
        },
        props: activityRouteProps,
        beforeEnter: beforeRouteCheckIfId,
      },
      {
        path: "affiliate-links",
        name: RouteNamesActivityOverview.AFFILIATE_LINKS,
        component: AffiliateLinks,
        meta: {
          title: routeTitles.activity.id["affiliate-links"],
          permissions: [Permission.ActivitiesWrite],
        },
        props: activityRouteProps,
        beforeEnter: beforeRouteCheckIfId,
      },
      {
        path: "survey-links",
        name: RouteNamesActivityOverview.SURVEY_LINKS,
        component: SurveyLinks,
        meta: {
          title: routeTitles.activity.id["survey-links"].overview,
          permissions: [
            Permission.ActivitiesRead,
            Permission.ActivitiesWrite,
            Permission.SurveysRead,
            Permission.SurveysWrite,
          ],
        },
        props: activityRouteProps,
        beforeEnter: beforeRouteCheckIfId,
      },
      {
        path: "survey-links/:linkId",
        name: RouteNamesActivityOverview.SURVEY_LINKS_EDIT,
        component: SurveyLinkEdit,
        props: true,
        meta: {
          title: routeTitles.activity.id["survey-links"].edit,
          permissions: [
            Permission.ActivitiesRead,
            Permission.ActivitiesWrite,
            Permission.SurveysRead,
            Permission.SurveysWrite,
          ],
        },
        beforeEnter: beforeRouteCheckIfId,
      },
    ],
  },
  {
    path: "/tenants",
    name: RouteNames.TENANTS,
    component: Tenant,
    meta: {
      title: routeTitles.tenants,
      permissions: [Permission.TenantsRead],
      [RouteMeta.NO_TENANT]: true,
    },
  },
  {
    path: "/login",
    name: RouteNames.LOGIN,
    component: Authentication,
    meta: {
      title: routeTitles.authentication,
      [RouteMeta.NO_TENANT]: true,
      [RouteMeta.NO_AUTH]: true,
    },
  },
  {
    path: "/logout",
    name: RouteNames.LOGOUT,
    component: Logout,
    meta: {
      title: routeTitles.logout,
      [RouteMeta.NO_TENANT]: true,
      [RouteMeta.NO_AUTH]: true,
    },
  },
  {
    path: "/unavailable",
    name: RouteNames.UNAVAILABLE,
    component: Unavailable,
    meta: {
      title: routeTitles.unavailable,
      [RouteMeta.NO_TENANT]: true,
      [RouteMeta.NO_AUTH]: true,
    },
  },
  {
    path: "/unauthorized",
    name: RouteNames.UNAUTHORIZED,
    component: Unauthorized,
    meta: {
      title: routeTitles.unauthorized,
      [RouteMeta.NO_TENANT]: true,
      [RouteMeta.NO_AUTH]: true,
    },
  },
  {
    path: "/unsubscribed",
    name: RouteNames.UNSUBSCRIBED,
    component: Unsubscribed,
    meta: {
      title: routeTitles.unsubscribed,
      [RouteMeta.NO_TENANT]: true,
      [RouteMeta.NO_AUTH]: true,
    },
  },
  {
    path: "/:pathMatch(.*)",
    name: RouteNames.NOT_FOUND,
    component: NotFound,
    meta: {
      title: routeTitles["not-found"],
      [RouteMeta.NO_TENANT]: true,
      [RouteMeta.NO_AUTH]: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(env.BASE_URL),
  routes,
});

router.beforeEach(authenticationGuard);
router.beforeEach(authorizationGuard);
router.beforeEach(tenantGuard);
router.beforeEach((to, _from, next) => {
  const title = to.meta?.title as string | undefined;
  document.title = title ? `${title}` : "Hippocampus";
  next();
});

export default router;
