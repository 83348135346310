<template>
  <Notifications
    class="max-w-md pt-4"
    width="100%"
    position="bottom left"
    :duration="5000"
    :speed="500"
  >
    <template v-slot:body="{ item, close }">
      <NotificationComponent
        :type="item.type"
        :title="item.title"
        :content="item.text"
        :close="close"
      />
    </template>
  </Notifications>
  <ApplicationShell>
    <template v-slot:topbar>
      <div class="h-full print:hidden">
        <RouterView name="topbar" />
      </div>
    </template>
    <template v-slot:sidebar>
      <div class="h-full print:hidden">
        <RouterView name="sidebar" />
      </div>
    </template>
    <template v-slot>
      <div
        class="flex flex-1 flex-col items-center 4xl:items-start screen:overflow-y-scroll"
      >
        <main
          class="relative mx-auto w-full max-w-8xl flex-1 p-4 focus:outline-none sm:p-8"
          :data-testid="testIds.applicationMainView"
        >
          <RouterView />
        </main>
      </div>
    </template>
  </ApplicationShell>
</template>

<script setup lang="ts">
import ApplicationShell from "@/components/application-shell/ApplicationShell.vue";
import NotificationComponent from "@/components/common/notification/Notification.vue";
import { setAuthContext } from "@/store/context/auth.context";
import { testIds } from "@/utils/testing";
import { useAuth0 } from "@auth0/auth0-vue";
import { Notifications } from "@kyvg/vue3-notification";
import { RouterView } from "vue-router";

const { getAccessTokenSilently, user } = useAuth0();

setTimeout(async () => {
  if (!user.value) return;
  const token = await getAccessTokenSilently();
  setAuthContext(token, user.value);
}, 60000);
</script>
