import { Form } from "@/models/form";
import { Culture } from "@/enums";
import { DateTime } from "luxon";
import {
  ActivityVisitedSurveyLinkDTO,
  ActivityVisitedSurveyLinkLocalizationDTO,
  CreateActivityVisitedSurveyLinkDTO,
  CreateActivityVisitedSurveyLinkFormDTO,
  ICreateActivityVisitedSurveyLinkLocalizationDTO,
  UpdateActivityVisitedSurveyLinkDTO,
  IUpdateActivityVisitedSurveyLinkLocalizationDTO,
} from "@/lib/eduConfigurationServiceClient";

export interface SurveyLinkCreateFormValues {
  localizations: SurveyLinkFormValuesLocalizations;
  formId: string;
  copyAsInstance: boolean;
}

export interface SurveyLinkEditFormValues {
  localizations: SurveyLinkFormValuesLocalizations;
  surveyStartDateTime?: DateTime;
  surveyEndDateTime?: DateTime;
}

export type SurveyLinkFormValuesLocalizations = Partial<
  Record<Culture, SurveyLinkFormValuesLocalization>
>;

export interface SurveyLinkFormValuesLocalization {
  name: string;
  prospectAppDescription?: string;
}

export const convertToEditFormValues = (
  surveyLink: ActivityVisitedSurveyLinkDTO,
  mainLanguage: Culture,
): SurveyLinkEditFormValues => {
  const localizations =
    surveyLink.localizations !== undefined
      ? localizationArrayToObject(
          surveyLink.localizations.toSorted((a) =>
            a.locale === mainLanguage ? -1 : 1,
          ),
        )
      : {
          [mainLanguage]: {
            name: "",
          },
        };

  return {
    localizations: localizations,
    surveyStartDateTime: surveyLink.surveyStartDateTime,
    surveyEndDateTime: surveyLink.surveyEndDateTime,
  };
};

export const convertToCreateDTO = (
  values: SurveyLinkCreateFormValues,
  forms: Form[],
): CreateActivityVisitedSurveyLinkDTO => {
  const form = forms.filter((f) => f.id === values.formId)[0];
  if (!form)
    throw new Error(
      `Could not create survey link, because no form with id ${values.formId} could be found`,
    );

  const formDto = new CreateActivityVisitedSurveyLinkFormDTO({
    ...form,
    copyAsInstance: values.copyAsInstance,
  });
  const dto = new CreateActivityVisitedSurveyLinkDTO({
    localizations: createLocalizationObjectToArray(values.localizations),
    form: formDto,
    surveyStartDateTime: undefined,
    surveyEndDateTime: undefined,
  });

  return dto;
};

export function createLocalizationObjectToArray(
  localizations: SurveyLinkFormValuesLocalizations,
): ICreateActivityVisitedSurveyLinkLocalizationDTO[] {
  return Object.entries(localizations).map(([locale, localization]) => ({
    locale,
    name: localization.name,
  }));
}

export const convertToUpdateDTO = (
  id: string,
  values: SurveyLinkEditFormValues,
): UpdateActivityVisitedSurveyLinkDTO => {
  const dto = new UpdateActivityVisitedSurveyLinkDTO({
    id,
    localizations: updateLocalizationObjectToArray(values.localizations),
    surveyStartDateTime: values.surveyStartDateTime,
    surveyEndDateTime: values.surveyEndDateTime,
  });

  return dto;
};

export function updateLocalizationObjectToArray(
  localizations: SurveyLinkFormValuesLocalizations,
): IUpdateActivityVisitedSurveyLinkLocalizationDTO[] {
  return Object.entries(localizations).map(([locale, localization]) => ({
    locale,
    name: localization.name,
  }));
}

export function localizationArrayToObject(
  localizations: ActivityVisitedSurveyLinkLocalizationDTO[],
) {
  return localizations.reduce(
    (cur, localization) => {
      const key = localization.locale as Culture;
      cur[key] = {
        name: localization.name ?? "",
      };
      return cur;
    },
    {} as Record<Culture, SurveyLinkFormValuesLocalization>,
  );
}
