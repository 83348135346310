import { Form } from "@/models/form";
import { Culture } from "@/enums";
import { DateTime } from "luxon";
import {
  RegistrationLinkDTO,
  CreateRegistrationLinkDTO,
  CreateRegistrationLinkFormDTO,
  ICreateRegistrationLinkLocalizationDTO,
  UpdateRegistrationLinkDTO,
  IUpdateRegistrationLinkLocalizationDto,
  RegistrationLinkLocalizationDTO,
} from "@/lib/eduConfigurationServiceClient";

export interface RegistrationLinkCreateFormValues {
  localizations: RegistrationLinkFormValuesLocalizations;
  formId: string;
  copyAsInstance: boolean;
  confirmationEmailTemplateId?: string;
  isVisibleForProspects: boolean;
}

export interface RegistrationLinkEditFormValues {
  localizations: RegistrationLinkFormValuesLocalizations;
  confirmationEmailTemplateId?: string;
  registrationStartDateTime?: DateTime;
  registrationEndDateTime?: DateTime;
  isVisibleForProspects: boolean;
}

export type RegistrationLinkFormValuesLocalizations = Partial<
  Record<Culture, RegistrationLinkFormValuesLocalization>
>;

export interface RegistrationLinkFormValuesLocalization {
  name: string;
  prospectAppDescription?: string;
}

export const convertToEditFormValues = (
  registrationLink: RegistrationLinkDTO,
  mainLanguage: Culture,
): RegistrationLinkEditFormValues => {
  const localizations =
    registrationLink.localizations !== undefined
      ? localizationArrayToObject(
          registrationLink.localizations.toSorted((a) =>
            a.locale === mainLanguage ? -1 : 1,
          ),
        )
      : {
          [mainLanguage]: {
            name: "",
          },
        };

  return {
    localizations: localizations,
    confirmationEmailTemplateId: registrationLink.confirmationEmailTemplateId,
    registrationStartDateTime: registrationLink.registrationStartDateTime,
    registrationEndDateTime: registrationLink.registrationEndDateTime,
    isVisibleForProspects: registrationLink.isVisibleForProspects,
  };
};

export const convertToCreateDTO = (
  values: RegistrationLinkCreateFormValues,
  forms: Form[],
): CreateRegistrationLinkDTO => {
  const form = forms.filter((f) => f.id === values.formId)[0];
  if (!form)
    throw new Error(
      `Could not create registration link, because no form with id ${values.formId} could be found`,
    );

  const formDto = new CreateRegistrationLinkFormDTO({
    ...form,
    copyAsInstance: values.copyAsInstance,
  });
  const dto = new CreateRegistrationLinkDTO({
    localizations: createLocalizationObjectToArray(values.localizations),
    isVisibleForProspects: values.isVisibleForProspects,
    form: formDto,
    confirmationEmailTemplateId: values.confirmationEmailTemplateId,
    registrationStartDateTime: undefined,
    registrationEndDateTime: undefined,
  });

  return dto;
};

export function createLocalizationObjectToArray(
  localizations: RegistrationLinkFormValuesLocalizations,
): ICreateRegistrationLinkLocalizationDTO[] {
  return Object.entries(localizations).map(([locale, localization]) => ({
    locale,
    name: localization.name,
  }));
}

export const convertToUpdateDTO = (
  id: string,
  values: RegistrationLinkEditFormValues,
): UpdateRegistrationLinkDTO => {
  const dto = new UpdateRegistrationLinkDTO({
    id,
    localizations: updateLocalizationObjectToArray(values.localizations),
    isVisibleForProspects: values.isVisibleForProspects,
    confirmationEmailTemplateId: values.confirmationEmailTemplateId,
    registrationStartDateTime: values.registrationStartDateTime,
    registrationEndDateTime: values.registrationEndDateTime,
  });

  return dto;
};

export function updateLocalizationObjectToArray(
  localizations: RegistrationLinkFormValuesLocalizations,
): IUpdateRegistrationLinkLocalizationDto[] {
  return Object.entries(localizations).map(([locale, localization]) => ({
    locale,
    name: localization.name,
  }));
}

export function localizationArrayToObject(
  localizations: RegistrationLinkLocalizationDTO[],
) {
  return localizations.reduce(
    (cur, localization) => {
      const key = localization.locale as Culture;
      cur[key] = {
        name: localization.name ?? "",
      };
      return cur;
    },
    {} as Record<Culture, RegistrationLinkFormValuesLocalization>,
  );
}
