<template>
  <Modal
    v-model:visible="visibleInternal"
    :type="ModalType.danger"
    :description="
      texts.navigationItems.organize.activity.surveyLinks.modal.delete
        .description
    "
    :title="
      texts.navigationItems.organize.activity.surveyLinks.modal.delete.title
    "
  >
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <ButtonDelete
          :data-testid="testIds.action.confirm"
          class="sm:ml-3"
          :loading="actionLoading"
          :disabled="!visibleInternal"
          @click="performDelete"
        />
        <ButtonCancel
          class="mt-3 sm:mt-0"
          :disabled="actionLoading || !visibleInternal"
          @click="visibleInternal = false"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import { testIds } from "@/utils/testing";
import { computed, ref } from "vue";
import texts from "@/utils/texts";
import Notify from "@/utils/notify";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";

const props = defineProps<{
  deletingId?: string | undefined;
  activityId: string;
}>();

const textsRegLinks = texts.navigationItems.organize.activity.surveyLinks;

const emit = defineEmits<{
  (e: "update:deletingId", value: string | undefined): void;
}>();

const visibleInternal = computed({
  get: () => !!props.deletingId,
  set: (value) => {
    if (!value) emit("update:deletingId", undefined);
  },
});

// Delete survey link modal
const actionLoading = ref(false);
// Deleting survey link
const performDelete = async () => {
  if (!props.deletingId) {
    throw new Error(`Cannot perform action. No brochure id provided.`);
  }

  try {
    actionLoading.value = true;

    await eduConfigurationServiceClient.deleteSurveyLink(
      props.activityId,
      props.deletingId,
    );

    Notify.success(textsRegLinks.delete.success);
    emit("update:deletingId", undefined);
  } catch (e) {
    Notify.failure(textsRegLinks.delete.failure);
    throw e;
  } finally {
    actionLoading.value = false;
  }
};
</script>
