<template>
  <Filter
    v-if="modeFilter.items.length > 0"
    v-model:expanded="modeFilter.expanded"
    :title="texts.models.studyProgramMode.title"
  >
    <FilterItem
      v-for="item in modeFilter.items"
      :id="item.id"
      :key="item.id"
      v-model="modeFilter.ids"
      :name="item.name"
    ></FilterItem>
  </Filter>
  <Divider v-if="levelFilter.items.length > 0" />
  <Filter
    v-if="levelFilter.items.length > 0"
    v-model:expanded="levelFilter.expanded"
    :title="texts.models.studyProgramLevel.title"
  >
    <FilterItem
      v-for="item in levelFilter.items"
      :id="item.id"
      :key="item.id"
      v-model="levelFilter.ids"
      :name="item.name"
    ></FilterItem>
  </Filter>
  <Divider v-if="languageFilter.items.length > 0" />
  <Filter
    v-if="languageFilter.items.length > 0"
    v-model:expanded="languageFilter.expanded"
    :title="texts.models.studyProgram.language"
  >
    <FilterItem
      v-for="item in languageFilter.items"
      :id="item.id"
      :key="item.id"
      v-model="languageFilter.ids"
      :name="item.name"
    ></FilterItem>
  </Filter>
  <Divider v-if="locationFilter.items.length > 0" />
  <Filter
    v-if="locationFilter.items.length > 0"
    v-model:expanded="locationFilter.expanded"
    :title="texts.models.studyProgramLocation.title"
  >
    <FilterItem
      v-for="item in locationFilter.items"
      :id="item.id"
      :key="item.id"
      v-model="locationFilter.ids"
      :name="item.name"
    ></FilterItem>
  </Filter>
</template>

<script setup lang="ts">
import Divider from "@/components/common/divider/Divider.vue";
import {
  FilterProps,
  compare,
  compareArray,
} from "@/components/common/filter/FilterItem.types";
import FilterItem from "@/components/common/filter/FilterItem.vue";
import StudyProgram from "@/models/study-program";
import texts from "@/utils/texts";
import { reactive, watch, watchEffect } from "vue";
import Filter from "../common/filter/Filter.vue";
import { sortOnName } from "./StudyProgramFilter.types";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";

const props = defineProps<{
  studyPrograms: StudyProgram[] | LocalizedStudyProgramDTO[];
  reset: boolean;
}>();

const emit = defineEmits<{
  (e: "filter", value: string[] | undefined): void;
}>();

const modeFilter = reactive({
  ids: [] as string[],
  expanded: false,
  items: props.studyPrograms
    .reduce<FilterProps[]>((result, item) => {
      if (item.studyProgramMode) {
        pushWhenNewId(result, {
          id: item.studyProgramMode.id,
          name: item.studyProgramMode.name,
          modelValue: false,
        });
      }
      return result;
    }, [])
    .toSorted(sortOnName),
});

const levelFilter = reactive({
  ids: [] as string[],
  expanded: false,
  items: props.studyPrograms
    .reduce<FilterProps[]>((result, item) => {
      if (item.studyProgramLevel) {
        pushWhenNewId(result, {
          id: item.studyProgramLevel.id,
          name: item.studyProgramLevel.name,
          modelValue: false,
        });
      }
      return result;
    }, [])
    .toSorted(sortOnName),
});

const locationFilter = reactive({
  ids: [] as string[],
  expanded: false,
  items: props.studyPrograms
    .reduce<FilterProps[]>((result, item) => {
      item.studyProgramLocations.forEach((location) => {
        pushWhenNewId(result, {
          id: location.id,
          name: location.name,
          modelValue: false,
        });
      });
      return result;
    }, [])
    .toSorted(sortOnName),
});

const languageFilter = reactive({
  ids: [] as string[],
  expanded: false,
  items: props.studyPrograms.reduce<FilterProps[]>((result, item) => {
    if (item.programLanguage) {
      pushWhenNewId(result, {
        id: item.programLanguage,
        name: item.programLanguage,
        modelValue: false,
      });
    }
    return result;
  }, []),
});

watchEffect(() => {
  const nothingIsChecked =
    modeFilter.ids.length === 0 &&
    levelFilter.ids.length === 0 &&
    languageFilter.ids.length === 0 &&
    locationFilter.ids.length === 0;

  if (nothingIsChecked) {
    emit("filter", undefined);
  } else {
    emit(
      "filter",
      filterStudyPrograms(props.studyPrograms).map((result) => result.id),
    );
  }
});

function filterStudyPrograms(
  studyPrograms: StudyProgram[] | LocalizedStudyProgramDTO[],
) {
  return studyPrograms
    .filter((studyProgram) =>
      compare(modeFilter.ids, studyProgram.studyProgramMode?.id),
    )
    .filter((studyProgram) =>
      compare(levelFilter.ids, studyProgram.studyProgramLevel?.id),
    )
    .filter((studyProgram) =>
      compare(languageFilter.ids, studyProgram.programLanguage),
    )
    .filter((studyProgram) =>
      compareArray(
        locationFilter.ids,
        studyProgram.studyProgramLocations.map((location) => location.id),
      ),
    );
}

watch(
  () => props.reset,
  () => {
    modeFilter.ids = [];
    levelFilter.ids = [];
    locationFilter.ids = [];
    languageFilter.ids = [];
  },
);

function pushWhenNewId<T extends { id: string }>(array: Array<T>, newItem: T) {
  const result = array.find((item) => item.id === newItem.id);
  if (result) return result;

  array.push(newItem);
  return newItem;
}
</script>
