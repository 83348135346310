<template>
  <ListItem :title="item.studyProgramDisplayName">
    <ListItemColumn :flex="0">
      <Icon icon="loyalty" :color="color"></Icon>
    </ListItemColumn>
    <ListItemColumn :flex="6" accent>
      <span :class="['truncate', { 'text-gray-400': item.isOrphaned }]">
        {{ label }}
      </span>
    </ListItemColumn>
    <ListItemColumn hide :flex="1" class="justify-end">
      <span :class="getTextColor(color)" class="text-base font-semibold">
        {{ item.prospectCount }}
      </span>
    </ListItemColumn>
    <ListItemColumn :flex="0">
      <ListItemActions v-if="item.studyProgramId && !item.isOrphaned">
        <DropdownItem
          v-if="item.studyProgramId && !item.isOrphaned"
          @click="copyToClipboard"
        >
          <Icon icon="link" :color="Color.DeepTeal"></Icon>
          <span>{{ texts.actions.copyLink }}</span>
        </DropdownItem>
      </ListItemActions>
      <div v-else class="w-6"></div>
    </ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts">
import { FormType, Color } from "@/enums";
import { KeepMeInformedOverviewItem } from "../Overview.types";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { computed } from "vue";
import texts from "@/utils/texts";
import {
  AsyncState,
  useAsyncState,
} from "@/components/common/async/Async.types";
import { formsServiceClient } from "@/services/formsService.client.service";
import { watch } from "vue";
import { getTextColor } from "@/utils/color";
import Icon from "@/components/common/icon/Icon.vue";

const props = defineProps<{
  item: KeepMeInformedOverviewItem;
  noInterestLabel?: string;
  color: Color;
}>();

const label = computed(
  () =>
    props.item.studyProgramDisplayName ??
    props.noInterestLabel ??
    texts.navigationItems.keepMeInformed.overview.noStudyProgram,
);

const keepMeInformedTexts = texts.navigationItems.keepMeInformed;

const createCopyToClipboardUrl = async () => {
  const forms = await formsServiceClient.getForms(FormType.KeepMeInformed);
  const formUri = forms.find(
    (f) => f.id === props.item.configuration.formId,
  )?.uri;

  if (!formUri) {
    throw new Error(`Could not find keep me informed form uri!`);
  }

  const url = await formsServiceClient.getPreselectKeepMeInformedLink(
    formUri,
    props.item.studyProgramId,
  );
  await navigator.clipboard.writeText(url);
};

const { state, handler: copyToClipboard } = useAsyncState(
  createCopyToClipboardUrl,
  keepMeInformedTexts.copyToClipboard,
);

watch(state, (value) => {
  if (value === AsyncState.Success) {
    setTimeout(() => (state.value = AsyncState.Content), 1500);
  }
  if (value === AsyncState.Failure) {
    setTimeout(() => (state.value = AsyncState.Content), 3000);
  }
});
</script>
