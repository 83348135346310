<template>
  <Section>
    <template v-slot:heading>
      <SectionHeading
        :title="texts.navigationItems.mailing.steps.timing.title"
        :description="texts.navigationItems.mailing.steps.timing.description"
        :divider="false"
      />
    </template>
    <div
      v-show="contextData.mailingType.mailingType !== MailingType.General"
      class="mb-4"
    >
      <RadioButtonList
        v-model:modelValue="selectedPlanningModeOption"
        :options="planningModeOptions"
        groupName="planningMode"
      />
    </div>
    <FormFieldDateTime
      v-show="
        contextData.mailingType.mailingType === MailingType.General ||
        selectedPlanningMode === PlanningMode.Absolute
      "
      id="datetime"
      :label="texts.models.mailing.timing"
      :displayMode="FormFieldDisplayMode.Row"
      :max="maximumDateTimeValue"
      class="sm:w-1/2"
    />
    <div
      v-show="selectedPlanningMode === PlanningMode.RelativeToActivityStart"
      class="flex flex-col gap-4 sm:w-1/2"
    >
      <FormFieldTextInput
        id="relativePlanningDayOffset"
        :label="texts.models.mailing.relativePlanningDayOffset"
        :type="TextInputType.NUMBER"
        :displayMode="FormFieldDisplayMode.Row"
        :min="0"
        :disabled="
          selectedPlanningModeOption?.getRadioButtonKey() ===
            planningModeRelativeToActivityStartAfterOptionKey &&
          data.type === MailingType.ActivityReminder
        "
      />
      <FormFieldTime
        id="relativePlanningTimeOfDay"
        :label="texts.models.mailing.relativePlanningTimeOfDay"
        :displayMode="FormFieldDisplayMode.Row"
        :disabled="
          selectedPlanningModeOption?.getRadioButtonKey() ===
            planningModeRelativeToActivityStartAfterOptionKey &&
          data.type === MailingType.ActivityReminder
        "
      />
    </div>
  </Section>
</template>

<script setup lang="ts">
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import RadioButtonList from "@/components/common/radiobutton/RadioButtonList.vue";
import { RadioButtonOption } from "@/components/common/radiobutton/RadioButtonOption";
import Section from "@/components/common/section/Section.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import FormFieldTime from "@/components/common/time/FormFieldTime.vue";
import { MailingType } from "@/enums";
import { PlanningMode } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import {
  ConceptMailingData,
  MailingContextData,
} from "@/views/mailings/mailing/MailingWizard.types";
import { DateTime } from "luxon";
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import * as yup from "yup";

const emit = defineEmits([
  "forwardNavigationValidated",
  "backNavigationValidated",
  "saveValidated",
]);

const props = defineProps<{
  isNavigatingBack: boolean;
  isNavigatingForward: boolean;
  isSaving: boolean;
  data: ConceptMailingData;
  contextData: MailingContextData;
}>();

const maximumDateTimeValue = computed((): DateTime | undefined => {
  if (props.data.type === MailingType.ActivityReminder) {
    return props.data.activity?.endDateTime;
  }
  return undefined;
});

const form = useForm({
  validationSchema: yup.object({
    planningMode: yup.mixed<PlanningMode>().required(),
    relativePlanningDayOffset: yup
      .number()
      .integer()
      .min(0)
      .when("planningMode", {
        is: (val: PlanningMode | undefined) =>
          val == PlanningMode.RelativeToActivityStart,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    relativePlanningTimeOfDay: yup.mixed<DateTime>().when("planningMode", {
      is: (val: PlanningMode | undefined) =>
        val == PlanningMode.RelativeToActivityStart,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
    datetime: yup
      .mixed<DateTime>()
      .when("planningMode", {
        is: (val: PlanningMode | undefined) => val == PlanningMode.Absolute,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      })
      .test(
        "reminderEndDate",
        texts.validation.reminderDateNeedsToBeBeforeActivityEndDateTime,
        (date) =>
          selectedPlanningMode.value === PlanningMode.RelativeToActivityStart ||
          props.data.type !== MailingType.ActivityReminder ||
          (props.data.type === MailingType.ActivityReminder &&
            !!date &&
            !!maximumDateTimeValue.value &&
            date <= maximumDateTimeValue.value),
      )
      .test(
        "visitedStartDate",
        texts.validation.surveyDateNeedsToBeAfterActivityStartDateTime,
        (date) =>
          props.data.type !== MailingType.ActivityVisitedSurvey ||
          (props.data.type === MailingType.ActivityVisitedSurvey &&
            !!date &&
            !!props.data.activity?.startDateTime &&
            date > props.data.activity?.startDateTime),
      ),
  }),
});

const internalData = computed(() => props.data);

form.setValues({
  planningMode:
    internalData.value.planningMode ??
    (internalData.value.type === MailingType.General
      ? PlanningMode.Absolute
      : undefined),
  relativePlanningDayOffset: internalData.value.relativePlanningDayOffset
    ? Math.abs(internalData.value.relativePlanningDayOffset)
    : undefined,
  relativePlanningTimeOfDay: internalData.value.relativePlanningTimeOfDay,
  datetime: internalData.value.datetime,
});

const getValues = () => {
  internalData.value.planningMode =
    form.values.planningMode ??
    (form.values.datetime && internalData.value.type === MailingType.General
      ? PlanningMode.Absolute
      : undefined);

  if (
    internalData.value.planningMode === PlanningMode.RelativeToActivityStart
  ) {
    internalData.value.datetime = undefined;
    internalData.value.relativePlanningDayOffset =
      selectedPlanningModeOption.value?.getRadioButtonKey() ===
      planningModeRelativeToActivityStartBeforeOptionKey
        ? -form.values.relativePlanningDayOffset
        : form.values.relativePlanningDayOffset;
    internalData.value.relativePlanningTimeOfDay =
      form.values.relativePlanningTimeOfDay;
  }

  if (internalData.value.planningMode === PlanningMode.Absolute) {
    internalData.value.datetime = form.values.datetime;
    internalData.value.relativePlanningDayOffset = undefined;
    internalData.value.relativePlanningTimeOfDay = undefined;
  }
};

const planningModeAbsoluteOption = {
  getRadioButtonKey: () => PlanningMode.Absolute,
  getRadioButtonText: () => texts.models.mailing.planningMode.absolute,
};
const planningModeRelativeToActivityStartBeforeOptionKey = `${PlanningMode.RelativeToActivityStart}-before`;
const planningModeRelativeToActivityStartBeforeOption = {
  getRadioButtonKey: () => planningModeRelativeToActivityStartBeforeOptionKey,
  getRadioButtonText: () =>
    texts.models.mailing.planningMode.relativeToActivityStartBefore,
};
const planningModeRelativeToActivityStartAfterOptionKey = `${PlanningMode.RelativeToActivityStart}-after`;
const planningModeRelativeToActivityStartAfterOption = {
  getRadioButtonKey: () => planningModeRelativeToActivityStartAfterOptionKey,
  getRadioButtonText: () =>
    texts.models.mailing.planningMode.relativeToActivityStartAfter,
};
const planningModeOptions = [
  planningModeAbsoluteOption,
  planningModeRelativeToActivityStartBeforeOption,
  planningModeRelativeToActivityStartAfterOption,
];

const mapToOption = (
  planningMode: PlanningMode | undefined,
): RadioButtonOption | undefined => {
  if (!planningMode) {
    return undefined;
  }
  if (planningMode == PlanningMode.Absolute) {
    return planningModeAbsoluteOption;
  }
  if (planningMode == PlanningMode.RelativeToActivityStart) {
    if (props.data.relativePlanningDayOffset ?? 0 < 0) {
      return planningModeRelativeToActivityStartBeforeOption;
    }
    if (props.data.relativePlanningDayOffset ?? 0 > 0) {
      return planningModeRelativeToActivityStartAfterOption;
    }
    if (props.data.relativePlanningDayOffset ?? 0 == 0) {
      if (
        (props.data.relativePlanningTimeOfDay?.hour ?? 0) <=
        (props.data.activity?.startDateTime.hour ?? 0)
      ) {
        return planningModeRelativeToActivityStartBeforeOption;
      } else {
        return planningModeRelativeToActivityStartAfterOption;
      }
    }
  }
  return undefined;
};
const mapToMode = (option: RadioButtonOption): PlanningMode => {
  if (option.getRadioButtonKey() == PlanningMode.Absolute) {
    return PlanningMode.Absolute;
  }
  if (
    option.getRadioButtonKey() ==
    planningModeRelativeToActivityStartBeforeOptionKey
  ) {
    return PlanningMode.RelativeToActivityStart;
  }
  if (
    option.getRadioButtonKey() ==
    planningModeRelativeToActivityStartAfterOptionKey
  ) {
    return PlanningMode.RelativeToActivityStart;
  }
  return PlanningMode.Absolute;
};

const selectedPlanningModeOption = ref<RadioButtonOption | undefined>(
  mapToOption(internalData?.value.planningMode),
);
const selectedPlanningMode = ref<PlanningMode | undefined>();
watch(
  () => selectedPlanningModeOption.value,
  (value) => {
    if (!value) {
      return;
    }

    const mode = mapToMode(value);
    form.setFieldValue("planningMode", mode, false);
    selectedPlanningMode.value = mode;
  },
  { immediate: true },
);

// Navigation
watch(
  () => props.isNavigatingForward,
  async (value) => {
    if (value) {
      const result = await form.validate();
      getValues();

      emit("forwardNavigationValidated", result.valid);
    }
  },
);

watch(
  () => props.isNavigatingBack,
  (value) => {
    if (value) {
      emit("backNavigationValidated", true);
    }
  },
);

watch(
  () => props.isSaving,
  async (value) => {
    if (value) {
      getValues();

      emit("saveValidated", true);
    }
  },
);
</script>
