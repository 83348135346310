<template>
  <FormLayout @submit="handleSubmit">
    <span>
      {{ instructionLabel }}
    </span>
    <TextInput v-model="enteredName"></TextInput>
    <template v-slot:actions>
      <Button
        :buttonElementType="ButtonElementType.submit"
        :disabled="!enteredNameIsValid"
        :loading="working"
        >{{ componentTexts.actionLabel }}</Button
      >
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { Prospect } from "@/models/contact";
import TextInput from "@/components/common/text-input/TextInput.vue";
import Button from "@/components/common/button/Button.vue";
import { ButtonElementType } from "@/components/common/button/Button.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import logger from "@/plugins/logger";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { interpolate } from "@/dictionary";
import FormLayout from "@/components/common/form/FormLayout.vue";

const componentTexts = texts.navigationItems.contact.anonymization;

const props = defineProps<{
  contact: Prospect;
}>();

const emit = defineEmits(["anonymized"]);

const enteredName = ref<string>("");
const working = ref<boolean>(false);
const enteredNameIsValid = computed<boolean>(
  () => enteredName.value == props.contact.firstName,
);
const instructionLabel = computed<string>(() =>
  interpolate(componentTexts.instructionLabel, props.contact.firstName),
);

const handleSubmit = (e: Event) => {
  e.preventDefault();

  working.value = true;
  eduConfigurationServiceClient
    .anonymizeProspect(props.contact.id)
    .then(() => {
      Notify.success({
        title: componentTexts.submit.success.title,
        content: componentTexts.submit.success.content,
      });

      emit("anonymized");
    })
    .catch((e) => {
      Notify.failure({
        title: componentTexts.submit.failure.title,
        content: componentTexts.submit.failure.content,
      });
      logger.log(e);
    })
    .finally(() => {
      working.value = false;
    });
};
</script>
