<template>
  <img
    :src="isChristmassy ? logoHoHoHippo : logoHippo"
    alt="Hippocampus logo"
  />
</template>

<script setup lang="ts">
import { DateTime } from "luxon";
import logoHippo from "@/assets/images/logo-hippo.svg";
import logoHoHoHippo from "@/assets/images/logo-hippohoho.svg";
import { computed, ref } from "vue";

const now = ref(DateTime.now());

const isChristmassy = computed(
  () => now.value.month === 12 && now.value.day >= 12 && now.value.day <= 26,
);
</script>
