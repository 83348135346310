<template>
  <form
    ref="formRef"
    class="form-editor flex flex-col gap-4"
    @submit="onSubmit"
  >
    <LocalizationTabs
      v-model="currentLocale"
      :locales
      @add="addLocalization"
      @remove="removeLocalization"
    />
    <FormTemplateEditorHeading
      :formUri="dto.formUri"
      :formType="modelValueInternal.type"
      :localizations="modelValueInternal.localizations"
      :currentLocale
      :submitting
    >
      <ButtonSubmit :loading="submitting" :size="ButtonSize.sm">{{
        texts.actions.submit
      }}</ButtonSubmit>
      <ButtonSettings
        v-if="dto.formUri"
        :size="ButtonSize.sm"
        @click="slideOverSettingsOpen = true"
      ></ButtonSettings>
    </FormTemplateEditorHeading>
    <FormTemplateEditorAlerts
      :form
      :localizations="modelValueInternal.localizations"
      :currentLocale
    />
    <div
      class="flex w-full flex-col items-center rounded-md border-deepteal-100 p-6"
      :style="
        formStyling?.sass.smtColorBackground
          ? {
              backgroundColor: formStyling.sass.smtColorBackground,
              borderWidth: '1px',
            }
          : undefined
      "
    >
      <div class="flex w-full max-w-4xl flex-col items-start gap-6">
        <template v-if="modelValueInternal.pages.length > 0">
          <template v-for="(_, index) in modelValueInternal.pages" :key="index">
            <div :ref="createHandler(index)" class="w-full">
              <FormTemplatePage
                v-model:page="modelValueInternal.pages[index]"
                :currentLocale="currentLocale"
                :style="formStyling"
                :nextPageLabel="
                  modelValueInternal.pages[index + 1]?.localizations.find(
                    (localization: FormPageLocalizationDTO) =>
                      localization.locale === settings.culture,
                  )?.label
                "
                :isFirstPage="index === 0"
                :isLastPage="index === modelValueInternal.pages.length - 1"
                @delete:page="
                  modelValueInternal.deletePage($event.sequenceNumber)
                "
                @move:page:up="
                  modelValueInternal.movePageUp($event.sequenceNumber)
                "
                @move:page:down="
                  modelValueInternal.movePageDown($event.sequenceNumber)
                "
                @scroll:page:up="scrollPageUp(index)"
                @scroll:page:down="scrollPageDown(index)"
                @scroll:confirm="scrollPageConfirm"
              ></FormTemplatePage>
            </div>
            <ButtonSettings
              icon="add"
              @click="modelValueInternal.addPage(index + 1)"
            >
              {{ interpolate(texts.actions.addNew, formTexts.page.title) }}
            </ButtonSettings>
          </template>
        </template>
        <template v-else>
          <ButtonSettings icon="add" @click="modelValueInternal.addPage(0)">
            {{ interpolate(texts.actions.addNew, formTexts.page.title) }}
          </ButtonSettings>
        </template>

        <div ref="confirmPageRef" class="flex w-full flex-col gap-4">
          <FormTemplateConfirmationPage
            v-show="!form.values.redirectEnabled"
            :localizations="modelValueInternal.localizations"
            :currentLocale
            :style="formStyling"
          />
          <FormTemplateEditorRedirectUrl
            :redirectEnabled="form.values.redirectEnabled"
            :localizations="modelValueInternal.localizations"
            :currentLocale
          />
        </div>
      </div>
    </div>
    <div
      class="flex flex-col items-stretch gap-4 sm:flex-row-reverse sm:items-center sm:justify-between"
    >
      <TextButton
        trailingIcon="arrow_warm_up"
        :color="Color.Gray"
        :size="ButtonSize.sm"
        class="self-end sm:self-center"
        @click="scrollPageForm"
        >{{ formTexts.scrollToTop }}</TextButton
      >
      <slot name="actions"></slot>
    </div>
    <SlideOverFormTemplateSettings
      v-if="dto.formUri"
      v-model:visible="slideOverSettingsOpen"
      :title="texts.models.formStylingSettings.specificSettings"
      :description="texts.models.formStylingSettings.specificDescription"
      :formUri="dto.formUri"
      @submit:succeeded="initializeStyling"
    />
  </form>
</template>

<script setup lang="ts">
import { FormDTO, FormPageLocalizationDTO } from "@/lib/formsServiceClient";
import {
  FormTemplateEditContext,
  FormTemplateEditContextKey,
  useFormStyling,
} from "@/views/settings/forms/FormExtensions";
import { formsServiceClient } from "@/services/formsService.client.service";
import { Color, Culture, FormType } from "@/enums";
import texts from "@/utils/texts";
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import FormTemplatePage from "@/views/settings/forms/components/form-template-editor/FormTemplatePage.vue";
import FormTemplateConfirmationPage from "./FormTemplateConfirmationPage.vue";
import { interpolate } from "@/dictionary";
import Notify from "@/utils/notify";
import { IStyleDTOWithSassVariables } from "@/lib/formsServiceClient";
import { ComponentPublicInstance, computed, provide, reactive, ref } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";

import settings from "@/store/context/settings.context";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import {
  FormTemplateFormValues,
  convertToFormValues,
  mapToDto,
} from "@/views/settings/forms/components/form-template-editor/FormTemplateEditor.types";
import FormTemplateEditorHeading from "@/views/settings/forms/components/form-template-editor/FormTemplateEditorHeading.vue";
import FormTemplateEditorRedirectUrl from "@/views/settings/forms/components/form-template-editor/FormTemplateEditorRedirectUrl.vue";
import FormTemplateEditorAlerts from "@/views/settings/forms/components/form-template-editor/FormTemplateEditorAlerts.vue";
import TextButton from "@/components/common/button/TextButton.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOverFormTemplateSettings from "@/views/settings/forms/SlideOverFormStylingSettings.vue";

const props = defineProps<{
  dto: FormDTO;
  submitting?: boolean;
}>();

const emit = defineEmits<{
  submit: [value: FormDTO];
}>();

const modelValueInternal = ref(props.dto);

// Start with one page on a new form
if (modelValueInternal.value.pages.length === 0) {
  modelValueInternal.value.addPage(1);
}

const locales = computed(() => modelValueInternal.value.getLocales());

const formTexts = texts.navigationItems.manage.settings.formTemplates;
const form = useForm<FormTemplateFormValues>({
  validationSchema: yup.object({
    redirectEnabled: yup.boolean().required(),
    localizations: yup.object().when("redirectEnabled", ([enabled], schema) => {
      return schema.shape(
        settings.availableLanguages.reduce(
          (acc, language) => ({
            ...acc,
            [language.locale.value as string]: yup.lazy((value) =>
              value
                ? yup.object({
                    label: yup.string().required(),
                    confirmationPage: enabled
                      ? yup.string()
                      : yup.string().required(),
                    redirectUrl: enabled
                      ? yup.string().url().required()
                      : yup.string().url(),
                  })
                : yup.mixed(),
            ),
          }),
          {},
        ),
      );
    }),
  }),
  initialValues: convertToFormValues(modelValueInternal.value),
  validateOnMount: false,
});

const mainLanguageLocale = settings.mainLanguage.locale.value as Culture;

if (!locales.value.some((locale) => locale === mainLanguageLocale)) {
  modelValueInternal.value.addLocalization(mainLanguageLocale);
}

const currentLocale = ref(mainLanguageLocale);

const formQuestions = computed(() => {
  return props.dto.pages.flatMap((page) => page.questions);
});
const formEditContext: FormTemplateEditContext = reactive({
  formType: FormType[props.dto.type as keyof typeof FormType],
  questions: [],
  formQuestions: formQuestions,
});

const formStyling = ref<IStyleDTOWithSassVariables | undefined>(undefined);

provide(FormTemplateEditContextKey, formEditContext);

const addLocalization = (locale: Culture) => {
  modelValueInternal.value.addLocalization(locale);
};

const removeLocalization = (locale: Culture) => {
  if (locale === mainLanguageLocale) {
    Notify.failure(formTexts.localization.deleteFailedNotification);
    return;
  }
  modelValueInternal.value.removeLocalization(locale);
};

const initializeQuestions = async () => {
  formEditContext.questions = await formsServiceClient.getAllQuestions();
};
const initializeStyling = async () => {
  const styling = useFormStyling(props.dto.formUri);
  const fallBackStyling = useFormStyling();

  await styling.load();
  await fallBackStyling.load();

  formStyling.value = styling.data.value ?? fallBackStyling.data.value;
};
initializeQuestions();
initializeStyling();

// Scroll Functions
let pageRefs: Record<number, Element> = {};
const confirmPageRef = ref<Element | null>(null);
const formRef = ref<Element | null>(null);

const createHandler = (index: number) => {
  return (ele: Element | ComponentPublicInstance | null) => {
    setPageRef(index, ele);
  };
};

const setPageRef = (
  index: number,
  ele: Element | ComponentPublicInstance | null,
) => {
  if (ele instanceof Element) {
    pageRefs[index] = ele;
  }
};

const scrollPageUp = (index: number) => {
  const prevPage = pageRefs[index - 1];
  prevPage.scrollIntoView({ behavior: "smooth" });
};

const scrollPageDown = (index: number) => {
  const nextPage = pageRefs[index + 1];
  nextPage.scrollIntoView({ behavior: "smooth" });
};

const scrollPageConfirm = () => {
  confirmPageRef.value?.scrollIntoView({ behavior: "smooth" });
};

const scrollPageForm = () => {
  formRef.value?.scrollIntoView({ behavior: "smooth" });
};

const onSubmit = form.handleSubmit(
  (values) => {
    if (!modelValueInternal.value) {
      throw new Error("No FormDTO to update");
    }

    mapToDto(modelValueInternal.value, values);

    emit("submit", modelValueInternal.value);
  },
  () => scrollPageForm(),
);

const slideOverSettingsOpen = ref(false);
</script>
