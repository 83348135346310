<template>
  <div class="mb-4 flex flex-row justify-end gap-4">
    <Button :size="ButtonSize.md" @click="slideOverAddEnrollmentOpen = true">
      {{ componentTexts.addEnrollmentAction }}
    </Button>
  </div>
  <Loader v-if="loadingContactMoments" />
  <ErrorComponent v-else-if="errorLoadingContactMoments" />
  <List v-else-if="contactMoments && contactMoments.length > 0">
    <template v-slot:header>
      <ListItemColumn>
        <span class="truncate">{{
          texts.models.contactMoment.contactMomentType
        }}</span>
      </ListItemColumn>
      <ListItemColumn hide>
        <span class="truncate">{{
          texts.models.contactMoment.activityName
        }}</span>
      </ListItemColumn>
      <ListItemColumn>
        <span class="truncate">{{ texts.models.contactMoment.dateTime }}</span>
      </ListItemColumn>
      <ListItemColumn :flex="0"> </ListItemColumn>
    </template>
    <ListItem
      v-for="(contactMoment, index) in contactMoments"
      :key="index"
      clickable
      data-testid="contact-moment-row"
      @click="
        () => {
          currentContactMoment = contactMoment;
          viewingDetails = true;
        }
      "
    >
      <ListItemColumn accent>
        {{ texts.enums.contactMomentType[contactMoment.type] }}
      </ListItemColumn>
      <ListItemColumn hide>
        {{ contactMoment.activityName }}
      </ListItemColumn>
      <ListItemColumn>
        {{ contactMoment.dateTime.toLocaleString(DateTime.DATETIME_MED) }}
      </ListItemColumn>
      <ListItemColumn :flex="0">
        <ListItemActions
          v-if="contactMoment.type === ContactMomentType.Enrollment"
          @click.stop
        >
          <DropdownItem @click="openDeleteModal(contactMoment.id)">
            <Icon icon="delete" :color="Color.Alert"></Icon>
            <span class="text-alert-500">{{ texts.actions.delete }}</span>
          </DropdownItem>
        </ListItemActions>
      </ListItemColumn>
    </ListItem>
  </List>

  <ContactMomentDetails
    v-if="currentContactMoment"
    v-model:viewingDetails="viewingDetails"
    :contactMoment="currentContactMoment"
    @deleted:enrollment="() => getContactMomentList()"
    @deleted:application="() => getContactMomentList()"
  />

  <SlideOver
    v-model:visible="slideOverAddEnrollmentOpen"
    :title="componentTexts.addEnrollment.title"
    :subTitle="componentTexts.addEnrollment.subTitle"
  >
    <ContactMomentAddEnrollment
      :prospect="props.contact"
      @added:enrollment="addedEnrolment"
    >
      <ButtonSubmit></ButtonSubmit>
      <ButtonCancel @click="slideOverAddEnrollmentOpen = false"></ButtonCancel
    ></ContactMomentAddEnrollment>
  </SlideOver>
  <DeleteContactMomentModal
    v-if="deletingId"
    :deletingId="deletingId"
    @update:deletingId="deleteContactMoment"
  ></DeleteContactMomentModal>
</template>

<script setup lang="ts">
import { Prospect } from "@/models/contact";
import { ref } from "vue";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import { ContactMoment } from "@/models/contact-moment";
import { getContactMoments } from "@/services/contact-moment.service";
import Loader from "@/components/common/loader/Loader.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import Icon from "@/components/common/icon/Icon.vue";
import ContactMomentDetails from "./ContactMomentDetails.vue";
import ContactMomentAddEnrollment from "./ContactMomentAddEnrollment.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import logger from "@/plugins/logger";
import { DateTime } from "luxon";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { Color } from "@/enums";
import DeleteContactMomentModal from "./DeleteContactMomentModal.vue";
import { ContactMomentType } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";

const componentTexts = texts.navigationItems.contact.contactMoments;

const props = defineProps<{
  contact: Prospect;
}>();

const loadingContactMoments = ref<boolean>(true);
const errorLoadingContactMoments = ref<boolean>(false);

const contactMoments = ref<ContactMoment[]>();

const getContactMomentList = () =>
  getContactMoments(props.contact.id)
    .then((dtos) => {
      const mappedResults = dtos.map((dto) => new ContactMoment(dto));

      contactMoments.value = mappedResults;
    })
    .catch((e) => {
      logger.error(e);

      errorLoadingContactMoments.value = true;
    })
    .finally(() => {
      loadingContactMoments.value = false;
    });

getContactMomentList();

const currentContactMoment = ref<ContactMoment>();
const viewingDetails = ref(false);
const slideOverAddEnrollmentOpen = ref<boolean>(false);

const addedEnrolment = () => {
  slideOverAddEnrollmentOpen.value = false;
  getContactMomentList();
};

const deletingEnrollment = ref<boolean>(false);
const deletingId = ref<string>();
const openDeleteModal = (contactMomentId: string) => {
  deletingId.value = contactMomentId;
  deletingEnrollment.value = true;
};

const deleteContactMoment = () => {
  deletingId.value = undefined;
  getContactMomentList();
};
</script>
