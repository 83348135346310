import env from "@/env";
import {
  ApplicationInsights,
  IEventTelemetry,
  IPageViewTelemetry,
  Snippet,
} from "@microsoft/applicationinsights-web";
import { App } from "vue";
import { Router } from "vue-router";
import { InsightsLogger } from "./logger";

const connectionString = env.VITE_INSIGHTS_CONNECTION_STRING;

const insightsConfig: Snippet = { config: { connectionString } };
const development = env.MODE === "development";

export interface ITracker {
  event: (name: string, properties?: Record<string, unknown>) => void;

  pageView: (
    name: string,
    path?: string,
    from?: string,
    properties?: Record<string, unknown>,
  ) => void;
}

export class InsightsTracker implements ITracker {
  appInsights: ApplicationInsights;

  constructor(
    insightsConfig: Snippet,
    private development: boolean = false,
  ) {
    this.appInsights = new ApplicationInsights(insightsConfig);
    this.appInsights.loadAppInsights();
  }

  event(name: string, properties?: Record<string, unknown>): void {
    const event: IEventTelemetry = {
      name: name,
      properties,
    };
    if (this.development) {
      console.debug("Event", event);
    } else {
      this.appInsights.trackEvent(event);
    }
  }

  pageView(
    name: string,
    path?: string,
    from?: string,
    properties?: Record<string, unknown>,
  ): void {
    const pageView: IPageViewTelemetry = {
      name: name,
      uri: path,
      refUri: from,
      isLoggedIn: false,
      properties,
    };

    if (this.development) {
      console.debug("PageView", pageView);
    } else {
      this.appInsights.trackPageView(pageView);
    }
  }
}

const tracker = new InsightsTracker(insightsConfig, development);
export default tracker;

export class InsightsTrackerPlugin {
  constructor(
    private router: Router,
    private logger: InsightsLogger,
  ) {}

  install(app: App): void {
    this.router.afterEach((to, from, failure) => {
      if (failure) {
        this.logger.warn(failure, {
          to: to.path,
          from: from.path,
        });
      } else {
        tracker.pageView(
          to.name?.toString() ?? "unknown",
          to.fullPath,
          from.fullPath,
        );
      }
    });

    app.config.globalProperties.$tracker = tracker; // Makes it available to track events in components with $tracker.
  }
}
