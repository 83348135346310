<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-5">
    <div class="flex items-center">
      <h2 class="flex-grow text-lg font-semibold text-deepteal-700">
        {{ texts.navigationItems.organize.sessionTypes.title }}
      </h2>
      <ButtonAdd @click="slideOverCreateOpen = true"></ButtonAdd>
    </div>
    <Section>
      <span
        v-if="!loading && sessionTypes.length === 0"
        data-testid="no-session-types-found-message"
        class="text-sm italic text-gray-500"
        >{{
          texts.navigationItems.organize.sessionTypes.overview.noResultsFound
        }}</span
      >
      <List v-if="sessionTypes.length > 0">
        <template v-slot:header>
          <ListItemColumn>{{ texts.models.sessionType.name }}</ListItemColumn>
          <ListItemColumn></ListItemColumn>
        </template>
        <ListItem
          v-for="sessionType in sessionTypes"
          :key="sessionType.name"
          :data-testid="testIds.action.edit"
          clickable
          @click="openUpdateSessionTypeSlideOver(sessionType.id)"
        >
          <ListItemColumn>{{ sessionType.name }} </ListItemColumn>
          <ListItemColumn :flex="0" class="text-right"
            ><ListItemActions @click.stop>
              <DropdownItem
                @click="openUpdateSessionTypeSlideOver(sessionType.id)"
              >
                <Icon icon="delete" :color="Color.DeepTeal"></Icon>
                <span class="text-deepteal-500">{{ texts.actions.edit }}</span>
              </DropdownItem>
              <Divider class="mx-2 my-1" />
              <DropdownItem @click="openDeleteModal(sessionType.id)">
                <Icon icon="delete" :color="Color.Alert"></Icon>
                <span class="text-alert-500">{{ texts.actions.delete }}</span>
              </DropdownItem>
            </ListItemActions></ListItemColumn
          >
        </ListItem>
      </List>
    </Section>
  </Page>
  <!-- SlideOver panel with create SessionType -->
  <SlideOver
    v-model:visible="slideOverCreateOpen"
    :title="texts.navigationItems.organize.sessionTypes.create.title"
    :subTitle="texts.navigationItems.organize.sessionTypes.create.subTitle"
    data-testid="create-slide-over"
  >
    <SessionTypeForm @submit="submitCreate">
      <ButtonSubmit :loading="actionLoading"></ButtonSubmit>
      <ButtonCancel @click="cancelCreate"></ButtonCancel>
    </SessionTypeForm>
  </SlideOver>

  <!-- SlideOver panel with edit SessionType -->
  <SlideOver
    v-model:visible="slideOverUpdateOpen"
    :title="texts.navigationItems.organize.sessionTypes.edit.title"
    :subTitle="texts.navigationItems.organize.sessionTypes.edit.subTitle"
  >
    <Loader
      v-if="sessionTypeLoading"
      data-testid="loading-session-type"
    ></Loader>
    <SessionTypeForm
      v-if="!sessionTypeLoading && !!sessionTypeFormValues"
      :formValues="sessionTypeFormValues"
      @submit="submitUpdate"
    >
      <ButtonSubmit
        v-if="!deletingSessionType"
        :data-testid="testIds.action.submit"
        :loading="actionLoading"
      ></ButtonSubmit>
      <ButtonCancel
        v-if="!deletingSessionType"
        data-testid="cancel-update"
        :color="Color.White"
        @click="cancelUpdate"
      ></ButtonCancel>
      <SlideOverDeleteConfirm
        v-if="deletingSessionType"
        @confirm="submitDelete"
        @cancel="cancelDelete"
      ></SlideOverDeleteConfirm>
      <Button
        v-if="!deletingSessionType"
        :data-testid="testIds.action.delete"
        :color="Color.Alert"
        :label="texts.actions.delete"
        @click="deletingSessionType = true"
      ></Button>
    </SessionTypeForm>
  </SlideOver>
  <DeleteSessionTypeModal
    :deletingId="sessionTypeIdToDelete"
    @update:deletingId="deleteSessionTypeFinished"
  ></DeleteSessionTypeModal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { Color, Culture } from "@/enums";
import Section from "@/components/common/section/Section.vue";
import List from "@/components/common/list/List.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Divider from "@/components/common/divider/Divider.vue";
import Icon from "@/components/common/icon/Icon.vue";
import {
  createSessionType,
  getSessionTypes,
  getSessionType,
  updateSessionType,
  deleteSessionType,
} from "@/services/sessionTypes.service";
import Loader from "@/components/common/loader/Loader.vue";
import Page from "@/components/common/page/Page.vue";
import logger from "@/plugins/logger";
import SessionType, {
  SessionTypeDTO,
  SessionTypeCreateDTO,
  SessionTypeUpdateDTO,
  SessionTypeDTOLocalizableProperties,
  SessionTypeExtended,
} from "@/models/sessionType";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import SessionTypeForm from "@/views/settings/organization/session-types/SessionTypeForm.vue";
import { SessionTypeFormValues } from "@/views/settings/organization/session-types/SessionTypeForm.types";
import Notify from "@/utils/notify";
import { Localization } from "@/models/helpers/localizable";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import { loadSessionTypes } from "@/store/context/settings.context";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOverDeleteConfirm from "@/components/common/slideover/SlideOverDeleteConfirm.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import DeleteSessionTypeModal from "./DeleteSessionTypeModal.vue";
import { testIds } from "@/utils/testing";
import Button from "@/components/common/button/Button.vue";
import texts from "@/utils/texts";

const loading = ref<boolean>(true);
const error = ref<boolean>(false);

// Get SessionTypes
const sessionTypes = ref<Array<SessionTypeDTO>>([]);

const fetchSessionTypes = () => {
  getSessionTypes()
    .then((response) => {
      sessionTypes.value = response.map(
        (sessionTypeDto) => new SessionType(sessionTypeDto),
      );
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
};

fetchSessionTypes();

const actionLoading = ref<boolean>(false);

// Create Slide Over
const slideOverCreateOpen = ref<boolean>(false);

const cancelCreate = (): void => {
  slideOverCreateOpen.value = false;
};

const submitCreate = async (values: SessionTypeFormValues) => {
  actionLoading.value = true;

  const localizations: Localization<SessionTypeDTOLocalizableProperties>[] = [];

  if (values.nameNL) {
    localizations.push({
      locale: Culture.NL,
      name: values.nameNL,
    });
  }
  if (values.nameEN) {
    localizations.push({
      locale: Culture.EN,
      name: values.nameEN,
    });
  }

  const sessionTypeCreateDTO: SessionTypeCreateDTO = {
    localizations,
  };

  await createSessionType(sessionTypeCreateDTO)
    .then(() => loadSessionTypes())
    .then(() => {
      Notify.success(
        texts.navigationItems.organize.sessionTypes.create.success,
      );
      fetchSessionTypes();
      slideOverCreateOpen.value = false;
    })
    .catch((e) => {
      Notify.failure(
        texts.navigationItems.organize.sessionTypes.create.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

// Edit Slide Over
const slideOverUpdateOpen = ref<boolean>(false);
const sessionTypeLoading = ref<boolean>(false);

const currentSessionType = ref<SessionTypeExtended | undefined>(undefined);

// sessionTypeFormValues will automatically be updated when currentSessionType changes
const sessionTypeFormValues = computed<SessionTypeFormValues | undefined>(
  () => {
    if (!currentSessionType.value) {
      return undefined;
    }

    return {
      nameNL: currentSessionType.value.localizations["nl-NL"]?.name,
      nameEN: currentSessionType.value.localizations["en-US"]?.name,
    };
  },
);

const openUpdateSessionTypeSlideOver = async (sessionTypeId: string) => {
  sessionTypeLoading.value = true;
  slideOverUpdateOpen.value = true;

  getSessionType(sessionTypeId)
    .then((response) => {
      currentSessionType.value = new SessionTypeExtended(response);
    })
    .catch((e) => {
      slideOverUpdateOpen.value = false;
      Notify.failure(
        texts.navigationItems.organize.sessionTypes.getSessionType.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      sessionTypeLoading.value = false;
    });
};

const submitUpdate = async (values: SessionTypeFormValues) => {
  actionLoading.value = true;

  if (!currentSessionType.value) {
    throw new Error("Update activity type values lacks id");
  }

  const localizations = [];
  if (values.nameNL) {
    localizations.push({
      locale: Culture.NL,
      name: values.nameNL,
    });
  }
  if (values.nameEN) {
    localizations.push({
      locale: Culture.EN,
      name: values.nameEN,
    });
  }

  const sessionTypeDTO: SessionTypeUpdateDTO = {
    id: currentSessionType.value.id,
    localizations,
  };

  await updateSessionType(sessionTypeDTO)
    .then(() => loadSessionTypes())
    .then(() => {
      Notify.success(texts.navigationItems.organize.sessionTypes.edit.success);
      fetchSessionTypes();
      slideOverUpdateOpen.value = false;
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.organize.sessionTypes.edit.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const cancelUpdate = (): void => {
  deletingSessionType.value = false;
  slideOverUpdateOpen.value = false;
};

// Deleting SessionType
const deletingSessionType = ref<boolean>(false);

const submitDelete = async () => {
  if (!currentSessionType.value) {
    throw new Error(`Cannot perform action. No sessionType provided.`);
  }

  await deleteSessionType(currentSessionType.value.id)
    .then(() => loadSessionTypes())
    .then(() => {
      Notify.success(
        texts.navigationItems.organize.sessionTypes.delete.success,
      );
      fetchSessionTypes();
      slideOverUpdateOpen.value = false;
    })
    .catch((e) => {
      Notify.failure(
        texts.navigationItems.organize.sessionTypes.delete.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      deletingSessionType.value = false;
    });
};

const cancelDelete = () => {
  deletingSessionType.value = false;
};

// Reset state when closing updateSlideOver
watch(slideOverUpdateOpen, (newValue, oldValue) => {
  if (!newValue && oldValue) {
    cancelDelete();
  }
});

const sessionTypeIdToDelete = ref<string | undefined>(undefined);
// Delete session type
const openDeleteModal = (sessionTypeId: string) => {
  sessionTypeIdToDelete.value = sessionTypeId;
};

const deleteSessionTypeFinished = (sessionTypeId: string | undefined) => {
  sessionTypeIdToDelete.value = sessionTypeId;
  fetchSessionTypes();
};
</script>
