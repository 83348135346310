import { Permission } from "@/lib/eduConfigurationServiceClient";
import HttpClient from "@/utils/httpClient";
import { User } from "@auth0/auth0-vue";
import { reactive } from "vue";

type AuthContext = {
  token: string | undefined;
  permissions: Permission[];
  tenantUri: string | undefined;
  expiry: number | undefined;
} & AuthContextUser;

export type AuthContextUser = {
  name: string | undefined;
  email: string | undefined;
  picture: string | undefined;
  sub: string | undefined;
};

const auth = reactive<AuthContext>({
  token: undefined,
  permissions: [],
  tenantUri: undefined,
  expiry: undefined,
  name: undefined,
  email: undefined,
  picture: undefined,
  sub: undefined,
});

export default auth;

const ACCESS_TOKEN_STORE_KEY = `${window.document.location.host}_access_token`;
const USER_STORE_KEY = `${window.document.location.host}_user`;

const storeToken = localStorage.getItem(ACCESS_TOKEN_STORE_KEY);
const storeUser = localStorage.getItem(USER_STORE_KEY);

if (storeToken && storeUser) {
  const userContext: AuthContextUser = JSON.parse(storeUser);
  setAuthContext(storeToken, userContext);
}

export function setAuthContext(token: string, auth0User: User) {
  const user = extractUserProperties(auth0User);

  localStorage.setItem(ACCESS_TOKEN_STORE_KEY, token);
  localStorage.setItem(USER_STORE_KEY, JSON.stringify(user));

  HttpClient.setAuth(token);

  const claims = parseJwtToken(token);
  const namespace = "http://schemas.summit.nl/identity/claims";

  Object.assign<AuthContext, AuthContext>(auth, {
    token,
    tenantUri: claims[`${namespace}/tenant`],
    permissions: claims[`${namespace}/permissions`],
    expiry: new Date(0).setUTCSeconds(claims.exp),
    name: user?.name,
    email: user?.email,
    picture: user?.picture,
    sub: user?.sub,
  });
}

export function clearAuthContext() {
  localStorage.removeItem(ACCESS_TOKEN_STORE_KEY);
  localStorage.removeItem(USER_STORE_KEY);

  Object.assign<AuthContext, AuthContext>(auth, {
    token: undefined,
    tenantUri: undefined,
    permissions: [],
    expiry: undefined,
    name: undefined,
    email: undefined,
    picture: undefined,
    sub: undefined,
  });
}

function parseJwtToken(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(window.atob(base64));
}

function extractUserProperties(user: User): AuthContextUser {
  return {
    name: user.name,
    email: user.email,
    picture: user.picture,
    sub: user.sub,
  };
}
