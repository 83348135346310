export enum Culture {
  NL = "nl-NL",
  EN = "en-US",
  FR = "fr-FR",
  DE = "de-DE",
}

export enum StudyProgramLanguage {
  NL = "nl-NL",
  EN = "en-US",
  DE = "de-DE",
  FR = "fr-FR",
}

export enum Timezone {
  Amsterdam = "W. Europe Standard Time",
}

export enum MailingStatus {
  Concept = "Concept",
  Planned = "Planned",
  InProgress = "InProgress",
  Sent = "Sent",
  Failed = "Failed",
}

export enum MailingType {
  General = "General",
  Activity = "Activity",
  ActivityReminder = "ActivityReminder",
  ActivityInvite = "ActivityInvite",
  ActivityVisitedSurvey = "ActivityVisitedSurvey",
}

export enum MailResponseStatus {
  Delivered = "delivered",
  HardBounce = "hard-bounce",
  SoftBounce = "soft-bounce",
  Rejected = "rejected",
  Unknown = "unknown",
}

export enum Months {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

export enum EmailTemplateType {
  RegistrationConfirmation = "RegistrationConfirmation",
  BrochureRequest = "BrochureRequest",
  BrochureHardCopyRequest = "BrochureHardCopyRequest",
  BrochureMailRoom = "BrochureMailRoom",
  KeepMeInformedRequest = "KeepMeInformedRequest",
  RegistrationNotification = "RegistrationNotification",
  RegistrationCanceledNotification = "RegistrationCanceledNotification",
  ApplicationConfirmation = "ApplicationConfirmation",
  ActivityCapacityFullNotification = "ActivityCapacityFullNotification",
  TrialDayConfirmation = "TrialDayConfirmation",
  TrialDayNotification = "TrialDayNotification",
  AffiliateRegistrationsReportLink = "AffiliateRegistrationsReportLink",
  AffiliateRegistrationsReport = "AffiliateRegistrationsReport",
}

export type MailTemplateType = MailingType | EmailTemplateType;

export enum SessionStorageKey {
  ContactsSearchQuery = "smt-edu-contact-search-query",
  SessionsSearchTerm = "smt-edu-sessions-search-term",
  BrochuresSearchTerm = "smt-edu-brochures-search-term",
  KeepMeInformedSearchTerm = "smt-edu-keepmeinformed-search-term",
  TrialDaySearchTerm = "smt-edu-trialday-search-term",
  ActivitiesSearchTerm = "smt-edu-activities-search-term",
  SessionsCopiedValues = "smt-edu-sessions-copied-values",
  MailingSearchTerm = "smt-edu-mailings-search-term",
  StudyProgramsSearchTerm = "smt-edu-studyprograms-search-term",
  ActivityStudyProgramsSearchTerm = "smt-edu-activity-studyprograms-search-term",
  ApplicationsSearchTerm = "smt-edu-applications-search-term",
  ActivityMailingSearchTerm = "smt-edu-activity-mailings-search-term",
  AccountsSearchTerm = "smt-edu-accounts-search-term",
  ActivitySelectedDateTime = "smt-edu-activity-selected-datetime",
}

export enum NotificationType {
  success = "success",
  failure = "failure",
  warning = "warning",
  info = "info",
}

export enum FormType {
  Registration = "Registration",
  ActivityVisitedSurvey = "ActivityVisitedSurvey",
  DigitalBrochureRequest = "DigitalBrochureRequest",
  HardCopyBrochureRequest = "HardCopyBrochureRequest",
  KeepMeInformed = "KeepMeInformed",
  Application = "Application",
  TrialDayRegistration = "TrialDayRegistration",
}

export enum Color {
  DeepTeal = "deepteal",
  Emerald = "emerald",
  Sunflower = "sunflower",
  Coral = "coral",
  Lavender = "lavender",
  Royal = "royal",
  Steel = "steel",
  Gray = "gray",
  Fuchsia = "fuchsia",
  Tangerine = "tangerine",
  Lime = "lime",
  Orchid = "orchid",
  Magenta = "magenta",
  Aqua = "aqua",
  Success = "success",
  Warning = "warning",
  Alert = "alert",
  Info = "info",
  White = "white",
  Black = "black",
  Transparent = "transparent",
}
