<template>
  <Menu
    as="div"
    class="group relative flex items-center"
    data-testid="profile-menu"
  >
    <div class="flex items-center justify-start">
      <MenuButton
        as="div"
        class="group cursor-pointer rounded-md p-1 hover:bg-gray-50"
      >
        <Icon class="text-gray-400 hover:text-gray-600" icon="language"></Icon>
      </MenuButton>
    </div>
    <Transition
      enterActiveClass="transition ease-out duration-100"
      enterFromClass="transform opacity-0 scale-95"
      enterToClass="transform opacity-100 scale-100"
      leaveActiveClass="transition ease-in duration-75"
      leaveFromClass="transform opacity-100 scale-100"
      leaveToClass="transform opacity-0 scale-95"
    >
      <MenuItems
        data-testid="menu-items"
        :class="[
          {
            'bottom-14 left-0 z-menuBar origin-bottom-left':
              origin === ApplicationMenuOrigin.BottomLeft,
            'right-0 top-14 z-menuBar origin-top-right':
              origin === ApplicationMenuOrigin.TopRight,
            'left-0 top-14 z-menuBar origin-top-left':
              origin === ApplicationMenuOrigin.TopLeft,
          },
          'absolute w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
        ]"
      >
        <div class="py-1">
          <MenuItem
            v-for="option in options"
            v-slot="{ active }"
            :key="option.value"
            @click="setCulture(option.value as Culture)"
            ><button
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'flex w-full justify-between px-4 py-2 text-left text-sm',
              ]"
            >
              {{ option.label }}
              <Icon
                v-if="option.value === settings.culture"
                :color="Color.Emerald"
                icon="check"
              ></Icon>
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </Transition>
  </Menu>
</template>

<script setup lang="ts">
import { ApplicationMenuOrigin } from "@/components/application-shell/ApplicationMenu.types";
import Icon from "@/components/common/icon/Icon.vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import { culturePicker } from "@/dictionary";
import { Color, Culture } from "@/enums";
import { AVAILABLE_CULTURES } from "@/services/culture.service";
import settings, { loadCulture } from "@/store/context/settings.context";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { computed } from "vue";

withDefaults(defineProps<{ origin: ApplicationMenuOrigin }>(), {});

const sortedAvailableCultures = AVAILABLE_CULTURES.toSorted((a, b) =>
  culturePicker[a].localeCompare(culturePicker[b]),
);

const options = computed<SelectOption[]>(() =>
  sortedAvailableCultures.map((culture) => ({
    label: culturePicker[culture],
    value: culture,
  })),
);

const setCulture = (culture: Culture) => {
  loadCulture(culture);
  location.reload();
};
</script>
