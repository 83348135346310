import { Culture } from "@/enums";
import {
  ActivityDTO,
  ActivityLocalizationDTO,
  CreateActivityDTO,
  ICreateActivityLocalizationDTO,
  UpdateActivityDTO,
  IUpdateActivityLocalizationDTO,
} from "@/lib/eduConfigurationServiceClient";
import { DateTime } from "luxon";

export interface ActivitySettingsFormValues {
  localizations: Record<Culture, ActivitySettingsFormValuesLocalization>;
  startDateTime?: DateTime;
  endDateTime?: DateTime;
  typeId?: string;
  isOnline?: boolean;
  onlineUrl?: string;
  capacity?: number;
}

export interface ActivitySettingsFormValuesLocalization {
  name: string;
  prospectAppDescription: string;
}

export function convertToFormValues(
  activity: ActivityDTO | undefined,
  mainLanguage: Culture,
): ActivitySettingsFormValues {
  if (activity !== undefined) {
    const localizations =
      activity.localizations !== undefined
        ? localizationArrayToObject(
            activity.localizations.toSorted((a) =>
              a.locale === mainLanguage ? -1 : 1,
            ),
          )
        : ({
            [mainLanguage]: {
              name: "",
              prospectAppDescription: "",
            },
          } as Record<Culture, ActivitySettingsFormValuesLocalization>);
    return {
      localizations: localizations,
      startDateTime: activity.startDateTime,
      endDateTime: activity.endDateTime,
      typeId: activity.typeId,
      isOnline: activity.isOnline,
      onlineUrl: activity.onlineUrl,
      capacity: activity.capacity,
    };
  }
  return {
    localizations: {
      [mainLanguage]: {
        name: "",
        prospectAppDescription: "",
      },
    } as Record<Culture, ActivitySettingsFormValuesLocalization>,
    startDateTime: undefined,
    endDateTime: undefined,
    typeId: undefined,
    isOnline: false,
    onlineUrl: undefined,
    capacity: undefined,
  };
}

export function localizationArrayToObject(
  localizations: ActivityLocalizationDTO[],
) {
  return localizations.reduce(
    (cur, localization) => {
      const key = localization.locale as Culture;
      cur[key] = {
        name: localization.name ?? "",
        prospectAppDescription: localization.prospectAppDescription ?? "",
      };
      return cur;
    },
    {} as Record<Culture, ActivitySettingsFormValuesLocalization>,
  );
}

export const convertToCreateDTO = (
  values: ActivitySettingsFormValues,
): CreateActivityDTO => {
  if (!values.startDateTime || !values.endDateTime) {
    throw new Error("Missing start or end date");
  }

  const dto = new CreateActivityDTO({
    startDateTime: values.startDateTime,
    endDateTime: values.endDateTime,
    localizations: createLocalizationObjectToArray(values.localizations),
    typeId: values.typeId || "",
    studyProgramIds: [],
    isOnline: values.isOnline || false,
    onlineUrl: values.onlineUrl,
    capacity: values.capacity,
  });

  return dto;
};

export function createLocalizationObjectToArray(
  localizations: Record<Culture, ActivitySettingsFormValuesLocalization>,
): ICreateActivityLocalizationDTO[] {
  return Object.keys(localizations)
    .filter((key) => localizations[key as Culture]?.name)
    .map((key) => ({
      locale: key as Culture,
      name: localizations[key as Culture].name,
      prospectAppDescription:
        localizations[key as Culture].prospectAppDescription,
    }));
}

export const convertToUpdateDTO = (
  activityId: string,
  values: ActivitySettingsFormValues,
): UpdateActivityDTO => {
  if (!values.startDateTime || !values.endDateTime) {
    throw new Error("Missing start or end date");
  }

  const dto = new UpdateActivityDTO({
    id: activityId,
    startDateTime: values.startDateTime,
    endDateTime: values.endDateTime,
    localizations: updateLocalizationObjectToArray(values.localizations),
    typeId: values.typeId || "",
    isOnline: values.isOnline || false,
    onlineUrl: values.onlineUrl,
    capacity: values.capacity,
  });

  return dto;
};

export function updateLocalizationObjectToArray(
  localizations: Record<Culture, ActivitySettingsFormValuesLocalization>,
): IUpdateActivityLocalizationDTO[] {
  return Object.keys(localizations)
    .filter((key) => localizations[key as Culture]?.name)
    .map((key) => ({
      locale: key as Culture,
      name: localizations[key as Culture].name,
      prospectAppDescription:
        localizations[key as Culture].prospectAppDescription,
    }));
}
